
import React from 'react';
import styled from 'styled-components';
import Navbar from '../../Components/Logo/Navigation/Navbar/Navbar';
import SideDrawer from '../../Components/Logo/Navigation/SideDrawer/SideDrawer';
import LabelBottomNavigation from '../../Components/Logo/Navigation/BottomNavigation/BottomNav';
import { connect } from 'react-redux';


const MainWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 6rem);
    margin-top: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Layout = ({ children, loggedIn }) => (
  <>
    <Navbar  loggedIn={loggedIn} />
    <SideDrawer loggedIn={loggedIn} />
    <LabelBottomNavigation />
    <MainWrapper>{children}</MainWrapper>

  </>
);

const mapStateToProps = ({ firebase }) => ({
  loggedIn: firebase.auth,
});

export default connect(mapStateToProps)(Layout);
