import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { connect } from "react-redux";
import styled from "styled-components";
import Heading from "../../../Components/UI/Headings/Heading";

import { Swiper, SwiperSlide } from 'swiper/react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import CardMedia from '@material-ui/core/CardMedia'
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import BoltIcon from '@mui/icons-material/Bolt';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import BalconyIcon from '@mui/icons-material/Balcony';
import SendTimeExtensionIcon from '@mui/icons-material/SendTimeExtension';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import HandshakeIcon from '@mui/icons-material/Handshake';

import SwiperCore, {
	Keyboard,
	Scrollbar,
	Pagination,
	Navigation,
} from 'swiper/core'

import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/scrollbar/scrollbar.min.css'

import Button from '../../../Components/UI/Forms/Button/Button';
import { getDemandsForArtisanAPI , deleteDemand } from "../../../Store/Actions/demandActions";

import * as actions from '../../../Store/Actions';
import EstimationWindow from "../Offers/EstimationWindow";
import ContractModal from "../Offers/ContractModal";
import ModificationModal from "../Offers/ModificationModal";
import JobFinalisingModal from "../Offers/JobFinalisingModal";
import DeleteOfferArtisan from "../Offers/DeleteOfferArtisan";
import ModalDevisPreview from "../../ClientProjects/ModalDevisPreview";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import SeeReviewArtisan from "../Offers/SeeReviewArtisan";
import Loader from "../../../Components/UI/Loader/Loader";
import { useHistory } from "react-router-dom";

const useStyles =  makeStyles({
	media:{
        height:0,
		paddingTop: '100%',
	},
	swiperContainer: {
		paddingBottom: '3rem',
		'& .swiper-pagination-bullet': {
			background: '#aaa',
		},
		'& .swiper-button-next:after': {
			fontSize: '1.5rem !important',
            width: '60px',
            height: '30px',
            color: 'rgba(0,0,0,0.3)',
            color: '#111111',
            backgroundColor: '#d6d4d5',
            borderRadius: '50%',
            paddingTop:'5px',

		},
		'& .swiper-button-prev:after': {
			fontSize: '1.5rem !important',
            width: '60px',
            height: '30px',
            color: 'rgba(0,0,0,0.3)',
            color: '#111111',
            backgroundColor: '#d6d4d5',
            borderRadius: '50%',
            paddingTop:'5px',
		},
        "@media (max-width: 768px)":{
            '& .swiper-button-next:after': {
            paddingTop:'7px',
            },
            '& .swiper-button-prev:after': {
                paddingTop:'7px',
            }
        }

	},
})

SwiperCore.use([Keyboard, Scrollbar, Pagination, Navigation])


const MainJobSection = (props)=>{

    const { media, swiperContainer } = useStyles();

    const artisanId= props.firebase.auth.uid;
    
    const [isDeleting, setIsDeleting]= useState(false);
    const [showReviewModa, setReviewShowModa]= useState(false);
    const[demandIdToDelete, setDemandIdToDelete]= useState('');
    const [showModal, setShowModal] = useState('close');
    const [showModalContract, setShowModalContract] = useState('close');
    const [showModificationModal, setModificationModal] = useState('close');
    const[showJobFinalizingModal, setJobFinalizingModal]= useState('close');
    const [showDevisModal, setDevisModal] = useState('close');
    const[demandIdToEstimation, setDemandIdToEstimation]= useState('');

  
    useEffect(()=> {
        props.getDemands()
    }, []);

    
    const handleClickIconEstimation = (e)=>{
            e.preventDefault();
    
            switch(showModal){
                case "open":
                    setShowModal('close');
                    break;
    
                case "close":
                    setShowModal('open');
                    break;
    
                    default:
                        setShowModal('close');
                        break;
            }
    }

    const handleClickIconContract= (e)=>{
        e.preventDefault();

        switch(showModalContract){
            case "open":
                setShowModalContract('close');
                break;

            case "close":
                setShowModalContract('open');
                break;

                default:
                    setShowModalContract('close');
                    break;
        }
    }

    const handleModificationModal= (e)=>{
        e.preventDefault();

        switch(showModificationModal){
            case "open":
                setModificationModal('close');
                break;

            case "close":
                setModificationModal('open');
                break;

                default:
                    setModificationModal('close');
                    break;
        }
    } ;


    const handleJobFinalizingIcon= (e)=>{
        e.preventDefault();

        switch(showJobFinalizingModal){
            case "open":
                setJobFinalizingModal('close');
                break;

            case "close":
                setJobFinalizingModal('open');
                break;

                default:
                    setJobFinalizingModal('close');
                    break;
        }
    };

    const handleDevisModal= (e)=>{
        e.preventDefault();

        switch(showDevisModal){
            case "open":
                setDevisModal('close');
                break;

            case "close":
                setDevisModal('open');
                break;

                default:
                    setDevisModal('close');
                    break;
        }
    } ;

    // Going to devis page
    const [ demandDevisTosee, setDemandDevisToSee] = useState('');
    const history = useHistory();
    const routeChangeDevis = ()=>{
        let path = 'devis';
        history.push({pathname : path, state:{demandToPrint: props.demandToSee}});
    }

   

    return(
        <>
            <Container>

                {props.loading && <Loader/>}
                                    
                <Content>
                  
                    {props.userDemands.length > 0 &&
                    props.userDemands.map((eachDemand, key)=> (  

                    <Article key={key}>
                        {eachDemand.estimation && eachDemand.estimation.artisan_id === artisanId  && eachDemand.demand_status !== 'reviewed'?
                        <div>
                            <SharedActor>
                            <a>
                                {eachDemand.client_profile_image ? 
                                <img src={eachDemand.client_profile_image} />
                                :
                                
                                <img src='/images/user.svg'/>
                                }
                                <div>
                                    {eachDemand.user_firstname ? 
                                    <span>{eachDemand.user_firstname}</span> :
                                    <span></span>
                                    }
                                    
                                    <span>{eachDemand.date.toDate().toLocaleDateString()}</span>
                                </div>
                            </a>

                            {/* demand status actions OFFER actions */}
                            {eachDemand.demand_status ==='unassigned' ?
                                <SpanDemandInfo onClick={()=>  setDemandIdToEstimation(eachDemand.demand_id) }>
                                 <Tooltip title="Envoyez votre devis">
                                 <div onClick={handleClickIconEstimation } style = {{cursor: 'pointer', paddingRight:'3px'}}>
                                 <SendTimeExtensionIcon onClick={handleClickIconEstimation } style = {{cursor: 'pointer', paddingRight:'3px'}}/> 
                                 </div>
                                 </Tooltip>
                                 
                                 
                                 </SpanDemandInfo> 
                                :
                                eachDemand.demand_status ==='estimation_wait' ?
                                <SpanDemandInfo > 
                                    <Tooltip title='Attendez la réponse du client'>
                                        <HourglassTopIcon style={{paddingRight:'3px'}}/>
                                    </Tooltip>
                                
                                </SpanDemandInfo>  
                                                 
                                :
                                eachDemand.demand_status ==='estimation_confirmed' ?
                                <SpanDemandInfo onClick={()=>  setDemandIdToEstimation(eachDemand) }> 
                                    <div onClick={handleClickIconContract } style = {{cursor: 'pointer', paddingRight:'3px'}}>
                                    <Tooltip title="Merci d'avance de préparer le contrat">
                                        <SendTimeExtensionIcon style={{paddingRight:'3px'}}></SendTimeExtensionIcon>       
                                    </Tooltip>
                                    </div>                                
                                </SpanDemandInfo>  
                                :
                                eachDemand.demand_status ==='estimation_visit_valid' ?
                                <SpanDemandInfo onClick={()=>  setDemandIdToEstimation(eachDemand) }> 
                                    <div onClick={handleClickIconContract } style = {{cursor: 'pointer', paddingRight:'3px'}}>
                                    <Tooltip title="Merci d'avance de préparer le contrat">
                                        <SendTimeExtensionIcon style={{paddingRight:'3px'}}></SendTimeExtensionIcon>       
                                    </Tooltip>
                                    </div>                                
                                </SpanDemandInfo>  
                                :
                                eachDemand.demand_status ==='contract_offered' ?
                                <SpanDemandInfo> 
                                <Tooltip title='Attendez la confirmation du client'>
                                <HourglassTopIcon style={{paddingRight:'3px'}}/>
                                </Tooltip>
                                </SpanDemandInfo> 
                                :
                                eachDemand.demand_status ==='modification_asked' ?
                                <SpanDemandInfo onClick={()=>  setDemandIdToEstimation(eachDemand) }> 
                                   <div onClick={handleModificationModal } style = {{cursor: 'pointer', paddingRight:'3px'}}>
                                   <Tooltip title="Merci d'avance de préparer le contrat">
                                       <SendTimeExtensionIcon style={{paddingRight:'3px'}}></SendTimeExtensionIcon>
                                   </Tooltip>
                                   </div>
                               </SpanDemandInfo> 
                               :
                               eachDemand.demand_status ==='assigned' ?
                               <SpanDemandInfo onClick={()=>setDemandIdToEstimation(eachDemand)} style = {{cursor: 'pointer', paddingRight:'3px'}}> 
                               <div onClick={handleJobFinalizingIcon}>
                               <Tooltip title='Le travail est terminé ?'>
                               <PriceCheckIcon style={{paddingRight:'0px'}}/>
                               </Tooltip>
                               </div>
                               </SpanDemandInfo>  
                               :
                               eachDemand.demand_status ==='finished' ?
                               <SpanDemandInfo> 
                                   <Tooltip title='Veuillez attendre le paiement'>
                                       <CreditScoreIcon style={{paddingRight:'3px'}}></CreditScoreIcon>
                                   </Tooltip>
                               </SpanDemandInfo>  
                                :
                                <div></div>
                            }

                            {/* REVIEW SCORE of the job */}
                            {eachDemand.demand_status && eachDemand.demand_status ==='reviewed'?
                             <Tooltip style = {{cursor: 'pointer', paddingRight:'3px'}} title= 'Évaluation de votre service'>
                            <SpanDemandInfo  onClick={()=> [setReviewShowModa(true), setDemandIdToDelete(eachDemand)]}  >
                                                          
                                <ReactStars
                                count={5}
                                value={eachDemand.review.rating}
                                size={18}
                                edit={false}
                                isHalf={false}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#c0a375"
                                />
                                
                            </SpanDemandInfo>
                            </Tooltip>
                            :
                            <div></div>
                            }
                            {/* REVIEW MODAL */}
                            <SeeReviewArtisan
                            demand={demandIdToDelete}
                            show={showReviewModa}
                            close={()=> setReviewShowModa(false)}
                            >

                            </SeeReviewArtisan>

                            {/* DELETING ICON */}
                            {eachDemand.demand_status && eachDemand.demand_status ==='finished'  ?
                            <Tooltip title="Vous ne pouvez pas supprimer votre offre maintenant !">
                            <DeleteIcon  style = {{cursor:'pointer', color:'#aaa'}}/>
                            </Tooltip>
                            :
                            eachDemand.demand_status && eachDemand.demand_status ==='assigned'  ?
                            <Tooltip title="YVous ne pouvez pas supprimer votre offre maintenant !">
                            <DeleteIcon  style = {{cursor:'pointer', color:'#aaa'}}/>
                            </Tooltip>
                            
                            :
                            eachDemand.demand_status && eachDemand.demand_status ==='paid'  ?
                            <Tooltip title="Vous ne pouvez pas supprimer votre offre maintenant !">
                            <DeleteIcon  style = {{cursor:'pointer', color:'#aaa'}}/>
                            </Tooltip>
                            :
                            eachDemand.demand_status && eachDemand.demand_status ==='reviewed'  ?
                            <Tooltip title="Vous ne pouvez pas supprimer votre offre maintenant !">
                            <DeleteIcon  style = {{cursor:'pointer', color:'#aaa'}}/>
                            </Tooltip>
                            :
                            <Tooltip title='Pas intéressé par ce petit boulot'>
                                <DeleteIcon
                                 onClick={()=>  [setIsDeleting(true), setDemandIdToDelete(eachDemand)]}
                                  style = {{cursor:'pointer', color:'#111111'}}/>

                            </Tooltip>
                            }
                            
                            <DeleteOfferArtisan
                            demand={demandIdToDelete}
                            show={isDeleting}
                            close={()=> setIsDeleting(false)}
                            >
                            </DeleteOfferArtisan>

                        </SharedActor>
                                                
                        <SharedImg>
                            
                            <Grid item container xs={12}  justifyContent='center'>
                            <Grid item xs={12} >
                                <Swiper 
                                        slidesPerView= 'auto'
                                        grabCursor
                                        keyboard={{ enabled: true }}
                                        pagination={{ clickable: true }}
                                        navigation
                                        className={swiperContainer}
                                        >
                                            {eachDemand.demand_image.map((image, index)=>(
                                                <SwiperSlide key={index}>
                                                    <CardMedia className={media} image={image}/>
                                                </SwiperSlide>

                                            ))}
                                        </Swiper>    
                                </Grid>
                            </Grid>
                        </SharedImg>              
                                            
                        
                        <Description>
                            {eachDemand.description}
                        </Description>

                        <DemandAddress>
                            <span>Adresse du chantier : </span>
                            {eachDemand.demand_address && 
                            <span>
                                {eachDemand.demand_address}
                            </span>
                            }
                        </DemandAddress>

                        {/* client phone number */}
                        {eachDemand.demand_status != "unassigned" || eachDemand.demand_status !=  "estimation_confirmed" ?
                        <SeeDevis>
                            <SpanDemandInfo> 
                            <Tooltip title='Numéro de téléphone du client'>    
                            <LocalPhoneIcon style={{paddingRight:'3px'}}> </LocalPhoneIcon>
                            </Tooltip>
                            <a href={"tel: " + eachDemand.client_phone_number}>
                            {eachDemand.client_phone_number}
                            </a>
                            </SpanDemandInfo> 
                            
                        </SeeDevis>
                        : null}
                        
                            {/* Visualizing the devis */}

                            {eachDemand.demand_status != "unassigned" ?
                    
                            <SeeDevis onClick={handleDevisModal } >
                            <SpanDemandInfo onClick={()=> setDemandDevisToSee(eachDemand) }>
                            <Tooltip title='Voir le devis'>
                            <HandshakeIcon/>
                            </Tooltip>
                            L'etat des travaux et devis
                          
                            </SpanDemandInfo>
                            </SeeDevis>
                            :
                            null
                            }
                                
                        <DemandType >
                            <span>Type de demande: </span>
                            {eachDemand.demand_type==='window' ?
                            <span>
                            {/* <img src='/images/doors.png'/> */}
                            <Tooltip title="Type de demande : menuiserie">
                                <BalconyIcon fontSize="large"/>
                            </Tooltip>
                            
                            </span>
                        :
                        eachDemand.demand_type==='electricity' ?
                            <span>
                                <Tooltip title="Demand Type: electricity">
                                <BoltIcon fontSize="large"/>
                                </Tooltip>
                            </span>
                        :
                            <span>
                                <img src=''/>
                            </span>
                        }
                        </DemandType>
                        
                        <DemandStatus>
                            <span>État de la demande : </span>
                            {eachDemand.demand_status && 
                            <span>
                                {eachDemand.demand_status ==='unassigned' ?
                                <SpanDemandInfo onClick={()=>  setDemandIdToEstimation(eachDemand.demand_id) }>
                                 <Tooltip title="Envoyez votre devis">
                                 <div onClick={handleClickIconEstimation } style = {{cursor: 'pointer', paddingRight:'3px'}}>
                                 <SendTimeExtensionIcon onClick={handleClickIconEstimation } style = {{cursor: 'pointer', paddingRight:'3px'}}/> 
                                 Envoyez votre devis
                                 </div>
                                 </Tooltip>
                                 
                                 
                                 </SpanDemandInfo> 
                                :
                                eachDemand.demand_status ==='estimation_wait' ?
                                <SpanDemandInfo > 
                                    <Tooltip title='wait for the client response'>
                                        <HourglassTopIcon style={{paddingRight:'3px'}}/>
                                    </Tooltip>
                                    Le client vous contactera
                                
                                </SpanDemandInfo>  
                               
                               
                                :
                                eachDemand.demand_status ==='estimation_confirmed' ?
                                <a >
                                    
                               

                                
                                 <SpanDemandInfo onClick={()=>  setDemandIdToEstimation(eachDemand) }> 
                                    <div onClick={handleClickIconContract } style = {{cursor: 'pointer', paddingRight:'3px'}}>
                                    <Tooltip title="Merci d'avoir préparé le contrat">
                                        <SendTimeExtensionIcon style={{paddingRight:'3px'}}></SendTimeExtensionIcon>
                                    
                                    </Tooltip>
                                    Préparer le devis
                                    </div>
                                    
                                
                                </SpanDemandInfo> 
                                
                                {/* <SpanDemandInfo>
                                    <Tooltip title='visit date'>
                                    <EventIcon />
                                    </Tooltip>
                                    {eachDemand.estimation.visit_date} 
                                </SpanDemandInfo>  */}
                                
                                </a>   
                                :
                                eachDemand.demand_status ==='estimation_visit_valid' ?
                                <SpanDemandInfo onClick={()=>  setDemandIdToEstimation(eachDemand) }> 
                                    <div onClick={handleClickIconContract } style = {{cursor: 'pointer', paddingRight:'3px'}}>
                                    <Tooltip title='Merci de préparer le contrat'>
                                        <SendTimeExtensionIcon style={{paddingRight:'3px'}}></SendTimeExtensionIcon>
                                    
                                    </Tooltip>
                                    {eachDemand.user_firstname} attend le contrat ! Envoyer le contrat
                                    </div>
                                    
                                
                                </SpanDemandInfo>    
                                :
                                eachDemand.demand_status ==='contract_offered' ?
                                <SpanDemandInfo> 
                                <CheckCircleOutlineIcon style={{paddingRight:'3px'}}/>
                                Le contrat est arrivé ! Attendez la confirmation du client.
                                </SpanDemandInfo> 
                                 :
                                 eachDemand.demand_status ==='modification_asked' ?
                                 <SpanDemandInfo onClick={()=>  setDemandIdToEstimation(eachDemand) }> 
                                    <div onClick={handleModificationModal} style = {{cursor: 'pointer', paddingRight:'3px'}}>
                                    <Tooltip title='Merci de préparer le contrat'>
                                        <SendTimeExtensionIcon style={{paddingRight:'3px'}}></SendTimeExtensionIcon>
                                    
                                    </Tooltip>
                                    {eachDemand.user_firstname}  demande de modification sur le contrat !
                                    </div>
                                </SpanDemandInfo>    
                                :
                                eachDemand.demand_status ==='assigned' ?
                                <SpanDemandInfo onClick={()=>setDemandIdToEstimation(eachDemand)}>
                                    <div onClick={handleJobFinalizingIcon} style = {{cursor: 'pointer', paddingRight:'3px'}}>
                                    <Tooltip title='Finaliser le travail'>
                                    <PriceCheckIcon style={{paddingRight:'5px'}}/>
                                       
                                    </Tooltip>
                                    Félicitations, votre offre est acceptée. Cliquez ici lorsque vous êtes prêt pour le paiement.
                                    </div>
                                </SpanDemandInfo>  
                                :
                                eachDemand.demand_status ==='finished' ?
                                <SpanDemandInfo> 
                                   <Tooltip title='Please, wait for payment'>
                                       <CreditScoreIcon style={{paddingRight:'3px'}}></CreditScoreIcon>
                                   </Tooltip>
                                   Paiement en cours !
                               </SpanDemandInfo> 
                                :
                                eachDemand.demand_status ==='paid' ?
                                <SpanDemandInfo> 
                                   <Tooltip title='Votre avis apparaîtra bientôt'>
                                       <HourglassTopIcon style={{paddingRight:'3px'}}></HourglassTopIcon>
                                   </Tooltip>
                                   Le paiement est effectué. Le client ajoutera un avis
                               </SpanDemandInfo> 

                                :
                                eachDemand.demand_status ==='reviewed' ?
                                <SpanDemandInfo style = {{cursor: 'pointer', paddingRight:'3px'}}
                                 onClick={()=> [setReviewShowModa(true), setDemandIdToDelete(eachDemand)]}> 
                                   <Tooltip title='Votre avis'>
                                       <CreditScoreIcon style={{paddingRight:'3px'}}></CreditScoreIcon>
                                   </Tooltip>
                                   Votre avis !
                               </SpanDemandInfo> 

                                :
                                null
                                }
                            </span>
                            }
                            
                        </DemandStatus>

                      
                        </div>
                        :
                        <div></div>
                        }
                        
                    </Article>
                    

                    ))}
                    
                    
                </Content>
                <EstimationWindow showModal= {showModal} handleClick={handleClickIconEstimation} demandId={demandIdToEstimation}/>
                <ContractModal showModal= {showModalContract} handleClick={handleClickIconContract} demand={demandIdToEstimation} />
                <ModificationModal showModal={showModificationModal} handleClick={handleModificationModal} demand={demandIdToEstimation} />
                <JobFinalisingModal showModal={showJobFinalizingModal} handleClick={handleJobFinalizingIcon} demand={demandIdToEstimation} />
                <ModalDevisPreview showModal={showDevisModal} handleClick={handleDevisModal} demand={demandDevisTosee}/>
            </Container>

        </>
    );
}


const Container = styled.div`
    grid-area : main;
    
`;

const CommonCard= styled.div`
    text-align: center;
    overflow:hidden;
    margin-bottom: 8px;
    background-color: #fff;
    border-radius:5px;
    position:relative;
    box-shadow: 0 0 0 1px rgba(0 0 0 / 15%), 0 0 0 rgba(0 0 0 /20%);
    border: none;

`;

const SpanDemandInfo = styled.span`
    display: flex;
    flex-direction: column;
    padding:5px;

   
`;


const Article = styled(CommonCard)`
    padding: 0;
    margin: 0 0 8px;
    overflow:visible;
`;

const SharedActor = styled.div`
    padding-right: 40px;
    flex-wrap:nowrap;
    padding: 12px 16px 0;
    margin-bottom: 8px;
    align-items:center;
    display:flex;
    
    a{
        margin-right:12px;
        flex-grow:1;
        overflow:hidden;
        display:flex;
        text-decoration:none;

        img{
            width:48px;
            height:48px;
            border-radius:50%;

        }
        &>div{
            display:flex;
            flex-direction:column;
            flex-grow:1;
            flex-basis:0;
            margin-left: 8px;
            overflow:hidden;
            span{
                text-align:left;
                &:first-child{
                    font-size: 14px;
                    font-weight:700;
                    color: rgba(0,0,0,1);
                }
                &:nth-child(n+1){
                    font-size:12px;
                    color:rgba(0,0,0,0.6);

                }
            }
        }
    }

    button{
        position: absolute;
        right:12px;
        top:10px;
        background:transparent;
        border:none;
        outline:none;
        cursor:pointer;
        img{
            width:18px;
        }
    }
`;

const Description =  styled.div`
    padding : 0 16px;
    overflow: hidden;
    color: rgba(0,0,0,0.9);
    font-size:14px;
    text-align:left;
`;
// 
const SharedImg= styled.div`
    margin-top: 8px;
    width: 100%;
    display:flex;
    justify-content:center;
    position: relative;
    background-color: #f9fafb;
    Grid{
        width:100%;
    }
    img{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
`;


const Content = styled.div`
    text-align: center;
    & > img{
        width: 30px;
    }
`;


const DemandType = styled.div`

justify-content: space-between; 

    text-align:left;
    
    span{
        
        margin-left:10px;
        align-items:center;
        img{
            width:6%;
            margin:10px 10px 10px;
            
        }
    }
`;

const DemandStatus = styled(DemandType)`
`;

const DemandAddress = styled(DemandType)`
    margin-top:5px;
`;

const SeeDevis = styled(DemandAddress)`
:hover{
    cursor:pointer;
}
`;



const mapStateToProps = (state)=> ({firebase, firestore, demands})=> {
    
    return{
        firebase,
        userId: firebase.auth.uid,
        loading: state.demands.loading,   
        // demands: state.demands.demands, ...> the problem was here I was looking at state then looking into demands ... they are already there
        userDemands: demands.demands,     
        error: demands.deleteDemand.error,
        loadingDemandDelete: demands.deleteDemand.loading,
    }
}

const mapDispatchToProps = (dispatch) =>({
    getDemands: ()=> dispatch(getDemandsForArtisanAPI),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainJobSection);