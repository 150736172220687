import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FormWrapper } from '../../Hoc/elements';
import Heading from '../../Components/UI/Headings/Heading';
import Button from '../../Components/UI/Forms/Button/Button';
import Message from '../../Components/UI/Messages/Message';
import * as actions from '../../Store/Actions';

const MessageWrapper = styled.div`
  position: absolute;
  bottom: -2rem;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

const VerifyEmail = ({ sendVerification, error, loading, cleanUp }) => {
    useEffect(() => {
      return () => {
        cleanUp();
      };
    }, [cleanUp]);
  
    return (
        <FormWrapper>
          <Wrapper>
            <Heading noMargin color="white" size="h1">
            Vérifiez votre e-mail
            </Heading>
            <Heading color="white" bold size="h4">
            Accédez à votre boîte d'e_mail et vérifiez votre adresse e-mail. Ensuite, connectez-vous.
            </Heading>
            <Button
              loading={loading? 'Sending email...' : null}
              onClick={() => sendVerification()}
            >
              Renvoyer l'e-mail de vérification
            </Button>
            <MessageWrapper>
                <Message show={error}>{error}</Message>
            </MessageWrapper>

            <MessageWrapper>
              
                <Message success show={error===false}>Veuillez cliquer sur Login</Message>
                <Message success show={error===false}>Message envoyé avec succès!</Message>
            </MessageWrapper>
            
          </Wrapper>
        </FormWrapper>
      );
    };

const mapStateToProps = ({ auth }) => ({
    loading: auth.verifyEmail.loading,
    error: auth.verifyEmail.error,
  });
  
  const mapDispatchToProps = {
    sendVerification: actions.verifyEmail,
    cleanUp: actions.cleanMessage,
  };

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(VerifyEmail);