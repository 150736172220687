import React from 'react';
import Logo from '../../Logo/Logo';
import { Container } from '../../../../Hoc/elements';
import styled from 'styled-components';
import NavItems from '../NavItems/NavItems';
import { connect } from 'react-redux';


const FixedWrapper = styled.div`
    position : fixed;
    display: flex;
    justify-content: space-between;
    padding: 0rem 2rem;
    background-color: #111111 ;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 6rem;
    z-index:10;

    @media(max-width:768px){
      display: none;
  }

    @media ${props => props.theme.mediaQueries.smallest}{
        display: none;
    }
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
`;



const Navbar = ({ loggedIn }) => {
    return (
      <FixedWrapper>
        <Container>
          <Wrapper>
            <Logo />
            <NavItems loggedIn={loggedIn} />         
          </Wrapper>
        </Container>
      </FixedWrapper>
    );
  };


export default Navbar
