import React, { useState } from 'react';
import ReactStars from "react-rating-stars-component";
import { connect } from 'react-redux';
import styled from 'styled-components';

import Modal from '../../../Components/UI/Modal/Modal';
import Button from '../../../Components/UI/Forms/Button/Button';
import Heading from '../../../Components/UI/Headings/Heading';
import Message from '../../../Components/UI/Messages/Message';

import * as actions from '../../../Store/Actions';
import { deletOfferArtisanAction, paymentConfirmationAPI, reviewArtisanAPI } from '../../../Store/Actions/actionOffer';

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  justify-content: space-around;
`;

const DemandWrapper = styled.div`
  margin: 1rem 0rem;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;
  color: #2e2e2e;
`;

const MessageWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  width: 100%;
  padding: 0 3rem;
`;

const Editor = styled.div`
    padding: 12px 24px;
    width:100%;
    textarea{
        width: 100%;
        min-height: 100px;
        resize: none;
        background-color: #e0e6ee ;
        padding-left:5px;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }

    input{
        width: 100%;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }
   
`;


const SeeReviewArtisan = ({show, close, demand, error, loading}) => {

    const [rating, setRating]=useState(0);
    const [editorText, setEditorText]= useState('')

    
    return (
        <Modal opened = {show} close={close}>
            <Heading noMargin size="h1" color="black">
                Votre avis 
            </Heading>
            <Heading bold size="h4" color="black">
            Merci pour votre aide et service!
            </Heading>

            <Editor>
                <label id='phoneNumber'>Your review</label>
                {demand.review && demand.review.review_text?
                
                <textarea
                value={demand.review.review_text}
                readOnly
                placeholder='How was the service...'
                >
                </textarea>
                :
                null

                 }
                
                <label id='phoneNumber'>Votre score</label>
                {demand.review && demand.review.rating?
                <ReactStars
                count={5}
                value={demand.review.rating}
                size={24}
                isHalf={false}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#e0e6ee"
                />
                :
                null
                }
                
            </Editor> 
            <DemandWrapper>{demand.demand}</DemandWrapper>
            
            <ButtonsWrapper>
                
                <Button color="main" contain onClick={close}>
                Fermer
                </Button>
                
                <MessageWrapper>
                    <Message error show={error}>
                    {error}
                    </Message>
                </MessageWrapper>
            </ButtonsWrapper>
        </Modal>  
    )
}


const mapStateToProps = ({demands}) => ({
    error: demands.deleteDemand.error,
    loading: demands.deleteDemand.loading
})

const mapDispatchToProps = (dispatch)=>( {
    //  addReview: (demand) => dispatch(reviewArtisanAPI(demand))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
    )(SeeReviewArtisan);