import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import StripeCheckoutButton from '../../../Components/Stripe/StripeButton/StripeButton';

import ModalDevisPreview from "../../ClientProjects/ModalDevisPreview";

import { Swiper, SwiperSlide } from 'swiper/react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EventIcon from '@mui/icons-material/Event';
import BoltIcon from '@mui/icons-material/Bolt';
import CheckIcon from '@mui/icons-material/Check';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BalconyIcon from '@mui/icons-material/Balcony';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';

import SwiperCore, {
	Keyboard,
	Scrollbar,
	Pagination,
	Navigation,
} from 'swiper/core'

import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/scrollbar/scrollbar.min.css'

import PostModal from "../../../Components/UI/Modal/PostModal";
import { getDemandsAPI , deleteDemand } from "../../../Store/Actions/demandActions";
import DeleteDemand from "../Demand/DeleteDemand";

import ClientCheckEstimation from "../../Jobs/Offers/ClientCheckEstimation";
import ManageEstimationModal from "../ManageEstimationModal";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContractModalClient from "../../Jobs/Offers/ContractModalClient";
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PaymentClientModal from "../../Jobs/Offers/PaymentClientModal";
import ReviewClientModal from "../../Jobs/Offers/ReviewClientModal";
import PinDropIcon from '@mui/icons-material/PinDrop';
import HandshakeIcon from '@mui/icons-material/Handshake';
import Loader from "../../../Components/UI/Loader/Loader";

const useStyles =  makeStyles({
	media:{
        height:0,
		paddingTop: '100%',
	},
	swiperContainer: {
		paddingBottom: '3rem',
		'& .swiper-pagination-bullet': {
			background: '#aaa',
		},
		'& .swiper-button-next:after': {
			fontSize: '1.5rem !important',
            width: '60px',
            height: '30px',
            color: 'rgba(0,0,0,0.3)',
            color: '#111111',
            backgroundColor: '#d6d4d5',
            borderRadius: '50%',
            paddingTop:'5px',

		},
		'& .swiper-button-prev:after': {
			fontSize: '1.5rem !important',
            width: '60px',
            height: '30px',
            color: 'rgba(0,0,0,0.3)',
            color: '#111111',
            backgroundColor: '#d6d4d5',
            borderRadius: '50%',
            paddingTop:'5px',
		},
        "@media (max-width: 768px)":{
            '& .swiper-button-next:after': {
            paddingTop:'7px',
            },
            '& .swiper-button-prev:after': {
                paddingTop:'7px',
            }
        }

	},
})

SwiperCore.use([Keyboard, Scrollbar, Pagination, Navigation])

const Main = (props)=>{

   

    const { media, swiperContainer } = useStyles()

    const [isAdding, setIsAdding] = useState(false);
    const [isDeleting, setIsDeleting]= useState(false);
    const [showPaymentModal, setShowPaymentModal]= useState(false);
    const [showReviewModal, setShowReviewModal]= useState(false);
    const [showModal, setShowModal] = useState('close');
    const [showModalEstim, setShowModalEstim] = useState('close');
    const [showModalManageEstimation, setShowModalManageEstimation] = useState('close');
    const [showModalContract, setShowModalContract] = useState('close');
    const[demandIdToDelete, setDemandIdToDelete]= useState('');
    const [showDevisModal, setDevisModal] = useState('close');
    
    const [demandToSee, setDemandToSee] = useState('');
    
    const [ demandDevisTosee, setDemandDevisToSee] = useState('');

  
    useEffect(()=> {
        props.getDemands()
    }, []);

    

    const handleClick = (e) => {
        e.preventDefault();

        switch(showModal){
            case "open":
                setShowModal('close');
                break;

            case "close":
                setShowModal('open');
                break;

                default:
                    setShowModal('close');
                    break;
        }
    };

    const handleClickIconEstimation = (e)=>{
        e.preventDefault();

        switch(showModalEstim){
            case "open":
                setShowModalEstim('close');
                break;

            case "close":
                setShowModalEstim('open');
                break;

                default:
                    setShowModalEstim('close');
                    break;
        }
    }

    const handleClickIconManageEstimation = (e)=>{
        e.preventDefault();

        switch(showModalManageEstimation){
            case "open":
                setShowModalManageEstimation('close');
                break;

            case "close":
                setShowModalManageEstimation('open');
                break;

                default:
                    setShowModalManageEstimation('close');
                    break;
        }
    }

    const handleIconContract = (e)=>{
        
        e.preventDefault();
        switch(showModalContract){
            case "open":
                setShowModalContract('close');
                break;

            case "close":
                setShowModalContract('open');
                break;

                default:
                    setShowModalContract('close');
                    break;
        }
    }

    const handleDevisModal= (e)=>{
        e.preventDefault();

        switch(showDevisModal){
            case "open":
                setDevisModal('close');
                break;

            case "close":
                setDevisModal('open');
                break;

                default:
                    setDevisModal('close');
                    break;
        }
    } ;

    return(
        <>
        
        {console.log(props.loadingDemandPosting)}
            <Container>
                {/* <ShareBox>
                    <div> */}
                        {/* {props.userId && props.firebase.profile.profile_image ? 
                            <img src={props.firebase.profile.profile_image}/>
                        :
                            <img src="/images/user.svg" alt="" />
                        } */}
                        <StyledButton onClick={handleClick}
                            disabled={props.loading ? true: false}
                            >Demander un devis
                        </StyledButton>
                    {/* </div>
                </ShareBox> */}
                <Content>
                    

                    {props.loadingDemandPosting && <Loader/>}
                    {props.loadingDemandDelete === true &&
                    <Loader/>}
                  
                    {props.userDemands.length > 0 &&
                    props.userDemands.map((eachDemand, key)=> (
                 
                    eachDemand.demand_status !== 'reviewed' ? 

                    <Article key={key}>
                        <SharedActor>
                            <a>
                                {eachDemand.client_profile_image ? 
                                <img src={eachDemand.client_profile_image} />
                                :
                                
                                <img src='/images/user.svg'/>
                                }
                                <div>
                                    {eachDemand.user_firstname ? 
                                    <span>{eachDemand.user_firstname}</span> :
                                    <span></span>
                                    }
                                    
                                    <span>{eachDemand.date.toDate().toLocaleDateString()}</span>
                                </div>
                            </a>
                            

                            {/* demand status actions OFFER actions */}
                            {eachDemand.demand_status ==='contract_offered' ?

                                <SpanDemandInfo  onClick={handleIconContract}> 
                                <Tooltip title='Merci de regarder le contrat'>
                                <div onClick={()=>  setDemandToSee(eachDemand)} style = {{fontWeight:'bold', cursor:'pointer'}}>
                                 
                                 <CheckCircleOutlineIcon style={{paddingRight:'3px'}}/>
                                 
                                </div>
                                </Tooltip>
                                </SpanDemandInfo>
                                :
                                eachDemand.demand_status ==='estimation_confirmed' ?
                                <SpanDemandInfo >
                                <div >
                                    <Tooltip title='Attendez le contrat'>
                                    <HourglassTopIcon style={{paddingRight:'3px'}}/>
                                    </Tooltip>
                                </div>
                                </SpanDemandInfo>
                                :
                                eachDemand.demand_status ==='estimation_visit_valid' ?
                                <SpanDemandInfo > 
                                <Tooltip title='Attendez le contrat'>
                                    <HourglassTopIcon style={{paddingRight:'3px'}}></HourglassTopIcon>
                                </Tooltip>
                            
                                </SpanDemandInfo>   
                                :
                                eachDemand.demand_status ==='modification_asked' ?
                                <SpanDemandInfo  onClick={handleIconContract}> 
                                <Tooltip title='Merci de regarder le contrat'>
                                <HourglassTopIcon style={{paddingRight:'3px'}}></HourglassTopIcon>
                                </Tooltip>
                                </SpanDemandInfo> 
                                :
                                eachDemand.demand_status ==='assigned' ?
                                
                                <SpanDemandInfo >
                                <Tooltip title=  {'Votre projet est prêt à démarrer'  } >
                                <div  style = {{fontWeight:'bold', cursor:'pointer'}}>
                                <PriceCheckIcon />
                                </div>
                                </Tooltip>
                                </SpanDemandInfo> 
                                
                                :
                                eachDemand.demand_status ==='finished' ?
                                <SpanDemandInfo style={{cursor:'pointer'}}>
                                    
                                    <StripeCheckoutButton demand={eachDemand} onTop='true' price={eachDemand.contract.total_cost}  />
                                </SpanDemandInfo>  
                                                                                          
                                :
                                eachDemand.demand_status ==='paid'?
                                <SpanDemandInfo onClick={()=>[setShowReviewModal(true), setDemandIdToDelete(eachDemand)]}  style={{cursor:'pointer'}}>
                                    <Tooltip title='Ajouter un commentaire'>
                                    <ThumbsUpDownIcon  style={{paddingRight:'3px'}}></ThumbsUpDownIcon>
                                    </Tooltip>
                                </SpanDemandInfo>
                                :
                                eachDemand.demand_status ==='unassigned' ?
                                <Tooltip title="En recherche d'un artisan">
                                    <HourglassTopIcon style={{paddingRight:'3px', cursor: 'pointer'}}></HourglassTopIcon>
                                </Tooltip>
                                :
                                <a> 
                                </a>  
                            }

                            {/* DELETING ICON */}
                            {eachDemand.demand_status && eachDemand.demand_status ==='finished'  ?
                            <Tooltip title="Vous ne pouvez pas supprimer votre projet maintenant !">
                            <DeleteIcon  style = {{cursor:'pointer', color:'#aaa'}}/>
                            </Tooltip>
                            :
                            eachDemand.demand_status && eachDemand.demand_status ==='assigned'  ?
                            <Tooltip title="Vous ne pouvez pas supprimer votre offre maintenant !">
                            <DeleteIcon  style = {{cursor:'pointer', color:'#aaa'}}/>
                            </Tooltip>
                            
                            :
                            eachDemand.demand_status && eachDemand.demand_status ==='paid'  ?
                            <Tooltip title="Vous ne pouvez pas supprimer votre offre maintenant !">
                            <DeleteIcon  style = {{cursor:'pointer', color:'#aaa'}}/>
                            </Tooltip>
                            :
                            eachDemand.demand_status && eachDemand.demand_status ==='reviewed'  ?
                            <Tooltip title="Vous ne pouvez pas supprimer votre offre maintenant !">
                            <DeleteIcon  style = {{cursor:'pointer', color:'#aaa'}}/>
                            </Tooltip>
                            :

                            <Tooltip title="Supprimer la demande">
                            <DeleteIcon onClick={()=>  [setIsDeleting(true), setDemandIdToDelete(eachDemand)]} style = {hoverPointer}/>
                            </Tooltip>

                            }
                            <DeleteDemand
                            demand={demandIdToDelete}
                            show={isDeleting}
                            close={()=> setIsDeleting(false)}
                            >
                            </DeleteDemand>

                        </SharedActor>
                                                
                        {/* <SharedImg> */}

                        
                            {/* <Grid item xs={12} align-items='center' > */}
                            <SharedImg>    
                            <Grid item container xs={12}  justifyContent='center'>
                                <Grid item xs={12} >
                                <Swiper 
                                        slidesPerView= 'auto'
                                        grabCursor
                                        keyboard={{ enabled: true }}
                                        pagination={{ clickable: true }}
                                        navigation
                                        className={swiperContainer}
                                        >
                                            {eachDemand.demand_image.map((image, index)=>(
                                                <SwiperSlide key={index}>
                                                    <CardMedia className={media} image={image}/>
                                                </SwiperSlide>

                                            ))}
                                        </Swiper>    
                                </Grid>
                            </Grid>
                            </SharedImg>
                                        
                                                                                
                                    
                            {/* </Grid>  */}
                        {/* </SharedImg> */}
                        
                        <Description>
                            {eachDemand.description}
                        </Description>

                        <DemandAddress>
                        <span>
                            <Tooltip title='Adresse de la demande'>
                            <PinDropIcon/>
                            </Tooltip>
                            {eachDemand.demand_address && 
                            <SpanDemandInfo>
                                {eachDemand.demand_address}
                            </SpanDemandInfo>
                            }
                        </span>
                        </DemandAddress>

                        {/* Visualizing the devis */}
                           {/* Visualizing the devis */}

                           {eachDemand.demand_status != "unassigned" ?
                    
                            <SeeDevis onClick={handleDevisModal } >
                                <SpanDemandInfo onClick={()=> setDemandDevisToSee(eachDemand) }>
                                <Tooltip title='Voir le devis'>
                                <HandshakeIcon/>
                                </Tooltip>
                                L'etat des travaux et devis
                                </SpanDemandInfo>
                            </SeeDevis>
                            :
                            null
                            }
                        
                        
                        <DemandType >
                            <span>Type de demande: </span>
                            {eachDemand.demand_type==='window' ?
                            <span>
                             <Tooltip title="Type de demande: menuiserie">
                                <BalconyIcon fontSize="large"/>
                            </Tooltip>
                            </span>
                        :
                        eachDemand.demand_type==='electricity' ?
                            <span>
                                <Tooltip title="Type de demande : électricité">
                                <BoltIcon fontSize="large"/>
                                </Tooltip>
                            </span>
                        :
                            <span>
                                <img src=''/>
                            </span>
                        }
                        </DemandType>
                        
                        <DemandStatus>
                            <span>État de la demande: </span>
                            {eachDemand.demand_status && 
                            <span>
                                {eachDemand.demand_status ==='unassigned' ?
                                <SpanDemandInfo>
                                    <PersonSearchIcon/>
                                    Recherche d'artisan
                                
                                </SpanDemandInfo>
                                :
                                eachDemand.demand_status ==='estimation_wait' ?
                                <SpanDemandInfo  onClick={handleClickIconEstimation}>
                                <Tooltip title='Look at the estimation'>
                                <div onClick={()=>  setDemandToSee(eachDemand)} style = {{fontWeight:'bold', cursor:'pointer'}}>
                                 
                                 <CheckIcon style={{paddingRight:'3px'}}/>
                                 Nous avons trouvé un artisan pour vous

                                </div>
                                </Tooltip>
                                </SpanDemandInfo>



                                :
                                eachDemand.demand_status ==='estimation_confirmed' ?
                                <a> 
                                <SpanDemandInfo > 
                                    <Tooltip title='Attendez le contrat'>
                                        <HourglassTopIcon style={{paddingRight:'3px'}}></HourglassTopIcon>
                                    </Tooltip>
                                    {eachDemand.estimation.artisan_firstname} prépare le contrat
                                
                                </SpanDemandInfo> 
                                
                                <SpanDemandInfo> 
                                    <Tooltip title="Numéro de téléphone de l'artisan">    
                                    <LocalPhoneIcon> </LocalPhoneIcon>
                                    </Tooltip>
                                    <a href={"tel: " + eachDemand.estimation.artisan_phone_number}>
                                    {eachDemand.estimation.artisan_phone_number}
                                    </a>
                                </SpanDemandInfo> 
                                
                                {/* <SpanDemandInfo>
                                    <Tooltip title='visit date'>
                                    <EventIcon style={{paddingRight:'3px'}}/>
                                    </Tooltip>
                                    {eachDemand.estimation.visit_date} 
                                </SpanDemandInfo>  */}

                               
                                    
                                </a> 
                                :
                                eachDemand.demand_status ==='estimation_visit_valid' ?
                                <SpanDemandInfo > 
                                    <Tooltip title='Attendez le contrat'>
                                        <HourglassTopIcon style={{paddingRight:'3px'}}></HourglassTopIcon>
                                    </Tooltip>
                                    {eachDemand.estimation.artisan_firstname} prépare le contrat
                                
                                </SpanDemandInfo>   
                                :
                                eachDemand.demand_status ==='contract_offered' ?
                                <SpanDemandInfo  onClick={handleIconContract}> 
                                <Tooltip title='Le contrat'>
                                <div onClick={()=>  setDemandToSee(eachDemand)} style = {{fontWeight:'bold', cursor:'pointer'}}>
                                
                                <CheckCircleOutlineIcon style={{paddingRight:'3px'}}/>
                                Le contrat est arrivé ! Regardez!
                                
                                </div>
                                </Tooltip>
                                </SpanDemandInfo> 
                                :
                                eachDemand.demand_status ==='modification_asked' ?
                                <SpanDemandInfo  > 
                                <Tooltip title='Le contrat'>
                                <HourglassTopIcon style={{paddingRight:'3px'}}></HourglassTopIcon>
                                </Tooltip>
                                La modification est envoyée. Attendez la réponse de l'artisan !
                                </SpanDemandInfo> 
                                :
                                eachDemand.demand_status ==='assigned' ?
                                <a >
                                <SpanDemandInfo> 
                                <PriceCheckIcon style={{paddingRight:'5px'}}/>
                                Le contrat est établi. Le début du projet est le {eachDemand.contract.start_date}
                                </SpanDemandInfo>
                                <SpanDemandInfo> 
                                    <Tooltip title="Numéro de téléphone de l'artisan">    
                                    <LocalPhoneIcon style={{paddingRight:'3px'}}> </LocalPhoneIcon>
                                    </Tooltip>
                                    <a href={"tel: " + eachDemand.contract.artisan_phone_number}>
                                    {eachDemand.contract.artisan_phone_number}
                                    </a>
                                
                                </SpanDemandInfo> 
                                </a>
                                
                                :
                                
                                eachDemand.demand_status ==='finished' ?
                                <SpanDemandInfo>

                                <StripeCheckoutButton onTop='false' price={eachDemand.contract.total_cost} demand={eachDemand}  /> 
                               
                                </SpanDemandInfo>
                                
                                :
                                eachDemand.demand_status ==='paid'?
                                <a onClick={()=>[setShowReviewModal(true), setDemandIdToDelete(eachDemand)]}>
                                <SpanDemandInfo style={{cursor:'pointer'}}>
                                    <Tooltip title='Add a review'>
                                    <ThumbsUpDownIcon  style={{paddingRight:'5px'}}></ThumbsUpDownIcon>
                                    </Tooltip>
                                    S'il vous plaît ajouter un avis!
                                </SpanDemandInfo>
                                </a>
                                
                                :
                                null  
                                }
                            </span>
                            
                            }
                            <PaymentClientModal
                                demand={demandIdToDelete}
                                show={showPaymentModal}
                                close={()=> setShowPaymentModal(false)}
                            >
                            </PaymentClientModal>
                            <ReviewClientModal
                                demand={demandIdToDelete}
                                show={showReviewModal}
                                close={()=> setShowReviewModal(false)}
                            >  
                            </ReviewClientModal>
                        </DemandStatus>

                      
                    </Article>
                    :
                    null
                    ))}
                    
                </Content>
                
                <PostModal showModal={showModal} handleClick={handleClick}/>
                <ClientCheckEstimation showModal={showModalEstim} handleClick={handleClickIconEstimation} demand = {demandToSee}/>
                <ManageEstimationModal showModal= {showModalManageEstimation} handleClick={handleClickIconManageEstimation} demand={demandToSee} />
                <ContractModalClient showModal={showModalContract} handleClick={handleIconContract} demand={demandToSee}/>
                <ModalDevisPreview showModal={showDevisModal} handleClick={handleDevisModal} demand={demandDevisTosee}/>
            </Container>

        </>
    );
}


const Container = styled.div`
    grid-area : main;
    width:60%;
    @media(max-width:1300px){
        width:100%;
    }
    
`;



const CommonCard= styled.div`
    text-align: center;
    overflow:hidden;
    margin-bottom: 8px;
    background-color: #fff;
    border-radius:5px;
    position:relative;
    box-shadow: 0 0 0 1px rgba(0 0 0 / 15%), 0 0 0 rgba(0 0 0 /20%);
    border: none;

`;


const SpanDemandInfo = styled.span`
    display: flex;
    flex-direction: column;
    padding:5px;
`;



const StyledButton = styled.button`
  width: ${({ contain }) => (contain ? 'auto' : '100%')};
  outline: none;
  padding: 1.2rem 5rem;
  border-radius: 2rem;
  font-size: 1.2rem;
  color: #111111;
  font-weight: 700;
  justify-content: center;
  box-shadow: 0rem 0.5rem 3.5rem var(--shadow);
  background-color: ${({ color }) => {
    if (color === 'red') return 'var(--color-errorRed)';
    else if (color === 'main') return 'var(--color-mainDark)';
    else return '#fff';
  }};
  margin: 1.5rem 0 2rem 0;
  border: none;
  transition: all 0.2s;
  &:hover {
    transform: translateY(-3px);
  }
  &:active {
    transform: translateY(2px);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #333;
  }
`;


const Article = styled(CommonCard)`
    padding: 0;
    margin: 0 10px 8px;
    overflow:visible;
`;

const SharedActor = styled.div`
    padding-right: 40px;
    flex-wrap:nowrap;
    padding: 12px 16px 0;
    margin-bottom: 8px;
    align-items:center;
    display:flex;
    
    a{
        margin-right:12px;
        flex-grow:1;
        overflow:hidden;
        display:flex;
        text-decoration:none;

        img{
            width:48px;
            height:48px;
            border-radius:50%;

        }
        &>div{
            display:flex;
            flex-direction:column;
            flex-grow:1;
            flex-basis:0;
            margin-left: 8px;
            overflow:hidden;
            span{
                text-align:left;
                &:first-child{
                    font-size: 14px;
                    font-weight:700;
                    color: rgba(0,0,0,1);
                }
                &:nth-child(n+1){
                    font-size:12px;
                    color:rgba(0,0,0,0.6);

                }
            }
        }
    }

    button{
        position: absolute;
        right:12px;
        top:10px;
        background:transparent;
        border:none;
        outline:none;
        cursor:pointer;
        img{
            width:18px;
        }
    }
`;

const Description =  styled.div`
    padding : 0 16px;
    overflow: hidden;
    color: rgba(0,0,0,0.9);
    font-size:14px;
    text-align:left;
`;
// 
const SharedImg= styled.div`
    margin-top: 8px;
    width: 100%;
    display:flex;
    justify-content:center;
    position: relative;
    background-color: #f9fafb;
    Grid{
        width:100%;
    }
    img{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
`;


const Content = styled.div`
    text-align: center;
    & > img{
        width: 30px;
    }
    
`;



const DemandType = styled.div`

justify-content: space-between; 

    text-align:left;
    
    span{
        
        margin-left:10px;
        align-items:center;
        img{
            width:6%;
            margin:10px 10px 10px;
            
        }
    }
`;

const DemandStatus = styled(DemandType)`    
`;

const DemandAddress = styled(DemandType)`
    margin-top:5px;
    span{
        display:flex;
        flex-direction:row;
    }
`;

const SeeDevis = styled(DemandType)`
    :hover{
        cursor:pointer;
    }
`;

const OfferArrivedLink = styled.a`
    width: ${({ contain }) => (contain ? 'auto' : '100%')};
    outline: none;
    padding: 1.2rem 1.5rem;
    border-radius: 2rem;
    font-size: 1rem;
    color: #111111;
    font-weight: 700;
    box-shadow: 0/2rem 0.5rem 0.5rem var(--shadow);
    background-color: #E8E8E8;
    margin: 1.5rem 0 2rem 0;
    border: none;
    transition: all 0.2s;
    &:hover {
    transform: translateY(-3px);
    color: #aa6c39;
    text-decoration:none;
    }
    &:active {
    transform: translateY(2px);
    }
    &:disabled {
    cursor: not-allowed;
    background-color: #333;
    }
`;

const hoverPointer = {cursor: 'pointer'};

const mapStateToProps = (state)=> ({firebase, firestore, demands, users})=> {
    
    return{
        firebase,
        userId: firebase.auth.uid,
        loadingDemandPosting: demands.loading,   
        // demands: state.demands.demands, ...> the problem was here I was looking at state then looking into demands ... they are already there
        userDemands: demands.demands,     
        error: demands.deleteDemand.error,
        loadingDemandDelete: demands.deleteDemand.loading,
    }
}

const mapDispatchToProps = (dispatch) =>({
    getDemands: ()=> dispatch(getDemandsAPI()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);