import { useState } from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase';

import styled from 'styled-components';
import { addDemandToDbAPI, postDemandAPI } from '../../../Store/Actions/demandActions';
import Resizer from 'react-image-file-resizer';




const PostModal = (props) =>{

    const [editorText, setEditorText] = useState("");
    const [shareImages, setShareImages] = useState([]);
    const[assetArea, setAssetArea] = useState("");
    const [clientPhoneNumber, setClientPhoneNumber] = useState(props.firebase.profile.phone_number);
    const [clientAddress, setClientAddress] = useState('');
    const [demandType, setDemandType] = useState('');
    const [demandRegion, setDemandRegion] = useState('');

    const handleImageChange =  (e) => {
        const maxLengthFile= 4;
        if (Array.from(e.target.files).length > maxLengthFile) {
            e.preventDefault();
            alert(`Ne téléchargeons pas plus de ${maxLengthFile} images, pour être plus propre.`);
            return;
          }else{
            for(let i=0; i<e.target.files.length; i++){
                const newImage = e.target.files[i];
                newImage['id']= Math.random();
                setShareImages((prevState)=> [...prevState, newImage]);

            }
        }
    };

    const handlePhoneNumber = (e)=>{
        const clientPhone = e.target.value;

        setClientPhoneNumber(clientPhone);
    }

    //
    const handleAddress = (e) => {
        const addressInput = e.target.value;

        setClientAddress(addressInput);
    }

    const handleDemandType = (e)=>{
        const demandTypeInput = e.target.value;
        setDemandType(demandTypeInput);
    }

    const handleDemandRegion = (e)=>{
        const regionInput = e.target.value;
        setDemandRegion(regionInput);
    }

    const switchAssetArea = (area) =>{
        setShareImages("");
        setAssetArea(area);
    }


    const postDemand = (e) =>{

        const userId = firebase.auth.uid;

        const payload = {
            clientId: props.firebase.auth.uid,
            demandImages: shareImages,
            userFirstName: props.firebase.profile.firstName,
            userLastName: props.firebase.profile.lastName,
            userEmail:props.firebase.profile.email,
            clientProfileImage: props.firebase.profile.profile_image,
            clientAddress: props.firebase.profile.address,
            description: editorText,
            // demandType: demandType,
            demandType: 'window',
            contactNumber: clientPhoneNumber,
            timestamp: firebase.firestore.Timestamp.now(),
            address: clientAddress,
            // demandRegion:demandRegion,
            demandRegion: 'aquitaine',
        }

        props.postDemand(payload);
        // console.log(payload);

        reset(e);
    }

    const reset= (e) =>{
        setEditorText('');
        setShareImages([]);
        setAssetArea("");

        props.handleClick(e);
    }

    return(
     <>

        {props.showModal === 'open' && (
            <Container>
                <Content>
                    <Header>
                        <h2>Votre demande</h2>
                        <button onClick={(event) => reset(event)}>
                            <img src="/images/close.png" alt="" />
                        </button>
                    </Header>
                    <SharedContent>
                        <UserInfo>
                            {props.userId && props.firebase.profile.profile_image ? <img src={props.firebase.profile.profile_image}/>
                            :
                            <img src="/images/user.svg" alt="" />
                            }
                            <span>{props.firebase.profile.firstName}</span>
                        </UserInfo>

                        <Editor>
                            <textarea style={{fontSize : '16px'}} value={editorText} onChange={(e) => setEditorText(e.target.value)} placeholder='Decrivez votre demand. Des mesures? *' autoFocus={true}></textarea>
                            
                            {/* <StyledSelect  onChange={handleDemandType}>
                            <option disabled selected value="">Type de demande</option>
                            <option value="window">Vitrerie, Menuiserie, Miroiterie</option>
                            <option value="electricity">Jardinage</option>
                            </StyledSelect>  */}

                            <label id='phoneNumber'>Votre numéro de téléphone *</label>
                            <input value={clientPhoneNumber}  type="number" onChange={handlePhoneNumber}/>


                           {/* <label id='region'>Actuellement, nous couvrons ces régions mais nous allons grandir *</label>
                             <StyledSelect  onChange={handleDemandRegion}>
                            <option disabled selected value="">Choisissez votre région</option>
                            <option value="occitanie">Occitanie</option>
                            <option value="aquitaine">Aquitaine</option>
                            </StyledSelect>  */}
                            
                            <label id='address'>Adresse du chantier *</label>
                            <input value={clientAddress}  type="text" onChange={handleAddress}/>                      

                            <UploadImage>
                            Il y aura plus de chances de trouver un meilleur artisan en téléchargeant des photos du chantier
                                <input type="file" 
                                    accept = 'image/gif, image/jpeg, image/png' 
                                    name='image'
                                    multiple
                                     id='fileInput'
                                    style={{display:"none"}}
                                    onChange={handleImageChange}
                                />
                                {shareImages[0] && <img src={URL.createObjectURL(shareImages[0])}/>
                                }
                                {shareImages[1] && <img src={URL.createObjectURL(shareImages[1])}/>
                                }
                                {shareImages[2] && <img src={URL.createObjectURL(shareImages[2])}/>
                                }
                                {shareImages[3] && <img src={URL.createObjectURL(shareImages[3])}/>
                                }
                                {/* {shareImages[4] && <img src={URL.createObjectURL(shareImages[4])}/>
                                }
                                {shareImages[5] && <img src={URL.createObjectURL(shareImages[5])}/>
                                }
                                {shareImages[6] && <img src={URL.createObjectURL(shareImages[6])}/>
                                } */}
                            </UploadImage>
                        </Editor>
                             {/* 
                               
                              
                        </Editor> */}

                    </SharedContent>
                    <SharedCreation>
                        <AttachAssets>
                            <AssetButton  onClick={() => switchAssetArea('image')}>
                            
                            <label htmlFor="fileInput" style={{cursor:'pointer'}}>
                                <img src="/images/photo.svg" alt="" />
                            </label>
                            </AssetButton>
                            
                        </AttachAssets>
                        
                      
                        <PostButton 
                             disabled={ !clientPhoneNumber || !clientAddress ? true : false}
                             onClick={(event)=> postDemand(event) }
                        >Publier
                        </PostButton>
                    </SharedCreation>
                </Content>
            </Container>
        )}
     </>
    )
};

const Container = styled.div`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:9999;
    color:black;
    background-color:rgba(0,0,0,0.8);
    animation: fadeIn 0.3s;
`;

const Content = styled.div`
    width:100%;
    max-width:552px;
    background-color:white;
    max-height:90%;
    overflow:initial;
    border-radius:5px;
    position: relative;
    display:flex;
    flex-direction:column;
    top:32px;
    margin: 0 auto;
`;

const Header = styled.div`
    display:block;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(0,0,0, 0.15);
    font-size:16px;
    line-height: 1.5;
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items:center;
    button{
        height:40px;
        width:40px;
        min-width:auto;
        color: rgba(0,0,0,0.15);
        background-color:transparent;
        border: none;
        justify-content: center;
        cursor: pointer;
        &:hover{
            border-radius:50%;
            background-color: #aaa;
        }
        
    }
    img{
        width:40px;
        margin-bottom:3px;
    }
`;

const SharedContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow:1;
    overflow-y:auto;
    vertical-align: baseline;
    background: transparent;
    padding: 8px 12px;
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 12px;

    svg, img{
        width: 48px;
        height:48px;
        background-clip: content-box;
        border: 2px solid transparent;
        border-radius: 50%;
    }
    span{
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        margin-left: 5px;
    }
`;

const SharedCreation = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 12px 16px;
`;

const AssetButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-width: auto;
    color: rgba(0,0,0,0.5);
    border-radius:5px;
    cursor: pointer;
    img{
        width: 25px;
        margin-top:5px;
        
    }
`;

const AttachAssets = styled.div`
    align-items: center;
    display: flex;
    padding-right: 8px;
    ${AssetButton}{
        width:40px;
    }
`;

const ShareComment =styled.div`
    padding-left: 8px;
    margin-right:auto;
    border-left: 1px solid rgba(0,0,0,0.15);
    ${AssetButton}{
        svg{
            margin-right: 5px;
        }
    }
`;

const PostButton = styled.button`
    min-wifth:60px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#111111')};
    color: ${(props)=> (props.disabled ? "#aaaa" : 'white')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'};
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;

const Editor = styled.div`
    padding: 12px 24px;

    textarea{
        width: 100%;
        min-height: 100px;
        resize: none;
    }

    input{
        width: 100%;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
    }
`;

const UploadImage = styled.div`
    text-align:center;
    img{
        width:100%;
    }
`;

const StyledSelect = styled.select`
  padding: 1.2rem 2rem;
  width: 100%;
  background-color: #242526;
  color: var(--color-white);
  font-weight: 500;
  font-size: 1.2rem;
  border-radius: 2rem;
  border: none;
  margin-bottom:1rem;
  padding: 4px ;
  &::placeholder {
    color: var(--color-white);
  }
`;

const mapStateToProps = (state) => ({firebase, firestore}) => {
    return{
        firebase,
        userId: firebase.auth.uid,

    }
};

const mapDispatchToProps =(dispatch)=> ({
    postDemand: (payload)=> dispatch(addDemandToDbAPI(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostModal);