import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import styled from 'styled-components';
import firebase from 'firebase';
import CurrencyInput from 'react-currency-input-field';
import Tooltip from '@mui/material/Tooltip';


import * as actions from '../../../Store/Actions';
import { acceptEstimation, addEstimation, rejectEstimation } from "../../../Store/Actions/actionOffer";
import { borderRadius } from "@mui/system";
import CheckArtisanBoutique from "../../Boutique/CheckArtisanBoutique";

const ClientCheckEstimation = (props)=>{

    

    const [editorText, setEditorText] = useState("");
    const[assetArea, setAssetArea] = useState("");
    const [artisanPhoneNumber, setArtisanPhoneNumber] = useState(0);
    const [materialCost, setMaterialCost] = useState(0);
    const [wage, setWage] = useState(0);
    const [estimatedVisitDate, setEstimatedVisitDate] = useState('');
    const [artisanIdToSee, setArtisanIdToSee] = useState('');

    // for goint to artisan'page
    const history = useHistory();   
    const routeChange = ()=>{
        let path = 'artisanboutique';
        history.push({pathname: path, state:{artisanId: props.demand.estimation.artisan_id}});
    }
    //....
    
    const acceptFunction = (e)=>{
        const demand= props.demand;
        const demandID = demand.demand_id;

        props.acceptFunction(demand);
        reset(e);
    }

    const rejectFunction = (e)=>{
        const demand = props.demand;
        props.rejectFunction(demand);
        reset(e);
    }

    const reset= (e) =>{

        props.handleClick(e);
    }

    return (
        <>

        {props.showModal === 'open' && (
            <Container>
            <Content>
                <Header>
                    <h2 style={{fontWeight:'bold'}}>Vous pouvez contacter cet artisan pour votre projet</h2>
                    <button onClick={(event) => reset(event) }>
                        <img src="/images/close.png" alt="" />
                    </button>
                </Header>
                <SharedContent>
                    <div style={{cursor: 'pointer'}} >
                    <UserInfo onClick={routeChange}>
                        {props.userId && props.demand.estimation.artisan_profile_image? <img src={props.demand.estimation.artisan_profile_image}/>
                        :
                        <img src="/images/user.svg" alt="" />
                        }
                        <span>{props.demand.estimation.artisan_firstname}</span>
                    </UserInfo>
                    </div>
                    

                    <Editor>
                    <textarea readOnly  style={{border: '1px solid #c0a375',borderRadius: '5px', paddingLeft:'8px'}}>
                        {props.demand.estimation.estimation_description}
                    </textarea>

                    <label id='phoneNumber'>Numéro de téléphone de l'artisan</label>
                        {props.demand.estimation &&
                        
                        <input readOnly value={props.demand.estimation.artisan_phone_number}  type="number" style={{border: '1px solid #c0a375',borderRadius: '5px', paddingLeft:'8px'}} />
                        }
                        {/* <label id='materialCost'>Estimation du coût du matériel €</label>
                        {props.demand.estimation &&
                         <input readOnly value={props.demand.estimation.estimation_material_cost}  type="number" style={{border: '1px solid #c0a375',borderRadius: '5px', paddingLeft:'8px'}} />
                        }
                         
                         <label id='materialCost'>Estimation du salaire €</label>
                         {props.demand.estimation &&
                        <input readOnly value={props.demand.estimation.estimation_wage}  type="number" style={{border: '1px solid #c0a375',borderRadius: '5px', paddingLeft:'8px'}} />
                        }
                         
                         <label id='estimatedVisitDate'>Date de visite</label>
                         {props.demand.estimation &&
                         <input readOnly value={props.demand.estimation.visit_date}   type="text" style={{border: '1px solid #c0a375',borderRadius: '5px', paddingLeft:'8px'}} />   
                         } */}
                        
                        
                    </Editor>
                         

                </SharedContent>
                <SharedCreation>   
                               
                <RejectButton 
                        //  onClick={(event)=> postEstimation(event)  }
                        onClick={(event)=> rejectFunction(event)}
                    >Rejeter
                </RejectButton>
                <AcceptButton 
                        //  onClick={(event)=> postEstimation(event)  }
                        onClick={(event)=> acceptFunction(event)}
                >Accepter 
                </AcceptButton>
                    
                </SharedCreation>
                
            </Content>

            
        </Container>
        )}
     </> 
    );
}


const Container = styled.div`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:9999;
    color:black;
    background-color:rgba(0,0,0,0.8);
    animation: fadeIn 0.3s;
`;

const Content = styled.div`
    width:100%;
    max-width:552px;
    background-color:white;
    max-height:90%;
    overflow:initial;
    border-radius:5px;
    position: relative;
    display:flex;
    flex-direction:column;
    top:32px;
    margin: 0 auto;
`;

const Header = styled.div`
    display:block;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(0,0,0, 0.15);
    font-size:16px;
    line-height: 1.5;
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items:center;
    button{
        height:40px;
        width:40px;
        min-width:auto;
        color: rgba(0,0,0,0.15);
        background-color:transparent;
        border: none;
        justify-content: center;
        cursor: pointer;
        &:hover{
            border-radius:50%;
            background-color: #aaa;
        }
        
    }
    img{
        width:40px;
        margin-bottom:3px;
    }
`;

const SharedContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow:1;
    overflow-y:auto;
    vertical-align: baseline;
    background: transparent;
    padding: 8px 12px;
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 12px;

    svg, img{
        width: 48px;
        height:48px;
        background-clip: content-box;
        border: 2px solid transparent;
        border-radius: 50%;
    }
    span{
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        margin-left: 5px;
    }
`;

const SharedCreation = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 12px 16px;
`;

const AssetButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-width: auto;
    color: rgba(0,0,0,0.5);
    border-radius:5px;
    cursor: pointer;
    img{
        width: 25px;
        margin-top:5px;
        
    }
`;

const AttachAssets = styled.div`
    align-items: center;
    display: flex;
    padding-right: 8px;
    ${AssetButton}{
        width:40px;
    }
`;

const ShareComment =styled.div`
    padding-left: 8px;
    margin-right:auto;
    border-left: 1px solid rgba(0,0,0,0.15);
    ${AssetButton}{
        svg{
            margin-right: 5px;
        }
    }
`;

const AcceptButton = styled.button`
    min-wifth:60px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    border:none;
    margin-right:20px;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#007500')};
    color: ${(props)=> (props.disabled ? "#aaaa" : 'white')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'};
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;
const RejectButton = styled.button`
    min-wifth:60px;
    margin-left:20px;
    border-radius: 20px;
    border:none;
    padding-left: 16px;
    padding-right: 16px;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#D70A00')};
    color: ${(props)=> (props.disabled ? "#aaaa" : 'white')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'}; 
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;

const Editor = styled.div`
    padding: 12px 24px;

    textarea{
        width: 100%;
        min-height: 100px;
        resize: none;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }

    input{
        width: 100%;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }
`;



const mapStateToProps = (state) => ({firebase, firestore})=>{
    return{
        firebase,
        userId:firebase.auth.uid,
    }
};

const mapDispatchToProps = (dispatch)=>({
    // postEstimation: (payload)=> dispatch(addEstimation(payload)),
    acceptFunction: (demand)=>dispatch(acceptEstimation(demand)),
    rejectFunction: (demand)=> dispatch(rejectEstimation(demand)),
});

export default connect( mapStateToProps,mapDispatchToProps)(ClientCheckEstimation);