import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Modal from '../../../Components/UI/Modal/Modal';
import Button from '../../../Components/UI/Forms/Button/Button';
import Heading from '../../../Components/UI/Headings/Heading';
import Message from '../../../Components/UI/Messages/Message';

import * as actions from '../../../Store/Actions';
import { deletOfferArtisanAction, paymentConfirmationAPI } from '../../../Store/Actions/actionOffer';

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  justify-content: space-around;
`;

const DemandWrapper = styled.div`
  margin: 1rem 0rem;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;
  color: #2e2e2e;
`;

const MessageWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  width: 100%;
  padding: 0 3rem;
`;


const PaymentClientModal = ({show, close, demand, error, loading, paymentConfirm}) => {

    return (
        <Modal opened = {show} close={close}>
            <Heading noMargin size="h1" color="black">
            Vérification des paiements !
            </Heading>
            <Heading bold size="h4" color="black">
            Avez-vous payé pour ce service ?
            </Heading>
            <DemandWrapper>{demand.demand}</DemandWrapper> 
            <ButtonsWrapper>
                
                <Button color="main" contain onClick={close}>
                Pas ecore!
                </Button>
                <div onClick={close}>
                <Button
                contain
                color="main"
                onClick={ async() => 
                    await paymentConfirm(demand)}
                
                disabled={loading}
                loading={loading ? 'Deleting...' : null}
                > 
                OUI
                </Button>
                </div>
                <MessageWrapper>
                    <Message error show={error}>
                    {error}
                    </Message>
                </MessageWrapper>
            </ButtonsWrapper>
        </Modal>  
    )
}


const mapStateToProps = ({demands}) => ({
    error: demands.deleteDemand.error,
    loading: demands.deleteDemand.loading
})

const mapDispatchToProps = (dispatch)=>( {
     paymentConfirm: (demand) => dispatch(paymentConfirmationAPI(demand))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
    )(PaymentClientModal);