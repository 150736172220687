import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { connect } from "react-redux";
import styled from "styled-components";
import { getBestArtisanAPI } from "../../../Store/Actions/userActions";

const RightSection = (props)=>{


    useEffect(()=> {
        props.getArtisansFunction()
    }, []);
    
    const artisanList = props.users.map
    // const artisanListSortedNumJobs = [].concat(props.artisans).sort((a,b)=> a.totla_num_jobs > b.total_num_jobs ? 1 : -1).map((item, i)=> 
    //         console.log(item)
    //     )
    

    return(
        <Container>
            <FollowCard>
                <Title>
                    <h3>Les artisans les plus efficaces</h3>
                </Title>
                <FeedList>
                    {props.artisans.length > 0 ?
                    props.artisans.slice(0,3).map((eachArtisan, key)=>(
                        eachArtisan.artisan_rating >= 2 ?
                        <ArtisansProfileInfor key={key}>
                                <a>
                                    {eachArtisan.profile_image ? 
                                    <img src={eachArtisan.profile_image} />
                                    :
                                    
                                    <img src='/images/user.svg'/>
                                    }
                                    <div>
                                        {eachArtisan.firstName ? 
                                        <span>{eachArtisan.firstName}</span> :
                                        <span></span>
                                        }
                                        
                                        <ReactStars
                                            count={5}
                                            value={eachArtisan.artisan_rating}
                                            size={10}
                                            isHalf={true}
                                            edit={false}
                                            emptyIcon={<i className="far fa-star"></i>}
                                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                                            fullIcon={<i className="fa fa-star"></i>}
                                            activeColor="#c0a375"
                                        />
                                    </div>
                                </a>
                        </ArtisansProfileInfor>
                        :null
                    ))
                    :
                    <div>Il n'y a pas assez d'artisans pour ce problème. Mais, nous grandissons en tant que communauté.</div>
                    }
                    
                </FeedList>
            </FollowCard>
            <FollowCard>
                <Title>
                    <h3>Artisans avec la plupart des projets.</h3>
                </Title>
                <FeedList>
                    {props.artisans.length > 0 ?
                    props.artisans.slice(0,3).map((eachArtisan, key)=>(
                        eachArtisan.total_num_jobs >= 1 ?
                        <ArtisansProfileInfor key={key}>
                                <a>
                                    {eachArtisan.profile_image ? 
                                    <img src={eachArtisan.profile_image} />
                                    :
                                    
                                    <img src='/images/user.svg'/>
                                    }
                                    <div>
                                        {eachArtisan.firstName ? 
                                        <span>{eachArtisan.firstName}</span> :
                                        <span></span>
                                        }
                                        
                                        <ReactStars
                                            count={5}
                                            value={eachArtisan.artisan_rating}
                                            size={10}
                                            isHalf={true}
                                            edit={false}
                                            emptyIcon={<i className="far fa-star"></i>}
                                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                                            fullIcon={<i className="fa fa-star"></i>}
                                            activeColor="#c0a375"
                                        />
                                    </div>
                                </a>
                        </ArtisansProfileInfor>
                        :null
                    ))
                    :
                    <div>Il n'y a pas assez d'artisans pour ce problème. Mais, nous grandissons en tant que communauté.</div>
                    }
                    
                </FeedList>
                {/* <Recommendation>
                    View best products
                    
                </Recommendation> */}
            </FollowCard>
            {/* <BannerCard>
                View Best Products
            </BannerCard> */}
        </Container>
    )
}


const Container = styled.div`
    grid-area : rightsection;
    display:none;
    @media(max-width:768px){
        display: none;
    }

    
`;

const FollowCard = styled.div`
    text-align: center;
    overflow: hidden;
    margin-bottom: 8px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    border: none;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 15%), 0 0 0 rgb(0 0 0 / 20%);
    padding: 12px;      
`;

const Title = styled.div`
    // display: inline-flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    width: 100%;
    color: rgba(0, 0, 0, 0.6);
    


`;

const ArtisansProfileInfor = styled.div`
    padding-right: 40px;
    flex-wrap:nowrap;
    padding: 12px 16px 0;
    margin-bottom: 8px;
    align-items:center;
    display:flex;
    
    a{
        margin-right:12px;
        flex-grow:1;
        overflow:hidden;
        display:flex;
        text-decoration:none;

        img{
            width:48px;
            height:48px;
            border-radius:50%;

        }
        &>div{
            display:flex;
            flex-direction:column;
            flex-grow:1;
            flex-basis:0;
            margin-left: 8px;
            overflow:hidden;
            span{
                text-align:left;
                &:first-child{
                    font-size: 14px;
                    font-weight:700;
                    color: rgba(0,0,0,1);
                }
                &:nth-child(n+1){
                    font-size:12px;
                    color:rgba(0,0,0,0.6);

                }
            }
        }
    }

    button{
        position: absolute;
        right:12px;
        top:10px;
        background:transparent;
        border:none;
        outline:none;
        cursor:pointer;
        img{
            width:18px;
        }
    }
`;

const FeedList = styled.div`
    margin-top: 16px;
    li{
        display:flex;
        align-items: center;
        margin: 12px 0;
        position: relative;
        font-size : 14px;
        cursor: pointer;
        & >div{
            display:flex;
            flex-direction: column;
        }

        button{
            background-color:transparent;
            color: rgba(0, 0, 0, 0.6);
            box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.6);
            padding: 16px;
            align-items: center;
            border-radius: 15px;
            box-sizing: border-box;
            font-weight: 600;
            display: inline-flex;
            justify-content: center;
            max-height: 32px;
            max-width: 480px;
            text-align: center;
            outline: none;

        }
    }
`;




const mapStateToProps = (state)=> ({ firebase, firestore, users, artisans})=>{
    return{
        firebase,
        userId: firebase.auth.uid,
        artisans: artisans.artisans,
        loading: state.demands.loading,   
        users: users.users,
    }
};

const mapDispatchToProps = (dispatch)=> ({
    getArtisansFunction : ()=> dispatch(getBestArtisanAPI()),
})


export default connect(mapStateToProps, mapDispatchToProps)(RightSection);