export const AUTH_START = 'AUTH_START';
export const AUTH_END = 'AUTH_END';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const CLEAN_UP = 'CLEAN_UP';

export const VERIFY_START = 'VERIFY_START';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAIL = 'VERIFY_FAIL';

export const RECOVERY_START = 'RECOVERY_START';
export const RECOVERY_SUCCESS = 'RECOVERY_SUCCESS';
export const RECOVERY_FAIL = 'RECOVERY_FAIL';

export const PROFILE_EDIT_START = 'PROFILE_EDIT_START';
export const PROFILE_EDIT_SUCCESS = 'PROFILE_EDIT_SUCCESS';
export const PROFILE_EDIT_FAIL = 'PROFILE_EDIT_FAIL';

export const DELETE_START = 'DELETE_START';
export const DELETE_FAIL = 'DELETE_FAIL';

export const ADD_DEMAND_START = 'ADD_DEMAND_START';
export const ADD_DEMAND_SUCCESS = 'ADD_DEMAND_SUCCESS';
export const ADD_DEMAND_FAIL = 'ADD_DEMAND_FAIL';

export const DELETE_DEMAND_START = 'DELETE_DEMAND_START';
export const DELETE_DEMAND_SUCCESS = 'DELETE_DEMAND_SUCCESS';
export const DELETE_DEMAND_FAIL = 'DELETE_DEMAND_FAIL';

export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const GET_DEMANDS = 'GET_DEMANDS';


export const EDIT_ARTISAN = 'EDIT_ARTISAN';

export const ADD_ESTIMATION = 'ADD_ESTIMATION';

export const ACCEPT_ESTIMATION_START = 'ACCEPT_ESTIMATION_START';
export const ACCEPT_ESTIMATION = 'ACCEPT_ESTIMATION';
export const ACCEPT_ESTIMATION_FAIL = 'ACCEPT_ESTIMATION_FAIL';

export const GET_USERS = 'GET_USERS';
export const GET_ARTISANS = 'GET_ARTISANS';
export const SET_USER = "SET_USER";