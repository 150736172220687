import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';


import { FormWrapper, StyledForm } from '../../../Hoc/elements';
import Message from '../../../Components/UI/Messages/Message';
import Heading from '../../../Components/UI/Headings/Heading';
import Button from '../../../Components/UI/Forms/Button/Button';
import Input from '../../../Components/UI/Forms/Inputs/Input';
import Modal from '../../../Components/UI/Modal/Modal';

import * as actions from '../../../Store/Actions';


const MessageWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  width: 100%;
  padding: 0 3rem;
  
`;

const DeleteWrapper = styled.div`
  cursor: pointer;
  color: var(--color-errorRed);
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 7.5rem;
  transition: all 0.2s;
  &:hover {
    transform: translateY(-3px);
  }
  &:active {
    transform: translateY(2px);
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  justify-content: space-around;
`;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const ProfileSchema = Yup.object().shape({
    firstName: Yup.string()
    .required('Votre prénom est requis.')
    .min(3, 'Trop court.')
    .max(25, 'Trop long.'),
  lastName: Yup.string()
    .required('Votre nom de famille est requis.')
    .min(3, 'Trop court.')
    .max(25, 'Trop long.'),
  email: Yup.string()
    .email('Email invalide.')
    .required("L'e-mail est requis."),
  phoneNumber: Yup.string().matches(phoneRegExp, "Le numéro de téléphone n'est pas valide"),
  lastName: Yup.string(),
  password: Yup.string().min(8, 'Le mot de passe est trop court.'),
  confirmPassword: Yup.string().when('password', {
    is: password => password !='' ,
    then: Yup.string()
      .required('Vous devez confirmer votre mot de passe.')
      .oneOf([Yup.ref('password'), null], `Le mot de passe ne correspond pas`),
  }),
});

const Profile = ({ firebase, editProfile , loading, error, loadingDelete, errorDelete, deleteUser, cleanUp})=> {

    useEffect(() => {
        return () => {
            cleanUp();
        }
    }, [cleanUp]);

    // deleting modal
    const [modalOpened, setModalOpened] = useState(false);

    if(!firebase.profile.isLoaded) return null;

    return (
      <>
        <Formik
      initialValues={{
        firstName: firebase.profile.firstName,
        lastName: firebase.profile.lastName,
        email: firebase.auth.email,
        profileImage: firebase.profile.profile_image,
        // password: '',
        // confirmPassword: '',
        userType: firebase.profile.user_type,
        phoneNumber: `${firebase.profile.phone_number}`,
        address:firebase.profile.address,
        userId: firebase.profile.user_id,
      }}
      validationSchema={ProfileSchema}
      onSubmit={async (values, { setSubmitting }) => {
        // edit the profile here
        await editProfile(values);

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid }) => (
        <FormWrapper>
          <Heading noMargin size="h1" color="white">
          Modifier votre profil
          </Heading>
          <Heading bold size="h4" color="white">
          Voici les informations de votre profil
          </Heading>
          <StyledForm>
            <Field
              type="text"
              name="firstName"
              placeholder="Votre prénom..."
              component={Input}
            />
            <Field
              type="text"
              name="lastName"
              placeholder="votre nom..."
              component={Input}
            />
            <Field
              type="email"
              name="email"
              placeholder="votre email..."
              component={Input}
            />
             <Field
              type="tel"
              name="phoneNumber"
              pattern="[0-9]*"
              placeholder="Numéro de téléphone..."
              component={Input}
            />
             <Field
              type="text"
              name="address"
              placeholder="Votre adresse..."
              component={Input}
            />
            <Field
              type="password"
              name="password"
              placeholder="Votre mot de passe..."
              component={Input}
            />
            <Field
              type="password"
              name="confirmPassword"
              placeholder="Retaper votre mot de passe..."
              component={Input}
            />
            <Button disabled={!isValid || isSubmitting} 
            loading= {loading? 'Modifier ...': null} type="submit"
            >
              Modifier
            </Button>
            <MessageWrapper>
                <Message error show={error}>{error}</Message></MessageWrapper>
            <MessageWrapper>
                <Message success show={error === false}>
                Nous avons mis à jour votre profil !
                </Message>
              </MessageWrapper>
          </StyledForm>
              <DeleteWrapper onClick={() => setModalOpened(true)}>
              Supprimer mon compte
              </DeleteWrapper>
        </FormWrapper>
      )}
    </Formik>
      <Modal opened ={modalOpened} close={()=> setModalOpened(false)}>
        <div>
        <Heading  noMargin size="h1" color="black">
          Supprimer mon compte
        </Heading>
        </div>
        
        <Heading bold size="h4" color="black">
        Êtes-vous sûr de vouloir supprimer votre compte ?
        </Heading>
        <ButtonsWrapper>
            <Button
              contain
              onClick={() => deleteUser()}
              color="red"
              disabled={loadingDelete}
              loading={loadingDelete ? 'Supprimer...' : null}
            >
              Supprimer
            </Button>
            <Button contain color="main" onClick={() => setModalOpened(false)}>
              Annuler
            </Button>
        </ButtonsWrapper>
          <MessageWrapper>
            <Message error show={errorDelete}>
              {errorDelete}
            </Message>
          </MessageWrapper>
      </Modal>
    
  </>
    );

}

const mapStateToProps = ({firebase, auth}) => ({
    firebase,
    loading: auth.profileEdit.loading,
    error: auth.profileEdit.error,
    loadingDelete: auth.deleteUser.loading,
    errorDelete: auth.deleteUser.error,
})

const mapDispatchToProps = {
    editProfile:  actions.editProfile,
    cleanUp: actions.cleanMessage,
    deleteUser: actions.deleteUser,
}


export default connect(mapStateToProps, mapDispatchToProps)(Profile);