import { getFirestore } from "redux-firestore";
import firebase from "firebase";
import * as actions from './actionTypes';
import { setLoading } from "./demandActions";

export const SET_LOADING_STATUS = (status)=>({
    type: actions.SET_LOADING_STATUS,
    status: status,
});

export const getUsers = (payload)=>({
    type: actions.GET_USERS,
    payload: payload,
});

export function getUsersAPI(){
    const db = getFirestore();

    return(dispatch)=>{
        dispatch(setLoading(true));
        let userList;
        const userRef = db.collection('users');

        userRef.onSnapshot((snapshot)=>{
            userList = snapshot.docs.map((doc)=> doc.data());
            
        dispatch(getUsers(userList));
        });
        dispatch(setLoading(false));
    }
};

//--- getting artisans 
export const getArtisans = (payload) =>({
    type: actions.GET_ARTISANS,
    payload: payload,
})

// get best artisans
export function getBestArtisanAPI(){
    const db  = getFirestore();

    return(dispatch)=>{
        dispatch(setLoading(true));
        let artisanList;
        const artisanRef = db.collectionGroup('users').where("user_type", "==", "Artisan").orderBy('artisan_rating');

        artisanRef.onSnapshot((snapshot)=>{
            artisanList = snapshot.docs.map((doc)=> doc.data());
            dispatch(getArtisans(artisanList));
            
        });
        dispatch(setLoading(false));
    }
}