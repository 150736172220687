import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from "styled-components";
import { firestoreConnect } from 'react-redux-firebase';

import Heading from '../../Components/UI/Headings/Heading';
import Loader from '../../Components/UI/Loader/Loader';
import { Container } from '../../Hoc/elements';
import Button from '../../Components/UI/Forms/Button/Button';
import LeftSection from './LeftSection/LeftSection';
import Main from './AddDemand/Main';
import RightSection from '../Jobs/RightJobSection/RightSection';


const NewDemand = ({ demandsFromFirestore, requested, userId }) => {
    
  
    return (
      <Wrapper>
        <Container>
            <HomeLayout>

              <LeftSection/>
              <MainSection> 
                {/* <InnerWrapper> */}
                  {/* <Heading noMargin size="h1" color="black">
                  Vos Petits Boulots
                  </Heading> */}
                  {/* <Heading bold size="h4" color="black">
                    Your current projects
                  </Heading> */}
                  {/* <AddDemand />
                  {content} */}
                  
                {/* </InnerWrapper> */}
                <Main/>
              </MainSection>
              <RightSection/>
            </HomeLayout>
        </Container>
      </Wrapper>
    );
  };



  const HomeLayout = styled.div`
    display: grid;
    grid-template-areas: "leftsection main rightsection";
    grid-template-column: minmax(0, 5fr) minmax(0, 12fr) minmax(300px, 7fr);
    column-gap: 25px;
    row-gap: 25px;
    grid-template-row:auto;
    margin: 20px 0;
    max-width:100%;

    @media(max-width:768px){
      display: flex;
      flex-direction:column;
      padding: 0 5px;
      max-width:100%;
    }
  `;

  const MainSection = styled.div`
  `;


  const Wrapper = styled.div`
  width: 100%;
  align-self: flex-start;
  height: 100%;
  min-height: calc(100vh - 6rem);
  background-color: #fff;
  color: #111111;
  @media(min-width:768px){
    margin: 36px;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 4rem;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 60rem;
  flex-direction: column;
  margin-top: 2rem;
`;


  const mapStateToProps = ({ firebase, firestore }) => ({
     userId: firebase.auth.uid,
     demandsFromFirestore: firestore.data.user_demands,
     requesting: firestore.status.requesting,
     requested: firestore.status.requested,
  });
  
  const mapDispatchToProps = {};
  
  export default compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    firestoreConnect(props => [`user_demands/${props.userId}`])
  )(NewDemand);