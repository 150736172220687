import { combineReducers } from "redux";
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from "redux-firestore";

import authReducer from "./authReducer";
import demandReducers from "./demandReducers";
import articleReducer from "./articleReducer";
import userReducers from "./userReducers";
import artisanReducers from "./artisanReducers";

// we combine all of our reducers here
export default combineReducers({
    auth: authReducer,
    demands: articleReducer,
    users: userReducers,
    artisans: artisanReducers,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
})