import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import './App.css';

import Layout from './Hoc/Layouts/Layout';
import Jobs from './Containers/Jobs/Jobs';
import Landing from './Containers/Landing/Landing';
import NewDemand from './Containers/Demands/NewDemand';
import Profile from './Containers/Auth/Profile/Profile';
import Logout from './Containers/Auth/Logout/Logout';
import Login from './Containers/Auth/Login/Login';
import Signup from './Containers/Auth/SignUp/Signup';
import VerifyEmail from './Containers/VerifyEmail/VerifyEmail';
import RecoverPassword from './Containers/Auth/RecoverPassword/RecoverPassword';
import {ToastContainer, ToastifyContainer} from 'react-toastify';
import RegisterArtisan from './Containers/Auth/RegisterArtisan/RegisterArtisan';
import ProfileArtisan from './Containers/Auth/Profile/ProfileArtisan';
import NewJobs from './Containers/Jobs/NewJobs';
import Projects from './Containers/ClientProjects/Projects';
import Boutique from './Containers/Boutique/Boutique';
import CheckArtisanBoutique from './Containers/Boutique/CheckArtisanBoutique';
import ContractDetailsPDF from './Containers/ClientProjects/ContractPDF';
import DevisPDFComponent from './Containers/ClientProjects/DevisPDFComponent';
import CheckClientProfile from './Containers/ClientProjects/CheckClientProfile';


function App({loggedIn , emailVerified, userType}) {

  let routes;
  if(loggedIn && !emailVerified){
    routes = (
      <Switch>
        <Route exact path = "/landing" component= {Landing}></Route>
        <Route exact path = '/verify-email' component= {VerifyEmail}></Route>
        <Route exact path = '/logout' component= {Logout}></Route>
        <Route exact path="/login" component={Login} />
        <Redirect to="/verify-email" />
      </Switch>
    )
  }
  else if(loggedIn && emailVerified && userType === 'Client'){  
    routes =(
      <Switch>
          <Route exact path = "/landing" component= {Landing}></Route>
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/artisanboutique" component={CheckArtisanBoutique} />
          <Route exact path = "/clientprofile" component={CheckClientProfile}/>
          <Route exact path="/contract" component={ContractDetailsPDF} />
          <Route exact path = "/devis" component={DevisPDFComponent}/>
          <Route exact path = "/" component= {NewDemand}></Route>
          <Route exact path = "/logout" component= {Logout}></Route>
          <Redirect   to='/'/> 
        </Switch>
        );
  }else if(loggedIn && emailVerified && userType === 'Artisan'){  
    routes =(
      <Switch>
          <Route exact path = "/landing" component= {Landing}></Route>
          <Route exact path="/profileartisan" component={ProfileArtisan} />
          <Route exact path = "/jobs" component= {Jobs}></Route>
          <Route exact path = "/" component= {NewJobs}></Route>
          <Route exact path = "/boutique" component= {Boutique}></Route>
          <Route exact path="/artisanboutique" component={CheckArtisanBoutique} />
          <Route exact path = "/clientprofile" component={CheckClientProfile}/>
          <Route exact path="/contract" component={ContractDetailsPDF} />
          <Route exact path = "/devis" component={DevisPDFComponent}/>
          <Route exact path = "/logout" component= {Logout}></Route>
          <Redirect to='/'/> 
        </Switch>
        );

    }else{
    routes = (
      <Switch>
        <Route exact path = "/" component= {Landing}></Route>
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/registerartisan" component={RegisterArtisan} />
        <Route exact path="/recover" component={RecoverPassword} />
        <Redirect to="/" />
      </Switch>
    );
  }

  return (
    <Layout>
      <ToastContainer/>
      {routes}      
    </Layout>
  );
}

const mapStateToProps = ({firebase}) => ({
  userType: firebase.profile.user_type,
  loggedIn: firebase.auth.uid? true : null,
  emailVerified: firebase.auth.emailVerified
})


export default connect(mapStateToProps)(App);
