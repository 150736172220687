import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { getDemandsForArtisanAPI , deleteDemand } from "../../Store/Actions/demandActions";
import { connect } from "react-redux";
import styled from "styled-components";
import JobModal from "../Boutique/JobModal";
import { getUsersAPI } from "../../Store/Actions/userActions";

import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';

const CheckClientProfile = (props) => {
    // receiving data from the main page
    const receivedData = useLocation();
    const clientId = receivedData.state.clientId;


    const [demandToSee, setDemandToSee] = useState('');
    const [showModal, setShowModal] = useState('close');

    useEffect(()=>{
        props.getDemands()
    }, []);

    useEffect(()=>{
        props.getUserListFunction()
    }, []);

    const handleJobModal = (e)=>{
        e.preventDefault();

        switch(showModal){
            case "open":
                setShowModal('close');
                break;
            case "close":
                setShowModal('open');
                break;
            default:
                setShowModal('close');
                break
        }
    };

    return(
        <Main>
            {props.userListItem.map((eachUser, key)=>(
                
                eachUser.user_type ==='Client' && eachUser.user_id === clientId ?
                <ArtisanProfileInfo key={key}>
                    
                    <ProfileContainer>
                        <Profile>
                        {eachUser.profile_image ? 
                            <ProfileImage>
                                <img src=  {eachUser.profile_image} />
                            </ProfileImage>
                            : 
                            <img src='/images/user.svg'/>
                            }
                            <UserSettings>
                            <h1 class="profile-user-name">{eachUser.firstName} {eachUser.lastName}</h1>

                            </UserSettings>
                            <UserScores>
                            <ul>
                            <li>
                                <a href={"tel:" + eachUser.phone_number}>
                                <span class="profile-stat-count">
                                    <PermPhoneMsgIcon></PermPhoneMsgIcon>
                                </span>
                                <span class="profile-stat-count">{eachUser.phone_number}</span>
                                </a>
                            </li>
                                <li>
                                    <span class="profile-stat-count">
                                        <LocalPostOfficeIcon></LocalPostOfficeIcon>
                                    </span>
                                    <span class="profile-stat-count">{eachUser.email}</span></li>

                            <li></li>
                            </ul>
                            </UserScores>

                        </Profile>
                    </ProfileContainer>
                </ArtisanProfileInfo>
                    :
                    null
                ))}
                
                <Gallery>
                        {props.userDemands.length > 0 && props.userDemands.map(
                            (eachDemand, key)=>(
                                eachDemand.contract && eachDemand.demand_status ==='reviewed' && eachDemand.user_id === clientId ?
                                <GalleryItem key={key}>
                                     {/* {eachDemand.demand_cover_photo ? 
                                        <GalleryImage                            
                                            src={eachDemand.demand_cover_photo}>
                                        </GalleryImage>
                                        :
                                        <GalleryImage                            
                                            src={eachDemand.demand_image[0]}>
                                        </GalleryImage>
                                    } */}
                                    <GalleryImage                            
                                            src={eachDemand.demand_image[0]}>
                                        </GalleryImage>
                                    {eachDemand.review&& eachDemand.review.rating?
                                <div onClick={handleJobModal}>
                                <GalleryItemInfo  onClick={()=>setDemandToSee(eachDemand)}>
                                <ul >
                                    <GalleryItemStat ><span>score:</span><i className="fas fa-star" aria-hidden="true"></i>{eachDemand.review.rating}/5</GalleryItemStat>
                                
                                    </ul>
                                </GalleryItemInfo>
                                </div>
                                    :
                                        null
                                    }       
                                </GalleryItem>
                                : 
                                null
                            )
                        )}
                </Gallery> 
                    
                    <JobModal showModal={showModal} handleClick = {handleJobModal} demand={demandToSee}/>
            </Main>
    )
}

const Main = styled.div`
    width:100%;
    align-self: flex-start;
    margin-bottom:100px;
`;

const ArtisanProfileInfo = styled.div`
    background-color:;
`;

const ProfileContainer = styled.div`
    max-width: 93.5rem;
    margin: 0 auto;
    padding: 0 2rem;
`;

const Profile = styled.div`
    padding: 5rem 0;

    &:after{
        content: "";
        display: block;
        clear: both;

    }
`;

const ProfileImage = styled.div`
    float: left;
    width: calc(33.333% - 1rem);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3rem;
    
    img{
        border-radius: 50%;
        width:40%;
    }

    @media(max-width: 768px){
        float: none;
         width: auto;
         img{
             width:20%;

         }
    }
`;

const UserSettings = styled.div`
    float: left;
    width: calc(66.666% - 2rem);
    margin-top: 1.1rem;
    @media(max-width:768px){
        float: none;
        width: auto; 
        flex-basis: calc(100% - 10.7rem);
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
    }
`;

const UserScores = styled.div`
    float: left;
    width: calc(66.666% - 2rem);                 
    margin-top: 2.3rem;
    ul{
        display: flex;
        flex-direction: row;
    }
    li{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.6rem;
        line-height: 1.5;
        margin-right: 4rem;
        cursor: pointer;
        a{
            color:black;
            display:flex;
            flex-direction:column;
        }
    }                      
    @media(max-width:768px){
        float: none;
        width: auto;
        lex-basis: 100%;
        order: 1;
        margin-top: 1.5rem;
        ul{
        display: flex;
        text-align: center;
        padding: 1.2rem 0;
        border-top: 0.1rem solid #dadada;
        border-bottom: 0.1rem solid #dadada;
        }
        li{
            font-size: 1.4rem;
        flex: 1;
        margin: 0;
        }
    }      
`;


const Gallery = styled.div`

    width:50%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    margin-left:auto;
    margin-right:auto;
    grid-row-gap:5px;
    grid-column-gap: 5px;
    border-top: 0.1rem solid #dadada;
    @media(max-width:768px){
        width:100%;
        grid-row-gap:5px;
        grid-column-gap: 5px;
        margin-left:5px;
        margin-right:5px;
    }

`;

const GalleryItemInfo = styled.div`
    display: none;
    li{
        display: inline-block;
        font-size: 1.7rem;
        font-weight: 600;
    }
`;

const GalleryItem =styled.div`
    
    margin-top: 20px;
    position: relative;
    width:100%;
    cursor: pointer;
    // display: flex;
    // flex-wrap: wrap;
    // flex: 0 0 25%  ;
    // margin:  auto;
    // margin-top:50px;

    &:hover {
        ${GalleryItemInfo} {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius:5px;
            color:#fff;

          }
    }

    &:focus {
        ${GalleryItemInfo} {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            color:#fff;
        }
        
    }
`;


const GalleryItemStat = styled.li`
    display: inline-block;
    font-size: 1.7rem;
    font-weight: 600;  

    span{
        margin-right: 2.2rem;
    }
`;


const GalleryImage = styled.img`
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 5px;
    border-width:1px;
    @media(max-width:850px){
        height:150px;
    }
`;

const mapStateToProps = (state)=> ({firebase, firestore, demands, users})=>{
    return{
        firebase,
        userId: firebase.auth.uid,
        loading: state.demands.loading,
        userDemands: demands.demands,     
        error: demands.deleteDemand.error,
        loadingDemandDelete: demands.deleteDemand.loading,
        userListItem: users.users,
    }
};

const mapDispatchToProps = (dispatch) =>({
    // get all the demands that are reviewed 
    getDemands: ()=> dispatch(getDemandsForArtisanAPI),
    getUserListFunction : ()=> dispatch(getUsersAPI()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckClientProfile);