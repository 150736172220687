import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Modal from '../../../Components/UI/Modal/Modal';
import Button from '../../../Components/UI/Forms/Button/Button';
import Heading from '../../../Components/UI/Headings/Heading';
import Message from '../../../Components/UI/Messages/Message';

import * as actions from '../../../Store/Actions';

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  justify-content: space-around;
`;

const DemandWrapper = styled.div`
  margin: 1rem 0rem;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;
  color: #2e2e2e;
`;

const MessageWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  width: 100%;
  padding: 0 3rem;
`;


const DeleteDemand = ({show, close, demand, deleteDemand, error, loading}) => {

    return (
        <Modal opened = {show} close={close}>
            <Heading noMargin size="h1" color="black">
            Suppression de la demande
            </Heading>
            <Heading bold size="h4" color="black">
            Êtes-vous sûr de vouloir supprimer ce petit boulot ?
            </Heading>
            <DemandWrapper>{demand.demand}</DemandWrapper> 
            <ButtonsWrapper>
                <div onClick={close}>
                <Button
                contain
                color="red"
                onClick={ async() => 
                    await deleteDemand(demand.demand_id)}
                
                disabled={loading}
                loading={loading ? 'Suppression...' : null}
                > 
                Supprimer
                </Button>
                </div>
                
                <Button color="main" contain onClick={close}>
                Annuler
                </Button>
                <MessageWrapper>
                    <Message error show={error}>
                    {error}
                    </Message>
                </MessageWrapper>
            </ButtonsWrapper>
        </Modal>  
    )
}


const mapStateToProps = ({demands}) => ({
    error: demands.deleteDemand.error,
    loading: demands.deleteDemand.loading
})

const mapDispatchToProps = {
    deleteDemand: actions.deleteDemand,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
    )(DeleteDemand);