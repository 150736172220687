import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from "styled-components";
import { firestoreConnect } from 'react-redux-firebase';

import Heading from '../../Components/UI/Headings/Heading';
import { Container } from '../../Hoc/elements';

import MainProjects from './MainProjects';
import ProjectLeftSide from './ProjectLeftSide';


function Projects() {
    return (
        <Wrapper>
            <Container >
                <HomeLayout>
                    <ProjectLeftSide/>
                        <MainProjects/>
                    {/* <RightSection/> */}
                </HomeLayout>
            </Container>
        </Wrapper>
        
    )
}



const Wrapper = styled.div`
  width: 100%;
  align-self: flex-start;
  height: 100%;
  min-height: calc(100vh - 6rem);
  background-color: #fff;
  color: #111111;
`;
const HomeLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap:20px;
  justify-content: flex-start;
  margin: 20px;
  @media(max-width:768px){
    flex-direction: column;
    gap:10px;
  }
`;

// const HomeLayout = styled.div`
// display:grid;
// grid-template-areas: "leftsection main ";
// grid-template-column: 1fr 2fr;
// column-gap: 5px;
// row-gap: 25px;
// grid-template-row:auto;
// margin: 25px 0;
// max-width:100%;
// 
// @media(max-width:768px){
//   display: flex;
//   flex-direction:column;
//   padding: 0 5px;
//   max-width:100%;
// }
// `;


export default Projects
