import React from 'react';
import styled from 'styled-components';
import NavItem from './NavItem/NavItem';
import { connect } from 'react-redux';

import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ExploreIcon from '@mui/icons-material/Explore';
import HomeIcon from '@mui/icons-material/Home';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

const Nav= styled.nav`
    display: flex;
    position:sticky;
`;

const Ul= styled.ul`
    display: flex;
    flex-direction: ${props => props.mobile? 'column' : 'row'};
    height: 100%;
    align-text:center;

    h6{
      // display: ${props=> props.mobile ? 'block': 'none'};
      // margin-left:${props=> props.mobile ? '7px': '0'};
      margin-left:7px;
      margin-top:7px;
    }
    
`;




const NavItems = ({ mobile, clicked, loggedIn, userType, emailVerified }) => {
    let links;
    if (loggedIn.uid && emailVerified && userType ==='Client') {
      links = (
        <Ul mobile={mobile}>
          {/* <NavItem mobile={mobile} clicked={clicked} link="/home">
          Home
          </NavItem> */}
          <NavItem mobile={mobile} clicked={clicked} link="/newDemand">
          <span>Mes demandes</span>
          <ConnectWithoutContactIcon fontSize='large'/>
          <h6>Mes demandes</h6>
          </NavItem>

          <NavItem mobile={mobile} clicked={clicked} link="/projects">
          <span> Projets précédents</span>
          <PermContactCalendarIcon fontSize='large'/>
          <h6>Profil</h6>
          </NavItem>
        
                    
          <NavItem mobile={mobile} clicked={clicked} link="/logout">
          <span>Logout</span>
          <LogoutIcon fontSize='large'/>
          <h6>Logout</h6>
          </NavItem>
        </Ul>
      );
    }else if (loggedIn.uid && emailVerified && userType ==='Artisan') {
      links = (
        <Ul mobile={mobile}>
          {/* <NavItem mobile={mobile} clicked={clicked} link="/home">
          Home
          </NavItem> */}

          <NavItem mobile={mobile} clicked={clicked} link="/jobs">
          <span>Mes boulots</span>
          <HomeIcon fontSize='large'/>
          <h6>Mes Boulots</h6>
          </NavItem>
          
          <NavItem mobile={mobile} clicked={clicked} link="/newjobs">
          <span>Rechercher un boulot</span>
          <TravelExploreIcon fontSize='large'/>
          <h6>Explorer</h6>

          </NavItem>
          
          <NavItem mobile={mobile} clicked={clicked} link="/boutique">
          <span>Ma Boutique</span>
          <PermContactCalendarIcon fontSize='large'/>
          <h6>Ma Boutique</h6>
          </NavItem>
          
          
                    
          <NavItem mobile={mobile} clicked={clicked} link="/logout">
          <span>Logout</span>
          <LogoutIcon fontSize='large'/>
          <h6>Logout</h6>
          </NavItem>
        </Ul>
      );
    }else if(loggedIn.uid && !emailVerified  && userType ==='Artisan'){
      links = (
        <Ul mobile={mobile} onClick={()=> window.location.href='/newjobs'}>
        <NavItem mobile={mobile} clicked={clicked} link="/login" >
          Login
        </NavItem>
        
      </Ul>
      );
    }else if(loggedIn.uid && !emailVerified  && userType ==='Client'){
      links = (
        <Ul mobile={mobile} onClick={()=> window.location.href='/newDemand'}>
        <NavItem mobile={mobile} clicked={clicked} link="/login" >
          Login
        </NavItem>
        
      </Ul>
      );
    }
     else {
      links = (
        <Ul mobile={mobile}>
        <NavItem mobile={mobile} clicked={clicked} link="/login">
          Login
        </NavItem>
        <NavItem mobile={mobile} clicked={clicked} link="/signup">
          Sign Up
        </NavItem>
      </Ul>
      );
    }
    return <Nav mobile={mobile}>{links}</Nav>;
  };

  const mapStateToProps = ({firebase})=> ({
    userType:firebase.profile.user_type,
    emailVerified: firebase.auth.emailVerified
  });


  
  export default connect(mapStateToProps)(NavItems);