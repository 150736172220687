import React, { useState } from "react";
import { connect } from "react-redux";
import firebase from 'firebase';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { sendContract } from "../../../Store/Actions/actionOffer";
import { TextField } from "@mui/material";
import { IconButton } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: { 
    
      '& .MuiTextField-root':{
        
      background: '#d6d4d5',
      margin: theme.spacing(1),
      width:'30%',
      textAlign:'center'
      }, 
     '& input + fieldset': {
        borderColor: '#c0a375',
      },
      },
      button:{
        margin: theme.spacing(1),
     }
  }));
  

const ContractModal = (props)=>{

    const classes = useStyles();

    // attributes
    const [editorText, setEditorText]= useState("");
    const [artisanPhoneNumber, setArtisanPhoneNumber] = useState(props.firebase.profile.phone_number);
    const [totalMaterialCost, setTotalMaterialCost] = useState(0);
    const [wage, setWage] = useState(0);
    const [estimatedVisitDate, setEstimatedVisitDate] = useState('');

    //.. list of materials
    const [materialLists, setMaterialList]= useState([
        {material_name: '', material_quantity:'' , material_price : ''}
    ]);

    const handleInputMaterial = (index, event)=>{
        const values = [...materialLists];
        values[index][event.target.name] = event.target.value;
        setMaterialList(values);

      };
    
      const handleAddFields = ()=>{
        setMaterialList([...materialLists, { material_name: '', material_quantity: '',   material_price : ''}]);
        calcCosttotal(materialLists);
      };
    
      const handleRemoveFields = (index)=>{
        const values = [...materialLists];
        values.splice(index, 1);
        setMaterialList(values);
        calcCosttotal(values);
      }

      const calcCosttotal = (materialListsInput) => {
        const newTotal = materialListsInput.reduce((a,c)=> (a+ c.material_quantity * c.material_price), 0 )
        setTotalMaterialCost(newTotal);
    
    } 
      

    //......End of material needs

    // functions
    const handlePhoneNumber = (e)=>{
        const artisanPhoneNumber = e.target.value;

        setArtisanPhoneNumber(artisanPhoneNumber);
    }

    //
    const handleMaterialCost = (e) => {
        e.preventDefault();
        const costInput = e.target.value;

        setTotalMaterialCost(costInput);
    }

    const handleEstimatedWage = (e) => {
        const wageInput = e.target.value;

        setWage(wageInput);
    }

    const handleVisit = (e)=>{
        const dateInput = e.target.value;
        setEstimatedVisitDate(dateInput);
    }



    const sendContract = (e) =>{
        const contract = {
            demandId: props.demand.demand_id,
            artisanId: props.firebase.auth.uid,
            artisanFirstName: props.firebase.profile.firstName,
            artisanLastName: props.firebase.profile.lastName,
            artisanEmail: props.firebase.profile.email,
            artisanAddress: props.firebase.profile.address,
            artisanProfileImage: props.firebase.profile.profile_image,
            descriptionOfContract: editorText,
            startDate: estimatedVisitDate,
            contactNumber: artisanPhoneNumber,
            timestamp: firebase.firestore.Timestamp.now(),
            materialList: materialLists,
            materialCost: totalMaterialCost,
            wage: wage,
            totalCost:  +wage + +totalMaterialCost,

        }
        props.sendContract(contract);
        console.log(contract)
        reset(e);
    }
    
    const reset = (e)=>{
        props.handleClick(e);
    }
    return(
        <>
        {props.showModal==='open' &&(
            <Container>
                <Content>
                    <Header>
                    <h2>Les informations sur le contrat</h2>
                        <button onClick={(event) => reset(event) }>
                            <img src="/images/close.png" alt="" />
                        </button>
                    </Header>
                    <SharedContent>
                        <UserInfo>
                        {props.userId && props.firebase.profile.profile_image ? <img src={props.firebase.profile.profile_image}/>
                        :
                        <img src="/images/user.svg" alt="" />
                        }
                        <span>{props.firebase.profile.firstName}</span>
                    
                        </UserInfo>
                        <Editor>
                        <label id='contractDetails'>Détails du contrat</label>
                        
                        <textarea style={{fontSize:'16px'}} value={editorText} onChange={(e)=> setEditorText(e.target.value)} placeholder='Details of your contract' autoFocus={true}></textarea> 
                                
                                                

                        <label id='phoneNumber'>Votre numéro de téléphone</label>
                        <input value={artisanPhoneNumber}  type="number" onChange={handlePhoneNumber}/>

                        <MaterialsSection>
                        <h6 style={{cursor:'pointer'}} onClick={()=>handleAddFields()}>Ajoutez un nouveau matériau et cliquez sur + pour ajouter un matériau</h6>
                       
                        
                        <FormMaterial   className={classes.root} >
                            {materialLists.map((inputMaterial, index)=>(
                                <div  key={index}>
                                    <TextField
                                    name = 'material_name'
                                    size="small"
                                    margin="normal"
                                    fullWidth 
                                    style = {{width: 90}}
                                    label = 'Nom du matériau'
                                    value={inputMaterial.material_name}
                                    onChange={event => handleInputMaterial(index, event)}
                                    />
                                    <TextField
                                    name = 'material_quantity'
                                    label = 'Combien? '
                                    size="small"
                                    style = {{width: 90}}
                                    margin="normal"
                                    fullWidth 
                                    value={inputMaterial.material_quantity}
                                    type='number'
                                    onChange={event => handleInputMaterial(index, event)}
                                    />
                                    <TextField
                                    name = 'material_price'
                                    size="small"
                                    style = {{width: 90}}
                                    label = 'Prix ​​par unité'
                                    margin="normal"
                                    fullWidth
                                    defaultValue={inputMaterial.material_price}
                                    type='number'
                                    onChange={event => handleInputMaterial(index, event)}
                                    />
                                    <IconButton onClick={()=>handleRemoveFields(index)}>
                                    <Remove/>
                                    </IconButton>

                                    <IconButton onClick={()=>handleAddFields()}>
                                    <Add/>
                                    </IconButton>
                                </div>
                            ))}
                        </FormMaterial>
                        </MaterialsSection>

                        <label id='totalMaterialCost' > Quel est le coût final ?</label>
                        
                        <input readOnly value={totalMaterialCost}  type="number" onChange={handleMaterialCost} />
                        
                        
                        
                        
                        <label id='wage'>Quel est le salaire final ?</label>
                        
                        <input value={wage }  type="number" onChange={handleEstimatedWage} />
                        
                        
                        
                        
                        <label id='estimatedVisitDate'>Le projet commence à</label>
                        <input value={estimatedVisitDate} placeholder="Début de votre projet"  type="date" onChange={handleVisit}/>

                    </Editor>

                    <SharedCreation>
                        {/* <RejectButton 
                           
                             onClick={()=>console.log('cancel the contract')  }
                        >Cancel the contract
                        </RejectButton> */}
                        <PostButton 
                            disabled={  !artisanPhoneNumber || !totalMaterialCost || !wage || !estimatedVisitDate ? true : false}
                            onClick={(event)=> sendContract(event)  }
                        >Envoyer le devis
                        </PostButton>
                    </SharedCreation>
                    </SharedContent>
                </Content>
            </Container>
        )}
            
        </>

    )
}



const Container = styled.div`
    position:absolute;
    width:100%;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:9999;
    color:black;
    background-color:rgba(0,0,0,0.8);
    animation: fadeIn 0.3s;
`;

const Content = styled.div`
    width:100%;
    max-width:652px;
    background-color:white;
    max-height:90%;
    overflow:initial;
    border-radius:5px;
    position: relative;
    display:flex;
    flex-direction:column;
    top:32px;
    margin: 0 auto;
`;

const Header = styled.div`
    display:block;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(0,0,0, 0.15);
    font-size:16px;
    line-height: 1.5;
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items:center;
    button{
        height:40px;
        width:40px;
        min-width:auto;
        color: rgba(0,0,0,0.15);
        background-color:transparent;
        border: none;
        justify-content: center;
        cursor: pointer;
        &:hover{
            border-radius:50%;
            background-color: #aaa;
        }
        
    }
    img{
        width:40px;
        margin-bottom:3px;
    }
`;

const SharedContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow:1;
    overflow-y:auto;
    vertical-align: baseline;
    background: transparent;
    padding: 8px 12px;
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 12px;

    svg, img{
        width: 48px;
        height:48px;
        background-clip: content-box;
        border: 2px solid transparent;
        border-radius: 50%;
    }
    span{
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        margin-left: 5px;
    }
`;

const SharedCreation = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 12px 16px;
`;


const PostButton = styled.button`
    min-width:60px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    border:none;
    font-weight:bold;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#c0a375')};
    color: ${(props)=> (props.disabled ? "#aaaa" : '#111111')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'};
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;

const Editor = styled.div`
    padding: 12px 24px;

    textarea{
        width: 100%;
        min-height: 100px;
        resize: none;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }

    input{
        width: 100%;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }
`;


const MaterialsSection = styled.div`
    display: flex;
    flex-direction: column;
    h2{
        text-align: center;
    }
`;


const FormMaterial = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    

`;


const mapStateToProps = (state) => ({firebase, firestore})=>{
    return{
        firebase,
        userId: firebase.auth.uid,
    }
};

const mapDispatchToProps = (dispatch)=>({
    // send the contract
    sendContract: (contract) => dispatch(sendContract(contract)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractModal);