import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import styled from "styled-components";
import NavItem from "../NavItems/NavItem/NavItem";

 
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HomeIcon from '@mui/icons-material/Home';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import PostModal from "../../../UI/Modal/PostModal";

const LabelBottomNavigation = ({mobile, clicked, userType})=>{

  const [showModal, setShowModal] = useState('close');

  const handleClick = (e) => {
    e.preventDefault();

    switch(showModal){
        case "open":
            setShowModal('close');
            break;

        case "close":
            setShowModal('open');
            break;

            default:
                setShowModal('close');
                break;
    }
};

    return (
        <Container>
                           
                <Nav>
                    { userType==='Client' ? 

                    <NavListWrap>
                    <BottomNavItem   mobile={mobile} clicked={clicked}  link="/newDemand">
                    <ConnectWithoutContactIcon fontSize='large'/>
                    </BottomNavItem>
                       
                    <BottomNavItem mobile={mobile} clicked={clicked} link="/projects">
                    <span> Previous projects</span>
                    <PermContactCalendarIcon fontSize='large'/>
                    </BottomNavItem>

                    

                    <BottomNavItem mobile={mobile} clicked={clicked} link="/profile">
                    <span> Account</span>
                    <ManageAccountsIcon fontSize='large'/>
                    </BottomNavItem>

                    
                <PostModal showModal={showModal} handleClick={handleClick}/>

                    </NavListWrap>
                    :
                    userType==='Artisan' ? 
                    <NavListWrap>
                    <BottomNavItem   mobile={mobile} clicked={clicked}  link="/jobs">
                    <HomeIcon fontSize='large'/>
                    </BottomNavItem>
                       
                    <BottomNavItem  mobile={mobile} clicked={clicked} link="/newjobs">
                    <TravelExploreIcon fontSize='large'/>
                    {/* <h6>Profile</h6> */}
                    </BottomNavItem>
                    
                    <BottomNavItem mobile={mobile} clicked={clicked} link="/boutique">
                    <span> Account</span>
                    <PermContactCalendarIcon fontSize='large'/>
                    </BottomNavItem>
                    
                    </NavListWrap>
                    :null
                    }
                    
                </Nav>
        </Container>
    );

};

const Container = styled.div`
background-color: white;
border-bottom: 1px solid rgba(0, 0, 0, 0.08);
left: 0;
padding: 0 24px;
position: fixed;
width: 100vw;
z-index: 100;

@media(min-width:900px){
    display:none;
}
`;


const Nav = styled.nav`
  background: rgba(0, 0, 0, 0.5);
  // background: #111111;
  width: max-content;
  display: block;
  padding: 0rem 1rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1.5rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
`;

const BottomNavItem = styled(NavItem)`
  background: transparent;
  padding: 0.9rem;
  border-radius:50%;
  display: flex;
  color: rgba(255, 255, 255, 0.6);
  font-size: 2.1rem;
  border-bottom: none;

`;

const NavListWrap = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  list-style-type: none;
  cursor:pointer;
  justify-content: space-evenly;
  .active {
    span:after {
      content: "";
      transform: scaleX(1);
      border-bottom: 2px solid var(--white, #fff);
      bottom: 0;
      left: 0;
      position: absolute;
      transition: transform 0.2s ease-in-out;
      width: 100%;
      border-color: rgba(0, 0, 0, 0.9);
    }
  }
`;





const mapStateToProps = ({firebase}) =>{
  return {
    userType: firebase.profile.user_type,
  };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelBottomNavigation);