import { SET_LOADING_STATUS, GET_DEMANDS, DELETE_DEMAND_SUCCESS } from "../Actions/actionTypes";


export const initState = {
    demands:[],
    loading: false,
    deleteDemand: {
        error: null,
        loading: false,
      },
};

const articleReducer = (state = initState, action) => {
    switch(action.type){

        case GET_DEMANDS:
            return{
                ...state,
                demands: action.payload,
            }

        case SET_LOADING_STATUS:
            return{
                ...state,
                loading: action.status,
            }
        case DELETE_DEMAND_SUCCESS:
            return {
                ...state,
                deleteDemand: { ...state.deleteDemand, loading: false, error: false },
            };
        default: 
            return state;
    }
};

export default articleReducer;