import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Li = styled.li`
    display: flex;
    height: 100%;
    color: white;
`;

const StyledNavLink = styled(NavLink)`
    display:flex;
    // flex-direction:column;
    text-transformation: uppercase;
    align-items: center;
    border-bottom: ${props => props.mobile ? '1px solid transparent' : '1px solid transparent' };
    font-size: 1.2rem;
    padding: ${props => (props.mobile? '.5rem 1rem': '1rem')};
    margin: ${props => (props.mobile? '2rem 0': '0 1rem')};
    font-weight: 400;
    color: white;
    transition: all 0.2s;
    font-size:20px;

    h6{
        margin-top:3px
    }

    span{
        position: absolute;
        top: 95px;
        background: transparent;
        border-radius: 0 0 5px 5px;
        width: 100px;
        height: 40px;
        font-size: 16px;
        transition-duration: 167ms;
        text-align: center;
        display: none;
        margin-left:-30px;
    }
    img{
        color:white;
    }

    &:hover{
        text-decoration:none;
        color:#aaa;
        border-bottom: ${props => props.mobile ? '1px solid #fff' : '1px solid #fff' };
        span{
            color:#111111;
            display:flex;
            flex-direction: column;
            justify-contents:center;
            align-items: center;
            justify-content: center;
            background-color:transparent;
        }
    }

    &.active{
        border-bottom: ${props => props.mobile ? '1px solid #ca7' : '1px solid #ca7' };
        // border-bottom: ${props => props.bottom =='bottom' ? 'none': null  };
        color: #ca7;
        
    }
`;

function NavItem({link, children, mobile, clicked, bottom}) {
    return (
        <Li>
            <StyledNavLink bottom={bottom} onClick={clicked} exact activeClassName="active" mobile ={mobile} to={link}>{children}</StyledNavLink>
        </Li>
    )
}

export default NavItem;
