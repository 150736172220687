import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LogoWrapper = styled.div`
    padding: 0rem 2rem;
    height: 100%;
    display: flex;
    align-items:center;
    font-weight:700;
    color: white;
    font-size: 1.2rem;
    
`;

const StyledLogoLink = styled(Link)`
    color:white;
    font-size: 1.5rem;

    &:hover{
        text-decoration:none;
        color: #c0a375;
    }

    @media ${props => props.theme.mediaQueries.small}{
        font-size: 20px;
    }

    @media ${props => props.theme.mediaQueries.smallest}{
        font-size: 20px;
    }
    
`;


function Logo() {
    return (
        <LogoWrapper>

            <StyledLogoLink to='/landing'>Le Petit Boulot</StyledLogoLink>
            
        </LogoWrapper>
    )
}

export default Logo;
