import React, {useEffect} from 'react';
import { Formik, Field } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import Message from '../../../Components/UI/Messages/Message';
import * as actions from '../../../Store/Actions';

import { FormWrapper, StyledForm } from '../../../Hoc/elements';
import Heading from '../../../Components/UI/Headings/Heading';
import Input from '../../../Components/UI/Forms/Inputs/Input';
import Button from '../../../Components/UI/Forms/Button/Button';

const MessageWrapper = styled.div`
  position: absolute;
  bottom: -2rem;
`;

const RecoverSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email.')
    .required('The email is required.'),
});


const RecoverPassword = ({ error, loading, sendEmail, cleanUp }) => {
    useEffect(() => {
      return () => {
        cleanUp();
      };
    }, [cleanUp]);
  
    return (
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={RecoverSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await sendEmail(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid }) => (
          <FormWrapper>
            <Heading noMargin size="h1" color="white">
            Récupérer votre mot de passe
            </Heading>
            <Heading size="h4" bold color="white">
            Tapez votre e-mail pour récupérer votre mot de passe
            </Heading>
            <StyledForm>
              <Field
                type="email"
                name="email"
                placeholder="Type your email..."
                component={Input}
              />
              <Button
                disabled={!isValid || isSubmitting}
                loading={loading ? 'Sending recover email...' : null}
                type="submit"
              >
               Récupérer 
              </Button>
              <MessageWrapper>
                <Message error show={error}>
                  {error}
                </Message>
              </MessageWrapper>
              <MessageWrapper>
                <Message success show={error === false}>
                Récupération envoyée avec succès à votre e-mail !
                </Message>
              </MessageWrapper>
            </StyledForm>
          </FormWrapper>
        )}
      </Formik>
    );
  };
  
  const mapStateToProps = ({ auth }) => ({
    loading: auth.recoverPassword.loading,
    error: auth.recoverPassword.error,
  });
  
  const mapDispatchToProps = {
    sendEmail: actions.recoverPassword,
    cleanUp: actions.cleanMessage,
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(RecoverPassword);