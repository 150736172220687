import React, { useState } from "react";
import { connect } from "react-redux";
import firebase from 'firebase';
import styled from 'styled-components';
import { finalizeJobArtisan } from "../../../Store/Actions/actionOffer";

const JobFinalisingModal = (props)=>{
    //attributes

    const [editorText, setEditorText] = useState("");

    //functions

    const finalizeDemand = (e) =>{
        const payload = {
            demandId: props.demand.demand_id,
            artisanId: props.firebase.auth.uid,
            artisanFirstName: props.firebase.profile.firstName,
            artisanLastName: props.firebase.profile.lastName,
            artisanEmail: props.firebase.profile.email,
            artisanProfileImage: props.firebase.profile.profile_image,
            descriptionOfContract: props.demand.contract.description,
            startDate: props.demand.contract.start_date,
            contactNumber: props.demand.contract.artisan_phone_number,
            endDate: firebase.firestore.Timestamp.now(),
            postDate: props.demand.contract.post_date,
            materialCost: props.demand.contract.material_cost,
            materialList: props.demand.contract.material_list,
            wage: props.demand.contract.wage,
            totalCost:  props.demand.contract.total_cost,
            additionalInfo: editorText,
            artisanAddress: props.firebase.profile.address,
        }
        props.finalizeDemand(payload);
        reset(e);
    }

    const reset = (e)=>{
        props.handleClick(e);
    }

    return(
        <>
        {props.showModal === 'open' && (
            <Container>
            <Content>
                <Header>
                <h2>Avez-vous terminé ce travail?</h2>
                    <button onClick={(event) => reset(event) }>
                        <img src="/images/close.png" alt="" />
                    </button>
                </Header>
                <SharedContent>
                   
                    <UserInfo>
                    {props.userId && props.firebase.profile.profile_image ? <img src={props.firebase.profile.profile_image}/>
                    :
                    <img src="/images/user.svg" alt="" />
                    }
                    <span>{props.firebase.profile.firstName}</span>
                
                    </UserInfo>
                   
                    <Editor>
                    <h4>En confirmant cela, vous demanderez le paiement !</h4>
                    
                    <label id='totalCost'>Coût total</label>
                    {props.demand.contract.total_cost &&
                    <input readOnly value={props.demand.contract.total_cost} ></input>
                    }   

                    <label id='responseText'>Des informations supplémentaires ?</label>
                    
                    <textarea value={editorText} onChange={(e)=> setEditorText(e.target.value)} placeholder='You want to add any additional information?' autoFocus={true}></textarea> 
                     
                
                    

                </Editor>

                <SharedCreation>
                    <RejectButton 
                       
                         onClick={(e)=> reset(e) }
                    >Pas encore!
                    </RejectButton>
                    <AcceptButton 
                        // disabled={ !editorText || !artisanPhoneNumber || !materialCost || !wage || !estimatedVisitDate ? true : false}
                        onClick={(event)=> finalizeDemand(event)  }
                    >OUI
                    </AcceptButton>
                </SharedCreation>
                </SharedContent>
            </Content>
        </Container>
        )
        }
        </>
        
    )
};



const Container = styled.div`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:9999;
    color:black;
    background-color:rgba(0,0,0,0.8);
    animation: fadeIn 0.3s;
`;

const Content = styled.div`
    width:100%;
    max-width:552px;
    background-color:white;
    max-height:90%;
    overflow:initial;
    border-radius:5px;
    position: relative;
    display:flex;
    flex-direction:column;
    top:32px;
    margin: 0 auto;
`;

const Header = styled.div`
    display:block;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(0,0,0, 0.15);
    font-size:16px;
    line-height: 1.5;
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items:center;
    button{
        height:40px;
        width:40px;
        min-width:auto;
        color: rgba(0,0,0,0.15);
        background-color:transparent;
        border: none;
        justify-content: center;
        cursor: pointer;
        &:hover{
            border-radius:50%;
            background-color: #aaa;
        }
        
    }
    img{
        width:40px;
        margin-bottom:3px;
    }
`;

const SharedContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow:1;
    overflow-y:auto;
    vertical-align: baseline;
    background: transparent;
    padding: 8px 12px;
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 12px;

    svg, img{
        width: 48px;
        height:48px;
        background-clip: content-box;
        border: 2px solid transparent;
        border-radius: 50%;
    }
    span{
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        margin-left: 5px;
    }
`;

const SharedCreation = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 12px 16px;
`;

const AssetButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-width: auto;
    color: rgba(0,0,0,0.5);
    border-radius:5px;
    cursor: pointer;
    img{
        width: 25px;
        margin-top:5px;
        
    }
`;

const AttachAssets = styled.div`
    align-items: center;
    display: flex;
    padding-right: 8px;
    ${AssetButton}{
        width:40px;
    }
`;

const ShareComment =styled.div`
    padding-left: 8px;
    margin-right:auto;
    border-left: 1px solid rgba(0,0,0,0.15);
    ${AssetButton}{
        svg{
            margin-right: 5px;
        }
    }
`;

const PostButton = styled.button`
    min-width:60px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    font-weight:bold;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#c0a375')};
    color: ${(props)=> (props.disabled ? "#aaaa" : '#111111')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'};
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;

const Editor = styled.div`
    padding: 12px 24px;

    textarea{
        width: 100%;
        min-height: 100px;
        resize: none;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }

    input{
        width: 100%;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }
`;


const AcceptButton = styled.button`
    min-wifth:60px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    margin-right:20px;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#007500')};
    color: ${(props)=> (props.disabled ? "#aaaa" : 'white')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'};
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;
const RejectButton = styled.button`
    min-wifth:60px;
    margin-left:20px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#D70A00')};
    color: ${(props)=> (props.disabled ? "#aaaa" : 'white')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'}; 
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;


const mapStateToProps = (state) => ({firebase, firestore})=>{
    return{
        firebase,
        userId: firebase.auth.uid,
    }
};

const mapDispatchToProps = (dispatch)=>({
    // finish the job function
    finalizeDemand: (payload) => dispatch(finalizeJobArtisan(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobFinalisingModal);