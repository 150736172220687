import { SET_LOADING_STATUS, GET_ARTISANS } from "../Actions/actionTypes";

export const initialState = {
    artisans: [],
    loading: false,
}

const artisanReducers = (state = initialState, action)=>{
    switch(action.type){
        case GET_ARTISANS:
            return{
                ...state,
                artisans: action.payload,
            }
        case SET_LOADING_STATUS:
            return{
                ...state,
                loading: action.status,
            }
        default:
            return state;
    }
}

export default artisanReducers;