import { SET_LOADING_STATUS, GET_USERS, SET_USER } from "../Actions/actionTypes";

export const initialState ={
    users: [],
    loading:false,
}

const userReducers = (state = initialState, action) =>{
    switch(action.type){
        case GET_USERS:
            return{
                ...state,
                users: action.payload,
            }
        
        case SET_LOADING_STATUS:
            return{
                ...state,
                loading:action.status,
            }

        default:
            return state;
    }
};

export default userReducers;