import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import styled from 'styled-components';

import Pdf from "react-to-pdf";
import { connect } from "react-redux";

import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getDemandsForArtisanAPI  } from "../../Store/Actions/demandActions";
import { getUsersAPI } from "../../Store/Actions/userActions";

const ref = React.createRef();
const DevisPDFComponent = (props)=> {
    useEffect(()=>{
        props.getDemands()
    },[]);

    const receivedData = useLocation();
    const demandToPrint = receivedData.state.demandToPrint;
    console.log(receivedData)

    const [editorText, setEditorText] = useState(demandToPrint.description);
    const [artisanFirstName, setArtisanFirstname] = useState(demandToPrint.demand_id);

    //getting the materials list
    const materialList = demandToPrint.contract &&  demandToPrint.contract.material_list && demandToPrint.contract.material_list.map((eachMaterial, index)=>
    ({
        id: index,
        materialName: eachMaterial.material_name,
        materialPrice: eachMaterial.material_price, 
        materialUnit: eachMaterial.material_quantity
    }));

    //calculating the total cost of materials
    const totalMaterialCost = materialList && materialList.reduce((a, c) => (a+c.materialUnit * c.materialPrice), 0);

    const totalCostToPay = parseFloat(totalMaterialCost) + parseFloat(demandToPrint.contract.wage);

    // HTML
    return(
        <div> 
        <DownloadBottonDiv>  
        <Pdf targetRef={ref} filename="lepetitboulot-DEVIS.pdf" x={.5} y={.5}  scale={0.8}>
        {({ toPdf }) =>
        <DownloadBtn onClick={toPdf}>Télécharger le devis</DownloadBtn>}
        </Pdf >       
        </DownloadBottonDiv>      
    
        <Content ref={ref}>
            <HeaderLogo >
            <h1 >DEVIS</h1>
            </HeaderLogo>
            <HeaderInfo >
            <h2>Ce document n'est pas le contrat final. Aucune transaction n'a été effectuée et c'est juste pour informer le client.</h2>
            </HeaderInfo>
            <SharedContent>
                
            {/* <h2 style={{textAlign: 'center'}}>L'Artisan / Le Client</h2> */}
            <Users>
                <UserAddresses>
                    
                <ArtisanInfoHeader>

                Artisan
                
                </ArtisanInfoHeader>
                    <ArtisanInfo>
                    {demandToPrint.contract&& demandToPrint.contract.artisan_profile_image ? <img src={demandToPrint.contract.artisan_profile_image}/>
                    :
                    <img src="/images/user.svg" alt="" />
                    }
                    {demandToPrint.contract && demandToPrint.contract.artisan_firstname ? 
                    
                    <span >{demandToPrint.contract.artisan_firstname} {demandToPrint.contract.artisan_lastname}</span>
                    :
                    null
                    }

                    </ArtisanInfo>
                    <AddressAndPhone>
                            <PermPhoneMsgIcon/>
                            <Address>{ demandToPrint.contract.artisan_phone_number}</Address>
                    </AddressAndPhone>
                    <AddressAndPhone>
                            <MarkunreadIcon/>
                            <Address>{demandToPrint.contract.artisan_email}</Address>
                    </AddressAndPhone>
                    <AddressAndPhone>
                            <LocationOnIcon/>
                            <Address>{demandToPrint.contract.artisan_address}</Address>
                    </AddressAndPhone>
                
                </UserAddresses>

                {/* client */}
                <UserAddresses>
                    <ClientInfoHeader>

                    Client
                    </ClientInfoHeader>
                    <ClientInfo>
                        {demandToPrint.contract && demandToPrint.client_profile_image?
                            <img src={demandToPrint.client_profile_image}/>
                        :<img src="/images/user.svg" alt="" />
                        }
                        <span>{demandToPrint.user_firstname} {demandToPrint.user_lastname}</span>
                    </ClientInfo>
                    <AddressAndPhone>
                            <PermPhoneMsgIcon/> 
                            <Address>{ demandToPrint.client_phone_number}</Address>
                    </AddressAndPhone>

                    <AddressAndPhone>
                            <MarkunreadIcon/>
                            <Address>{demandToPrint.user_email_address}</Address>
                    </AddressAndPhone>
                    
                    {demandToPrint.demand_address === demandToPrint.client_address ?
                    <AddressAndPhone>
                    <LocationOnIcon/>
                    <Address>{demandToPrint.demand_address}</Address>
                    </AddressAndPhone>
                    
                    :
                    <div>
                    <AddressAndPhone>
                    <LocationOnIcon/>
                    <Address>Adresse du chantier :  {demandToPrint.demand_address}</Address>
                    </AddressAndPhone>
                    <AddressAndPhone>
                    <LocationOnIcon/> 
                    <Address>Adresse client/facturation : {demandToPrint.demand_address}</Address>
                    </AddressAndPhone>
                    </div>
                    }
                    
                </UserAddresses>
            </Users>                                
                
            <Editor>
                <label id='contractDetails'>Description de la demande / ID de référence : {demandToPrint.demand_id}</label>
                {demandToPrint.contract &&
                <textarea value={editorText} ></textarea> 
                }   
                
                <label id='artisanDetails'>Description du problème par l'artisan</label>
                {demandToPrint.contract &&
                <textarea value={demandToPrint.contract.description} ></textarea> 
                }             

                <ContractDetails> 

                <Table>
                    <table>
                    <thead>
                        <th >Détails</th>
                        <th>Quantité</th>
                        <th >Prix ​​unitaire</th>
                        <th >Totale €</th>
                    </thead>
                    <tbody>
                    {demandToPrint.contract.material_list?  demandToPrint.contract.material_list.map((eachMaterial, key)=> (                            
                        <tr key={key}>
                        <td >Matériaux utilisés: {eachMaterial.material_name}</td>
                        <td>{eachMaterial.material_quantity}</td>
                        <td>{eachMaterial.material_price}</td>
                        <td>{eachMaterial.material_price* eachMaterial.material_quantity}</td>
                        </tr>
                    ))
                    :null}
                    </tbody>
                    
                    <tbody>
                        <tr>
                        <td>Wage: </td>
                        <td>---</td>
                        <td>---</td>
                        <td>{demandToPrint.contract.wage}</td>
                        </tr>
                    </tbody>
                    </table>   
                </Table>

                </ContractDetails>
                                    
               

                <label style={{fontWeight:'600'}} id='materialCost'>Total Cost: €</label>
                
                <input style={{fontWeight:'600', padding:'5px'}} value={totalCostToPay}  />
                
                <h4>Projet mis en ligne le   {demandToPrint.date.toDate().toLocaleDateString()}</h4> 
                {demandToPrint.contract && demandToPrint.contract.end_date?
                <h4>Projet terminé à {demandToPrint.contract.end_date.toDate().toLocaleDateString()}</h4>
                : <h4>Le projet n'est pas encore terminé</h4>}
                
            </Editor>

           

            <Footer>
                <h4>Le Petit Boulot</h4>
                <CompanyAddress>
                73 Rue Rabaneau, 33 440 Ambarès
                <h5>
                support@lepetitboulot.fr
                </h5>
                
                </CompanyAddress>
            </Footer>

            </SharedContent>                    
        </Content>
    </div>
    );

}

// css
const Content = styled.div`
    width:90%;
    background-color:white;
    max-height:100%;
    overflow:initial;
    border-radius:5px;
    position: relative;
    display:flex;
    flex-direction:column;
    top:32px;
    margin: 0 auto;
`;

const Header = styled.div`
    display:block;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(0,0,0, 0.15);
    font-size:16px;
    line-height: 1.5;
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    display: flex;
    align-items:center;
    button{
        height:40px;
        width:40px;
        min-width:auto;
        color: rgba(0,0,0,0.15);
        background-color:transparent;
        border: none;
        justify-content: center;
        cursor: pointer;
        &:hover{
            border-radius:50%;
            background-color: #aaa;
        }
        
    }
    h2{
        text-align: center;
    }
    img{
        width:40px;
        margin-bottom:3px;
    }
`;

const HeaderLogo = styled(Header)`
    justify-content:center;
    background-color:#111111;
    color:#c0a375;
    h1{
        font-weight:'700'
    }
    `;
const HeaderInfo = styled(Header)`
justify-content:center;
    background-color:#111111;
    color:#fff;
    h1{
        font-weight:'700'
    }
`

const SharedContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow:1;
    overflow-y:auto;
    vertical-align: baseline;
    background: transparent;
    padding: 8px 12px;
`;


const Users = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    text-align: left; 
    justify-content: space-around;
    margin-bottom:10px;
    border-bottom: 1px solid rgba(0,0,0, 0.15);
    padding-bottom: 10px;
`;



const ArtisanInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 12px;

    svg, img{
        width: 48px;
        height:48px;
        background-clip: content-box;
        border: 2px solid transparent;
        border-radius: 50%;
    }
    span{
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        margin-left: 5px;
    }
`;

const ArtisanInfoHeader = styled(ArtisanInfo)`
    font-weight: bold;
    text-decoration: underline dotted;
    justify-content: center;
`;

const ClientInfoHeader = styled(ArtisanInfoHeader)``;

const UserAddresses = styled.div`
    display: flex;
    flex-direction: column;
`;

const AddressAndPhone = styled.div`
    display: flex;
    flex-direction:row;
`;

const Address = styled.span`
    display: flex;
    flex-direction: row;
    margin-left:10px;
`;
const ClientInfo = styled(ArtisanInfo)`
`;


const Editor = styled.div`
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right:auto;
    width:80%;

    textarea{
        width: 100%;
        min-height: 100px;
        resize: none;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }

    input{
        width: 100%;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }
`;

const ContractDetails =styled.div`
    display:flex;
    justify-content:center;
    width:100%;
`;

const Table = styled.div`
    display:flex;
    table-layout: fixed;
    width: 100%;
    align-items: stretch;
    justify-content: center;
    align-items: center;
    border-collapse:collapse;
    border-spacing:0;
    padding:20px;
    
    td{
        border-color:black;
        border-style:solid;
        border-width:1px;
        border-radius:5px;
        overflow:hidden;
        padding:10px 5px;
        word-break:normal;
    }
    th{
        border-color:black;
        border-style:solid;
        border-width:1px;
        font-weight:normal;
        overflow:hidden;
        padding:10px 5px;
        word-break:normal;
        border-radius:5px;
        font-weight:bold;
        text-align:center;
        vertical-align:top
    }
    @media(max-width: 767px) {
        td {width: auto !important;
            width: auto !important;
            overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}
`;

const DownloadBtn = styled.button`
    min-width:60px;
    border-radius: 20px;
    padding-left: 16px;
    margin:0 auto;
    padding-right: 16px;
    margin-bottom:10px;
    margin-top:10px;
    display: block;
    font-weight:bold;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#c0a375')};
    color: ${(props)=> (props.disabled ? "#aaaa" : '#111111')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'};
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;
const DownloadBottonDiv = styled.div`
    justify-content:center;
    
`;


const Footer  = styled.div`
    border-top: 1px solid rgba(0,0,0, 0.15);
    justify-content: center;
    text-align: center;
    align-items: center;
    width:100%;
    height:80px;
    position:relative;
    bottom:0;
    left:0;
    margin-top:10px;
`;

const CompanyAddress=styled.div``;

const mapStateToProps = (state)=> ({firebase, demands, users})=>{
    return{
        firebase,
        userId: firebase.auth.uid,
        loading: state.demands.loading,
        demand: demands.demands,
        error: demands.deleteDemand.error,
        loadingDemandDelete: demands.deleteDemand.loading,
        userListItem: users.users,
    }
};

const mapDispatchToProps = (dispatch) => ({
    // get all the demands 
    getDemands: ()=> dispatch(getDemandsForArtisanAPI),
    getUserListFunction : ()=> dispatch(getUsersAPI),
});

export default connect(mapStateToProps, mapDispatchToProps)(DevisPDFComponent);
