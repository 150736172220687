import React, { useState, useRef  } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import styled from 'styled-components';
import ReactStars from "react-rating-stars-component";

import { Swiper, SwiperSlide } from 'swiper/react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';

import SwiperCore, {Keyboard,Scrollbar,Pagination,Navigation,} from 'swiper/core';

import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';


import { useReactToPrint } from 'react-to-print';
import ComponentToPrint from "./ComponentToPrint";

const useStyles =  makeStyles({
	media:{
        height:0,
		paddingTop: '100%',
	},
	swiperContainer: {
		paddingBottom: '3rem',
		'& .swiper-pagination-bullet': {
			background: '#aaa',
		},
		'& .swiper-button-next:after': {
			fontSize: '1.5rem !important',
            width: '60px',
            height: '30px',
            color: 'rgba(0,0,0,0.3)',
            color: '#111111',
            backgroundColor: '#d6d4d5',
            borderRadius: '50%',
            paddingTop:'5px',

		},
		'& .swiper-button-prev:after': {
			fontSize: '1.5rem !important',
            width: '60px',
            height: '30px',
            color: 'rgba(0,0,0,0.3)',
            color: '#111111',
            backgroundColor: '#d6d4d5',
            borderRadius: '50%',
            paddingTop:'5px',
		},
        "@media (max-width: 768px)":{
            '& .swiper-button-next:after': {
            paddingTop:'7px',
            },
            '& .swiper-button-prev:after': {
                paddingTop:'7px',
            }
        }

	},
})

SwiperCore.use([Keyboard, Scrollbar, Pagination, Navigation])



const ClientPreviousProjectModal = (props)=>{

    const { media, swiperContainer } = useStyles();
    // printing consts
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    //--------

    const reset= (e) =>{

        props.handleClick(e);
    }

    // for goint to contract 'page
    const history = useHistory();   
    const routeChange = ()=>{
        let path = 'contract';
        history.push({pathname: path, state:{demandToPrint: props.demand}});
}

    return(
        <>
        {props.showModal === 'open' && (
            <Container >
            <Content>
                <Header>
                    <h3>Votre précédent projet réalisé par : {props.demand.contract.artisan_firstname} </h3>
                    <button onClick={(event) => reset(event)}>
                        <img src="/images/close.png" alt="" />
                    </button>
                </Header>
                
                <Article >
                    
                        <div>
                            <SharedActor>
                           
                            <a>
                                {props.demand.contract.artisan_profile_image ? 
                                <img src={props.demand.contract.artisan_profile_image} />
                                :
                                <img src='/images/user.svg'/>
                                }
                                <div>
                                    {props.demand.contract.artisan_firstname? 
                                    <span>{props.demand.contract.artisan_firstname}</span> :
                                    <span></span>
                                    }
                                    
                                    <span>{props.demand.date.toDate().toLocaleDateString()}</span>
                                </div>
                            </a>


                        </SharedActor>
                                                
                        <SharedImg>
                            
                            <Grid item container xs={12}  justifyContent='center'>
                            <Grid item xs={12} >
                                <Swiper 
                                        slidesPerView= 'auto'
                                        grabCursor
                                        keyboard={{ enabled: true }}
                                        pagination={{ clickable: true }}
                                        navigation
                                        className={swiperContainer}
                                        >
                                            {props.demand.demand_image.map((image, index)=>(
                                                <SwiperSlide key={index}>
                                                    <CardMedia className={media} image={image}/>
                                                </SwiperSlide>

                                            ))}
                                        </Swiper>    
                                </Grid>
                            </Grid>
                        </SharedImg>              
                                            
                        <Description>
                        Score:
                        {props.demand.review && props.demand.review.rating?
                        <ReactStars
                        count={5}
                        value={props.demand.review.rating}
                        size={24}
                        edit={false}
                        isHalf={false}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#c0a375"
                    />
                    :
                    <ReactStars
                        count={5}
                        value={5}
                        size={24}
                        isHalf={false}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#e0e6ee"/>
                        }
                        </Description>
                        
                        <Description>
                           Review: {props.demand.review.review_text}
                        </Description>

                        </div>
                        {/* Print Button */}
                        <div
                        style={{ display: "none" }}// This make ComponentToPrint show   only while printing
                        > 
                        <ComponentToPrint ref={componentRef} demand={props.demand} />
                        </div>
                        
                        <PostButton style={{ display: "none" }} onClick={handlePrint} >Imprimez votre contrat !</PostButton>
                        
                        
                        <PostButton onClick={routeChange} > Votre contrat</PostButton>

                    </Article>
                
            </Content>
        </Container>
        )}
        </>
    );
};


const Container = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:9999;
    color:black;
    background-color:rgba(0,0,0,0.8);
    animation: fadeIn 0.3s;

`;

const CommonCard= styled.div`
    text-align: center;
    overflow:hidden;
    margin-bottom: 8px;
    background-color: #fff;
    border-radius:5px;
    position:relative;
    box-shadow: 0 0 0 1px rgba(0 0 0 / 15%), 0 0 0 rgba(0 0 0 /20%);
    border: none;

`;

const Article = styled(CommonCard)`
    padding: 0;
    margin: 0 0 8px;
    overflow:visible;
`;

const SharedActor = styled.div`
    padding-right: 40px;
    flex-wrap:nowrap;
    padding: 12px 16px 0;
    margin-bottom: 8px;
    align-items:center;
    display:flex;
    
    a{
        margin-right:12px;
        flex-grow:1;
        overflow:hidden;
        display:flex;
        text-decoration:none;

        img{
            width:48px;
            height:48px;
            border-radius:50%;

        }
        &>div{
            display:flex;
            flex-direction:column;
            flex-grow:1;
            flex-basis:0;
            margin-left: 8px;
            overflow:hidden;
            span{
                text-align:left;
                &:first-child{
                    font-size: 14px;
                    font-weight:700;
                    color: rgba(0,0,0,1);
                }
                &:nth-child(n+1){
                    font-size:12px;
                    color:rgba(0,0,0,0.6);

                }
            }
        }
    }

    button{
        position: absolute;
        right:12px;
        top:10px;
        background:transparent;
        border:none;
        outline:none;
        cursor:pointer;
        img{
            width:18px;
        }
    }
`;
const SharedImg= styled.div`
    margin-top: 8px;
    width: 100%;
    display:flex;
    justify-content:center;
    position: relative;
    background-color: #f9fafb;
    Grid{
        width:100%;
    }
    img{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
`;

const Description =  styled.div`
    padding : 0 16px;
    overflow: hidden;
    color: rgba(0,0,0,0.9);
    font-size:14px;
    text-align:left;
`;
const Content = styled.div`
    width:100%;
    max-width:552px;
    background-color:white;
    max-height:90%;
    overflow:initial;
    border-radius:5px;
    position: relative;
    display:flex;
    flex-direction:column;
    top:32px;
    margin: 0 auto;
`;

const Header = styled.div`
    display:block;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(0,0,0, 0.15);
    font-size:16px;
    line-height: 1.5;
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items:center;
    button{
        height:40px;
        width:40px;
        min-width:auto;
        color: rgba(0,0,0,0.15);
        background-color:transparent;
        border: none;
        justify-content: center;
        cursor: pointer;
        &:hover{
            border-radius:50%;
            background-color: #aaa;
        }
        
    }
    img{
        width:40px;
        margin-bottom:3px;
    }
`;


const PostButton = styled.button`
    min-width:60px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom:10px;
    margin-top:10px;
    font-weight:bold;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#c0a375')};
    color: ${(props)=> (props.disabled ? "#aaaa" : '#111111')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'};
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;





const mapStateToProps = (state)=>({firebase, firestore})=>{
    return{
        firebase,
        userId: firebase.auth.uid,
    }
};

const mapDispatchToProps = (dispatch)=>({

});

export default connect(mapStateToProps, mapDispatchToProps)(ClientPreviousProjectModal);