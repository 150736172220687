import React, {useEffect, useState} from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Message from '../../../Components/UI/Messages/Message';
import { connect } from 'react-redux';
import * as actions from '../../../Store/Actions';

import { FormWrapper, StyledForm } from '../../../Hoc/elements';
import Input from '../../../Components/UI/Forms/Inputs/Input';
import Button from '../../../Components/UI/Forms/Button/Button';
import Heading from '../../../Components/UI/Headings/Heading';
import CustomLink from '../../../Components/UI/CustomLink/CustomLink';

const MessageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  top:300px;
`;

const LoginSchema = Yup.object().shape({
  email:Yup.string()
      .email('Email invalide.')
      .required("L'e-mail est requis."),
  password: Yup.string().required('Le mot de passe est requis.').min(8, 'Trop court...'),

});

function Login({login, loading, error, cleanup}) {

  
  useEffect(() => {
    return () => {
      cleanup();
    }
  }, [cleanup]);

  return (
      <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={LoginSchema}
      onSubmit={async (values, { setSubmitting }, handleSubmit) => {
        await login(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid }) => (
        <FormWrapper>
          <Heading noMargin size="h1" color="white">
          Connectez-vous à votre compte
          </Heading>
          {/* <Heading bold size="h4" color="white">
          Remplissez vos coordonnées pour vous connecter à votre compte
          </Heading> */}
          <StyledForm>
            <Field
              type="email"
              name="email"
              placeholder="Votre email..."
              component={Input}
            />
            <Field
              type="password"
              name="password"
              placeholder="Votre mot de passe..."
              component={Input}
            />
            <Button  disabled={!isValid || isSubmitting} loading= {loading? "S'identifier...": null} type="submit">
            S'identifier
            </Button>
            <br />
              <CustomLink link = '/recover' color = 'white'>
              Mot de passe oublié?
              </CustomLink>
            <MessageWrapper><Message error show={error}>{error}</Message></MessageWrapper>
          </StyledForm>
          
        </FormWrapper>
      )}
    </Formik>
  );
};

const mapStateToProps = ({auth}) => ({
  loading: auth.loading,
  error: auth.error
})

const mapDispatchToProps = {
  login: actions.signIn,
  cleanup: actions.cleanMessage
}


export default connect(mapStateToProps, mapDispatchToProps)(Login)
