import React, { useState } from "react";
import { connect } from "react-redux";
import firebase from 'firebase';
import styled from 'styled-components';
import { sendContract, sendModificaionArtisan } from "../../../Store/Actions/actionOffer";

const ModificationModal = (props)=>{

    // attributes
    const [editorText, setEditorText]= useState("");
    const [artisanPhoneNumber, setArtisanPhoneNumber] = useState(props.firebase.profile.phone_number);
    const [materialCost, setMaterialCost] = useState(props.demand.contract && props.demand.contract.material_cost? props.demand.contract.material_cost:0 );
    const [wage, setWage] = useState(props.demand.contract && props.demand.contract.wage? props.demand.contract.wage:0 );
    const [estimatedVisitDate, setEstimatedVisitDate] = useState('');

    // functions
    const handlePhoneNumber = (e)=>{
        const artisanPhoneNumber = e.target.value;

        setArtisanPhoneNumber(artisanPhoneNumber);
    }

    //
    const handleMaterialCost = (e) => {
        e.preventDefault();
        const costInput = e.target.value;

        setMaterialCost(costInput);
        console.log(materialCost);
    }

    const handleEstimatedWage = (e) => {
        const wageInput = e.target.value;

        setWage(wageInput);
        console.log(wage);
    }

    const handleVisit = (e)=>{
        const dateInput = e.target.value;
        setEstimatedVisitDate(dateInput);
    };

    //  the PAYLOAD
    const sendModification = (e)=>{
        const contract = {
            demandId: props.demand.demand_id,
            artisanId: props.firebase.auth.uid,
            artisanFirstName: props.firebase.profile.firstName,
            artisanLastName: props.firebase.profile.lastName,
            artisanEmail: props.firebase.profile.email,
            artisanProfileImage: props.firebase.profile.profile_image,
            descriptionOfContract: props.demand.contract.description,
            modificationResponse: editorText,
            modificationAsked: props.demand.modification.modification,
            startDate: estimatedVisitDate,
            contactNumber: artisanPhoneNumber,
            timestamp: firebase.firestore.Timestamp.now(),
            materialCost: materialCost,
            materialList: props.demand.contract.material_list,
            wage: wage,
            totalCost:  +wage + +materialCost,
        }
        props.sendModification(contract);
        reset(e);
    }

    const reset = (e)=>{
        props.handleClick(e);
    }

    return(
        <>
        {props.showModal === 'open' && (
            <Container>
            <Content>
                <Header>
                <h2>Les informations sur le contrat</h2>
                    <button onClick={(event) => reset(event) }>
                        <img src="/images/close.png" alt="" />
                    </button>
                </Header>
                <SharedContent>
                    <UserInfo>
                    {props.userId && props.firebase.profile.profile_image ? <img src={props.firebase.profile.profile_image}/>
                    :
                    <img src="/images/user.svg" alt="" />
                    }
                    <span>{props.firebase.profile.firstName}</span>
                
                    </UserInfo>
                    <Editor>
                    
                    <label id='phoneNumber'>Modifications demandées</label>
                    {props.demand.modification &&
                    <textarea readOnly value={props.demand.modification.modification} ></textarea>
                    }   

                    <label id='responseText'>Votre réponse</label>
                    {props.demand.modification &&
                    <textarea style={{fontSize:'16px'}} value={editorText} onChange={(e)=> setEditorText(e.target.value)} placeholder='Your response to the asked modifications...' autoFocus={true}></textarea> 
                    }   
                                            

                    <label id='phoneNumber'>Votre numéro de téléphone</label>
                    <input value={artisanPhoneNumber}  type="number" onChange={handlePhoneNumber}/>

                    <label id='materialCost'>Vous avez mentionné le coût du matériel comme {props.demand.contract.material_cost} €. Quel est le coût final ?</label>
                    
                    <input value={materialCost}  type="number" onChange={handleMaterialCost} />
                    
                    
                    
                    <label id='wage'>Vous avez mentionné le salaire comme {props.demand.contract.wage} €. Quel est le salaire final ?</label>
                    
                    <input value={wage }  type="number" onChange={handleEstimatedWage} />
                    
                    
                    
                    <label id='estimatedVisitDate'>La date de début précédente est : "{props.demand.contract.start_date}" quelle est la décision finale ?</label>
                    <input value={estimatedVisitDate} placeholder="Begining of your project"  type="date" onChange={handleVisit}/>

                </Editor>

                <SharedCreation>
                    <RejectButton 
                       
                         onClick={()=>console.log('cancel the contract')  }
                    >Annuler le contrat
                    </RejectButton>
                    <AcceptButton 
                        disabled={ !editorText || !artisanPhoneNumber || !materialCost || !wage || !estimatedVisitDate ? true : false}
                        onClick={(event)=> sendModification(event)  }
                    >Envoyer la modification
                    </AcceptButton>
                </SharedCreation>
                </SharedContent>
            </Content>
        </Container>
        )
        }
        </>
    )
};


const Container = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:9999;
    color:black;
    background-color:rgba(0,0,0,0.8);
    animation: fadeIn 0.3s;
`;

const Content = styled.div`
    width:100%;
    max-width:552px;
    background-color:white;
    max-height:90%;
    overflow:initial;
    border-radius:5px;
    position: relative;
    display:flex;
    flex-direction:column;
    top:32px;
    margin: 0 auto;
`;

const Header = styled.div`
    display:block;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(0,0,0, 0.15);
    font-size:16px;
    line-height: 1.5;
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items:center;
    button{
        height:40px;
        width:40px;
        min-width:auto;
        color: rgba(0,0,0,0.15);
        background-color:transparent;
        border: none;
        justify-content: center;
        cursor: pointer;
        &:hover{
            border-radius:50%;
            background-color: #aaa;
        }
        
    }
    img{
        width:40px;
        margin-bottom:3px;
    }
`;

const SharedContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow:1;
    overflow-y:auto;
    vertical-align: baseline;
    background: transparent;
    padding: 8px 12px;
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 12px;

    svg, img{
        width: 48px;
        height:48px;
        background-clip: content-box;
        border: 2px solid transparent;
        border-radius: 50%;
    }
    span{
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        margin-left: 5px;
    }
`;

const SharedCreation = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 12px 16px;
`;

const AssetButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-width: auto;
    color: rgba(0,0,0,0.5);
    border-radius:5px;
    cursor: pointer;
    img{
        width: 25px;
        margin-top:5px;
        
    }
`;

const AttachAssets = styled.div`
    align-items: center;
    display: flex;
    padding-right: 8px;
    ${AssetButton}{
        width:40px;
    }
`;

const ShareComment =styled.div`
    padding-left: 8px;
    margin-right:auto;
    border-left: 1px solid rgba(0,0,0,0.15);
    ${AssetButton}{
        svg{
            margin-right: 5px;
        }
    }
`;

const PostButton = styled.button`
    min-width:60px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    font-weight:bold;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#c0a375')};
    color: ${(props)=> (props.disabled ? "#aaaa" : '#111111')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'};
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;

const Editor = styled.div`
    padding: 12px 24px;

    textarea{
        width: 100%;
        min-height: 100px;
        resize: none;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }

    input{
        width: 100%;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }
`;

const UploadImage = styled.div`
    text-align:center;
    img{
        width:100%;
    }
`;

const StyledSelect = styled.select`
  padding: 1.2rem 2rem;
  width: 100%;
  background-color: #242526;
  color: var(--color-white);
  font-weight: 500;
  font-size: 1.2rem;
  border-radius: 2rem;
  border: none;
  margin-bottom:1rem;
  padding: 4px ;
  &::placeholder {
    color: var(--color-white);
  }
`;
const AcceptButton = styled.button`
    min-wifth:60px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    margin-right:20px;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#007500')};
    color: ${(props)=> (props.disabled ? "#aaaa" : 'white')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'};
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;
const RejectButton = styled.button`
    min-wifth:60px;
    margin-left:20px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#D70A00')};
    color: ${(props)=> (props.disabled ? "#aaaa" : 'white')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'}; 
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;


const mapStateToProps = (state) => ({firebase, firestore})=>{
    return{
        firebase,
        userId: firebase.auth.uid,
    }
};

const mapDispatchToProps = (dispatch)=>({
    // send the contract
    sendModification: (contract) => dispatch(sendModificaionArtisan(contract)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModificationModal);