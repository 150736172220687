
import * as actions from '../Actions/actionTypes';

// sign up action creator
export const signUp= data => async (dispatch, getState, {getFirebase, getFirestore})=>{
    const firebase = getFirebase();
    const firestore = getFirestore();
    
    dispatch({type: actions.AUTH_START});

    try{
        const res = await firebase
            .auth()
            .createUserWithEmailAndPassword(data.email, data.password);
        
            //adding the user to the users node
            await firestore
            .collection('users')
            .doc(res.user.uid)
            .set({
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              user_id: res.user.uid,
              user_type: 'Client',
              phone_number: '',
              address:'',
              profile_image:'',
            });

            dispatch({type:actions.AUTH_SUCCESS})

    }catch(err){
        dispatch({type:actions.AUTH_FAIL, payload : err.message})
    };
    dispatch({type:actions.AUTH_END});
};

// sign up action creator
export const signUpArtisan= data => async (dispatch, getState, {getFirebase, getFirestore})=>{
  const firebase = getFirebase();
  const firestore = getFirestore();
  
  dispatch({type: actions.AUTH_START});

  try{
      const res = await firebase
          .auth()
          .createUserWithEmailAndPassword(data.email, data.password);
      
          //adding the user to the users node
          await firestore
          .collection('users')
          .doc(res.user.uid)
          .set({
            firstName: data.firstName,
            lastName: data.lastName,
            user_id: res.user.uid,
            email: data.email,
            speciality: 'window',
            user_type: 'Artisan',
            registration_status: 'pending',
            phone_number:'',
            address:'',
            artisan_region:'aquitaine',
            profile_image:'',
            artisan_rating:0,
            total_num_jobs:0,
          });
          dispatch({type:actions.AUTH_SUCCESS})

  }catch(err){
      dispatch({type:actions.AUTH_FAIL, payload : err.message})
  };
  dispatch({type:actions.AUTH_END});
};

// Logout action creator
export const signOut = () => async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    try {
     firebase.auth().signOut();
    } catch (err) {
      console.log(err.message);
    }
  };

  // SIgn In action creator
  export const signIn = (data) => async (dispatch, getState, {getFirebase}) =>{
      const firebase = getFirebase();
      dispatch({type: actions.AUTH_START});
      
      try{
            await firebase.auth().signInWithEmailAndPassword(data.email, data.password);
            dispatch({type:actions.AUTH_SUCCESS});

      }catch(err){
          dispatch({type:actions.AUTH_FAIL, payload : err.message})
      }

      dispatch({type:actions.AUTH_END});
  };

  // Cleaning up messages
  export const cleanMessage= () =>({
      type: actions.CLEAN_UP,
  });

  // Verify Email actions type

  export const verifyEmail= ()=> async(
      dispatch,
      getState,
      {getFirebase}
  )=> {
      const firebase = getFirebase();
      dispatch({type:actions.VERIFY_START});
      try{
        const user = firebase.auth().currentUser;
        await user.sendEmailVerification();
        dispatch({ type: actions.VERIFY_SUCCESS });
      }catch(err){
        dispatch({ type: actions.VERIFY_FAIL, payload: err.message });
        console.log(err.message);
      }
  };

  // send recover password email
  export const recoverPassword = data => async (dispatch, getState, { getFirebase})=>{
      const firebase = getFirebase();
      dispatch({type: actions.RECOVERY_START});
      try{
        // send email here
        await firebase.auth().sendPasswordResetEmail(data.email);
        dispatch({type: actions.RECOVERY_SUCCESS});
      }catch(err){
        dispatch({type: actions.RECOVERY_FAIL, payload: err.message});
      }
  }

  // edit Profile function

  

  export const editProfile = data => async (dispatch, getState, {getFirebase, getFirestore})=> {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser;
    // const userEmail = getState().firebase.auth.email;
    // const userId = getState().firebase.auth.uid;
    const {uid: userId, email : userEmail} = getState().firebase.auth;

    dispatch({type: actions.PROFILE_EDIT_START})

    try{
      //edit happens here
      if(data.email != userEmail){
        await user.updateEmail(data.email);
      };

      await firestore.collection('users').doc(userId).set({
        user_id: userId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone_number: `${data.phoneNumber}`,
        user_type: data.userType,
        profile_image: data.profileImage,
        address: data.address,
      });

      //password update
      if(data.password != ''){
        await user.updatePassword(data.password);
      };

      dispatch({type: actions.PROFILE_EDIT_SUCCESS});
    }catch(err){
      dispatch({type: actions.PROFILE_EDIT_FAIL, payload: err.message})
    }
  };


  export const editAccountArtisan = data => async (dispatch, getState, {getFirebase, getFirestore})=> {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser;
    const {uid: userId, email : userEmail} = getState().firebase.auth;

    dispatch({type: actions.PROFILE_EDIT_START})

    try{
      //edit happens here
      if(data.email != userEmail){
        await user.updateEmail(data.email);
      };

      await firestore.collection('users').doc(userId).set({
        user_id: data.userId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        speciality: data.specialityType,
        registration_status: data.registrationStatus,
        user_type: data.userType,
        phone_number: `${data.contactNumber}`,
        address: data.address,
        profile_image: data.profileImage,
        total_num_jobs: data.totalNumJobs,
        artisan_rating:data.rating,
        artisan_region: data.region,

      });

      //password update
      if(data.password != ''){
        await user.updatePassword(data.password);
      };

      dispatch({type: actions.PROFILE_EDIT_SUCCESS});
    }catch(err){
      dispatch({type: actions.PROFILE_EDIT_FAIL, payload: err.message})
    }
  };



  // edit Artisan Profile function
 export const editArtisan = (payload) =>({
  type: actions.EDIT_ARTISAN,
  payload: payload,
})

 // reload a page
 function refreshPage(){ 
  window.location.href='/jobs'; 
}
  export const editArtisanProfile = payload => async (dispatch, getState, {getFirebase, getFirestore})=>{

    const db = getFirestore();
    const artisanId = payload.clientId;
    
    dispatch({type: actions.PROFILE_EDIT_START});
     
    try{

      await db.collection('users').doc(artisanId).set({
          user_id: artisanId, 
          firstName: payload.userFirstName,
          lastName: payload.userLastName,
          profile_image: payload.clientProfileImage,
          email: payload.userEmail,
          speciality: payload.specialityType,
          user_type: payload.userType,
          registration_status: payload.registrationStatus,
          phone_number: `${payload.contactNumber}`,
          address:payload.address,
          artisan_region: payload.artisanRegion,
          artisan_rating: payload.artisanRating,
          total_num_jobs: payload.totalNumJobs,
              
        });

      await refreshPage();
       dispatch({type: actions.PROFILE_EDIT_SUCCESS});

    }catch(err){
      dispatch({type: actions.PROFILE_EDIT_FAIL, payload: err.message})
    }

   
  }

  //Delete user
  export const deleteUser = () => async (dispatch, getState, {getFirebase, getFirestore})=>{
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser;
    const userId = getState().firebase.auth.uid;

    dispatch({type: actions.DELETE_START});
    try{
      
      //from firestore
      await firestore
      .collection('users')
      .doc(userId)
      .delete();

      // delete authentication
      await user.delete();

    }catch(err){
      dispatch({type: actions.DELETE_FAIL, payload: err.message});
    }
  }