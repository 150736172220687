
const theme = {
    colors: {
        main: '#003459',
        mainDark: '#111111',
        mainLight: '#2c3247',
        mainLighter: '#2f82b8',
        textColor: '#333',
        whiteColor: '#fff',
        errorRed: '#ff5757',
        shadow: 'rgba(0,0,0,.2)',
    },

    mediaQueries: {
        smallest: `only screen and (max-width: 25em)`,
        small: `only screen and (max-width: 37.5em)`,
        medium: `only screen and (max-width: 56em)`,
        ipad:`only screen and (max-width: 48.125em)`,
        large: `only screen and (max-width: 80em)`,
        largest: `only screen and (max-width: 90em)`,
        plus: `only screen and (max-width: 25.8em)`,
      },
};

export default theme;