import React from 'react';
import styled from 'styled-components';


const P = styled.p`
  font-weight: 700;
  font-size: 1.2rem;
  color: ${({ error, success }) => {
    if (error) return 'red';
    if (success) return '#4BB543';
    else return 'red';
  }};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transform: translateY(${({ show }) => (show ? '30px' : '0px')});
  text-align: center;
  transition: all 0.2s;
  margin-bottom: -6rem
`;

function Message({children, error, success, show}) {
    return (
        <P error = {error} success={success} show={show}>
            {children}
        </P>
    );
};

export default Message
