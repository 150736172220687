import { useState } from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase';

import styled from 'styled-components';
import { editArtisanProfile } from '../../Store/Actions';






const RegionModal = (props) =>{

    
    const [clientPhoneNumber, setClientPhoneNumber] = useState(props.firebase.profile.phone_number);
    const [clientAddress, setClientAddress] = useState(props.firebase.profile.address);
    const [region, setRegion] = useState('');


    const handleRegion = (e)=>{
        const Input = e.target.value;
        setRegion(Input);
    }

    
    const editRegion = (e) =>{

        const payload = {
            clientId: props.firebase.auth.uid,
            userFirstName: props.firebase.profile.firstName,
            userLastName: props.firebase.profile.lastName,
            clientProfileImage: props.firebase.profile.profile_image,
            userEmail: props.firebase.profile.email,
            specialityType: props.firebase.profile.speciality,
            userType: 'Artisan',
            registrationStatus: props.firebase.profile.registration_status,
            contactNumber: clientPhoneNumber,
            address: clientAddress,
            artisanRating: props.firebase.profile.artisan_rating,
            totalNumJobs: props.firebase.profile.total_num_jobs,
            artisanRegion: region,
            
        }

        props.editRegion(payload);

        reset(e);
    }

    const reset= (e) =>{

        props.handleClick(e);
    }

   

    return(
     <>

        {props.showModal === 'open' && (
            <Container>
                <Content>
                    <Header>
                        <h2>Mettez à jour vos informations</h2>
                        <button onClick={(event) => reset(event) }>
                            <img src="/images/close.png" alt="" />
                        </button>
                    </Header>
                    <SharedContent>
                        <UserInfo>
                            {props.userId && props.firebase.profile.profile_image ? <img src={props.firebase.profile.profile_image}/>
                            :
                            <img src="/images/user.svg" alt="" />
                            }
                            <span>{props.firebase.profile.firstName}</span>
                        </UserInfo>

                        <Editor>
                            
                            
                            <StyledSelect  onChange={handleRegion}>
                            <option disabled selected value="">Votre région : {props.firebase.profile.artisan_region}</option>
                            <option value="aquitaine">Aquitaine</option>
                            <option value="occitanie">Occitanie</option>
                            </StyledSelect>                               

                        </Editor>
                             

                    </SharedContent>
                    <SharedCreation>              
                      
                        <PostButton 
                             disabled={ !region ? true : false}
                             onClick={(event)=> editRegion(event)  }
                        >Modifier
                        </PostButton>
                    </SharedCreation>
                </Content>
            </Container>
        )}
     </>
    )
};

const Container = styled.div`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:9999;
    color:black;
    background-color:rgba(0,0,0,0.8);
    animation: fadeIn 0.3s;
`;

const Content = styled.div`
    width:100%;
    max-width:552px;
    background-color:white;
    max-height:90%;
    overflow:initial;
    border-radius:5px;
    position: relative;
    display:flex;
    flex-direction:column;
    top:32px;
    margin: 0 auto;
`;

const Header = styled.div`
    display:block;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(0,0,0, 0.15);
    font-size:16px;
    line-height: 1.5;
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items:center;
    button{
        height:40px;
        width:40px;
        min-width:auto;
        color: rgba(0,0,0,0.15);
        background-color:transparent;
        border: none;
        justify-content: center;
        cursor: pointer;
        &:hover{
            border-radius:50%;
            background-color: #aaa;
        }
        
    }
    img{
        width:20px;
        margin-bottom:3px;
    }
`;

const SharedContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow:1;
    overflow-y:auto;
    vertical-align: baseline;
    background: transparent;
    padding: 8px 12px;
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 12px;

    svg, img{
        width: 48px;
        height:48px;
        background-clip: content-box;
        border: 2px solid transparent;
        border-radius: 50%;
    }
    span{
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        margin-left: 5px;
    }
`;

const SharedCreation = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 12px 16px;
`;

const AssetButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-width: auto;
    color: rgba(0,0,0,0.5);
    border-radius:5px;
    cursor: pointer;
    img{
        width: 25px;
        margin-top:5px;
        
    }
`;

const AttachAssets = styled.div`
    align-items: center;
    display: flex;
    padding-right: 8px;
    ${AssetButton}{
        width:40px;
    }
`;

const ShareComment =styled.div`
    padding-left: 8px;
    margin-right:auto;
    border-left: 1px solid rgba(0,0,0,0.15);
    ${AssetButton}{
        svg{
            margin-right: 5px;
        }
    }
`;

const PostButton = styled.button`
    min-wifth:60px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#111111')};
    color: ${(props)=> (props.disabled ? "#aaaa" : 'white')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'};
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;

const Editor = styled.div`
    padding: 12px 24px;

    textarea{
        width: 100%;
        min-height: 100px;
        resize: none;
    }

    input{
        width: 100%;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
    }
`;

const UploadImage = styled.div`
    text-align:center;
    img{
        width:100%;
    }
`;

const StyledSelect = styled.select`
  padding: 1.2rem 2rem;
  width: 100%;
  background-color: #242526;
  color: var(--color-white);
  font-weight: 500;
  font-size: 1.2rem;
  border-radius: 2rem;
  border: none;
  margin-bottom:1rem;
  padding: 4px ;
  &::placeholder {
    color: var(--color-white);
  }
`;

const mapStateToProps = (state) => ({firebase, firestore}) => {
    return{
        firebase,
        userId: firebase.auth.uid,

    }
};

const mapDispatchToProps =(dispatch)=> ({
    editRegion: (payload)=> dispatch(editArtisanProfile(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionModal);