import React, { useState } from "react";
import { connect } from "react-redux";
import styled from 'styled-components';
import firebase from 'firebase';
import CurrencyInput from 'react-currency-input-field';
import Tooltip from '@mui/material/Tooltip';


import * as actions from '../../../Store/Actions';
import { addEstimation } from "../../../Store/Actions/actionOffer";

const EstimationWindow = (props)=>{

    

    const [editorText, setEditorText] = useState("");
    const[assetArea, setAssetArea] = useState("");
    const [clientPhoneNumber, setClientPhoneNumber] = useState(props.firebase.profile.phone_number);
    const [materialCost, setMaterialCost] = useState(0);
    const [wage, setWage] = useState(0);
    const [estimatedVisitDate, setEstimatedVisitDate] = useState('');
    const [specialityType, setSpecialityType] = useState('');

    const handlePhoneNumber = (e)=>{
        const clientPhone = e.target.value;

        setClientPhoneNumber(clientPhone);
    }

    //
    const handleMaterialCost = (e) => {
        e.preventDefault();
        const costInput = e.target.value;

        setMaterialCost(costInput);
        console.log(materialCost);
    }
    const handleEstimatedWage = (e) => {
        const wageInput = e.target.value;

        setWage(wageInput);
        console.log(wage);
    }

    const handleVisit = (e)=>{
        const dateInput = e.target.value;
        setEstimatedVisitDate(dateInput);
    }

    const handleSpecialityType = (e)=>{
        const Input = e.target.value;
        setSpecialityType(Input);
    }

    const switchAssetArea = (area) =>{
        setAssetArea(area);
    }
    

    
    const postEstimation = (e) =>{

        const userId = firebase.auth.uid;

        const payload = {
            demandId: props.demandId,
            artisanId: props.firebase.auth.uid,
            artisanFirstName: props.firebase.profile.firstName,
            artisanLastName: props.firebase.profile.lastName,
            artisanEmail:props.firebase.profile.email,
            artisanProfileImage: props.firebase.profile.profile_image,
            descriptionOfEstimation: editorText,
            visitDateEstimation:estimatedVisitDate,
            contactNumber: clientPhoneNumber,
            timestamp: firebase.firestore.Timestamp.now(),
            materialCostEstim: materialCost,
            wageEstim: wage,
        }

        props.postEstimation(payload);
        console.log(payload);

        reset(e);
    }

    const reset= (e) =>{

        setEditorText('');
        props.handleClick(e);
    }

    return (
        <>

        {props.showModal === 'open' && (
            <Container>
            <Content>
                <Header>
                    <h5>Êtes-vous disponible pour accepter ce projet et envoyer votre devis ?</h5>
                    <button onClick={(event) => reset(event) }>
                        <img src="/images/close.png" alt="" />
                    </button>
                </Header>
                <SharedContent>
                    <UserInfo>
                        {props.userId && props.firebase.profile.profile_image ? <img src={props.firebase.profile.profile_image}/>
                        :
                        <img src="/images/user.svg" alt="" />
                        }
                        <span>{props.firebase.profile.firstName}</span>
                    </UserInfo>

                    <Editor>
                        <textarea style={{fontSize:'16px'}} value={editorText} onChange={(e)=> setEditorText(e.target.value)} placeholder= "Besoin d'ajouter quelques informations?"autoFocus={true}></textarea>          
                                      
                        <label style={{fontSize : '16px'}} id='phoneNumber'>Votre numéro de téléphone</label>
                        <input style={{fontSize : '16px'}} value={clientPhoneNumber}  type="number" onChange={handlePhoneNumber}/>

                        {/* <label id='phoneNumber'>Votre numéro de téléphone</label>
                        <input value={clientPhoneNumber}  type="number" onChange={handlePhoneNumber}/>

                        <label id='materialCost'>Estimation du coût du matériel €</label>
                        <input value={materialCost}  type="number" onChange={handleMaterialCost} />
                        
                        <label id='wage'>Estimation de votre salaire €</label>
                        <input value={wage}  type="number" onChange={handleEstimatedWage} />
                        
                        
                        <label id='estimatedVisitDate'>Date de visite</label>
                        <input value={estimatedVisitDate} placeholder="Donnez à votre client une date de visite"  type="date" onChange={handleVisit}/> */}

                        
                    </Editor>
                         

                </SharedContent>
                <SharedCreation>              
                <RejectButton 
                         onClick={(event) => reset(event) }
                    >Annuler
                </RejectButton>

                <AcceptButton 
                        //  onClick={(event)=> postEstimation(event)  }
                        onClick={(event)=> postEstimation(event)  }
                >Accepter 
                </AcceptButton>
                    
                </SharedCreation>
                
            </Content>
        </Container>
        )}
     </> 
    );
}


const Container = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:9999;
    color:black;
    background-color:rgba(0,0,0,0.8);
    animation: fadeIn 0.3s;
`;

const Content = styled.div`
    width:100%;
    max-width:552px;
    background-color:white;
    max-height:90%;
    overflow:initial;
    border-radius:5px;
    position: relative;
    display:flex;
    flex-direction:column;
    top:32px;
    margin: 0 auto;
`;

const Header = styled.div`
    display:block;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(0,0,0, 0.15);
    font-size:16px;
    line-height: 1.5;
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items:center;
    button{
        height:40px;
        width:40px;
        min-width:auto;
        color: rgba(0,0,0,0.15);
        background-color:transparent;
        border: none;
        justify-content: center;
        cursor: pointer;
        &:hover{
            border-radius:50%;
            background-color: #aaa;
        }
        
    }
    img{
        width:40px;
        margin-bottom:3px;
    }
`;

const SharedContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow:1;
    overflow-y:auto;
    vertical-align: baseline;
    background: transparent;
    padding: 8px 12px;
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 12px;

    svg, img{
        width: 48px;
        height:48px;
        background-clip: content-box;
        border: 2px solid transparent;
        border-radius: 50%;
    }
    span{
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        margin-left: 5px;
    }
`;

const SharedCreation = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 12px 16px;
`;

const AssetButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-width: auto;
    color: rgba(0,0,0,0.5);
    border-radius:5px;
    cursor: pointer;
    img{
        width: 25px;
        margin-top:5px;
        
    }
`;


const PostButton = styled.button`
    min-wifth:60px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#111111')};
    color: ${(props)=> (props.disabled ? "#aaaa" : 'white')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;

const Editor = styled.div`
    padding: 12px 24px;

    textarea{
        width: 100%;
        min-height: 100px;
        resize: none;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }

    input{
        width: 100%;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }
`;

const AcceptButton = styled.button`
    min-wifth:60px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    margin-right:20px;
    border:none;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#007500')};
    color: ${(props)=> (props.disabled ? "#aaaa" : 'white')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'};
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;
const RejectButton = styled.button`
    min-wifth:60px;
    margin-left:20px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    border:none;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#D70A00')};
    color: ${(props)=> (props.disabled ? "#aaaa" : 'white')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'}; 
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;


const mapStateToProps = (state) => ({firebase, firestore})=>{
    return{
        firebase,
        userId:firebase.auth.uid,
    }
};

const mapDispatchToProps = (dispatch)=>({
    postEstimation: (payload)=> dispatch(addEstimation(payload)),
});

export default connect( mapStateToProps,mapDispatchToProps)(EstimationWindow);