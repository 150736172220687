import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from "styled-components";
import { firestoreConnect } from 'react-redux-firebase';

import Heading from '../../Components/UI/Headings/Heading';
import { Container } from '../../Hoc/elements';

import MyBoutique from './MyBoutique';
import BoutiqueProfile from './BoutiqueProfile';


function Boutique() {
    return (
        <Wrapper>
            <Container >
                <HomeLayout>
                    <BoutiqueProfile/>
                    {/* <MainSection> */}
                        <MyBoutique/>
                    {/* </MainSection> */}
                    
                </HomeLayout>
            </Container>
        </Wrapper>
        
    )
}



// const HomeLayout = styled.div`
//   display:grid;
//   grid-template-areas: "leftsection middlesection ";
//   grid-template-column: minmax(0, 5fr) minmax(0, 12fr) ;
//   column-gap: 25px;
//   row-gap: 25px;
//   grid-template-row:auto;
//   margin: 25px 0;
//   max-width:100%;
// 
//   @media(max-width:768px){
//     display: flex;
//     flex-direction:column;
//     padding: 0 5px;
//     max-width:100%;
//   }
// `;
const HomeLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap:20px;
  justify-content: flex-start;
  margin: 20px;
  @media(max-width:768px){
    flex-direction: column;
    gap:10px;
  }
`;
// 
// const MainSection = styled.div``;

const Wrapper = styled.div`
  width: 100%;
  align-self: flex-start;
  height: 100%;
  min-height: calc(100vh - 6rem);
  background-color: #fff;
  color: #111111;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 4rem;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 60rem;
  flex-direction: column;
  margin-top: 2rem;
`;

export default Boutique
