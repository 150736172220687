 import firebase from 'firebase/app'

import { initializeApp } from 'firebase/app';

import 'firebase/firestore';
import 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCWb6YsyOPQbWnQMlNNoOIcys2aTavouaM",
    authDomain: "aram-1cfe2.firebaseapp.com",
    databaseURL: "https://aram-1cfe2.firebaseio.com",
    projectId: "aram-1cfe2",
    storageBucket: "aram-1cfe2.appspot.com",
    messagingSenderId: "360125829133",
    appId: "1:360125829133:web:966362d83da8dd9f6d8544",
    measurementId: "G-53V2B14GQX"
  };


  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
  // firebase.storage();

  export default firebase;