import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import firebase from 'firebase';
import styled from 'styled-components';
import { acceptContractClient, askModificationClient, rejectContractClient, sendContract } from "../../../Store/Actions/actionOffer";

const ContractModalClient = (props)=>{

    // attributes
    const [editorText, setEditorText]= useState("");
    const [artisanPhoneNumber, setArtisanPhoneNumber] = useState(props.firebase.profile.phone_number);
    const [materialCost, setMaterialCost] = useState(0);
    const [wage, setWage] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [estimatedVisitDate, setEstimatedVisitDate] = useState('');
    const [modificationText, setModificationText] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [acceptDisabled, setAcceptDisabled] = useState(true);
    

    // for goint to artisan'page
    const history = useHistory();
    const routeChange = ()=>{
        let path = 'artisanboutique';
        history.push({pathname: path, state:{artisanId: props.demand.contract.artisan_id}})
    }

    // functions
    const handlePhoneNumber = (e)=>{
        const artisanPhoneNumber = e.target.value;

        setArtisanPhoneNumber(artisanPhoneNumber);
    }

    //
    const handleMaterialCost = (e) => {
        e.preventDefault();
        const costInput = e.target.value;

        setMaterialCost(costInput);
        console.log(materialCost);
    }
    const handleEstimatedWage = (e) => {
        const wageInput = e.target.value;

        setWage(wageInput);
        console.log(wage);
    }

    const handleVisit = (e)=>{
        const dateInput = e.target.value;
        setEstimatedVisitDate(dateInput);
    }
    const handleModification = (e)=>{
        const textInput = e.target.value;
        setModificationText(textInput);
    }

    const handleRadioClick= ()=>{
        setDisabled(!disabled);
    }
    const handleAccpetClick= ()=>{
        setAcceptDisabled(!disabled);
    }

    const acceptContract = (e)=>{
        const demand = props.demand;
        props.acceptContract(demand);
        reset(e);
    }

    const rejectContract = (e)=>{
        const demand= props.demand;
        props.cancelContract(demand);
        reset(e);
    }

    const askModification = (e) =>{
        const modification = {
            demand_id: props.demand.demand_id,
            modification: modificationText,
        }
        props.modifyContract(modification);
        
        reset(e);
    }
    
    const reset = (e)=>{
        props.handleClick(e);
    }
    return(
        <>
        {props.showModal==='open' &&(
            <Container>
                <Content>
                    <Header>
                    <h2>Les informations sur le contrat</h2>
                        <button onClick={(event) => reset(event) }>
                            <img src="/images/close.png" alt="" />
                        </button>
                    </Header>
                    <SharedContent >
                        <div style={{cursor: 'pointer'}} >
                        <UserInfo onClick={routeChange}>
                        {props.userId && props.demand.contract.artisan_profile_image ? <img src={props.demand.contract.artisan_profile_image}/>
                        :
                        <img src="/images/user.svg" alt="" />
                        }
                        <span>{props.demand.contract.artisan_firstname}</span>
                    
                        </UserInfo>
                        </div>
                        <Editor>
                            
                        <label id='textArea'>Détails du contrat</label>
                        <textarea readOnly style={{border: '1px solid #c0a375',borderRadius: '5px', paddingLeft:'8px', fontSize : '16px'}}   placeholder='Details of your contract' autoFocus={true} >
                            {props.demand.contract.description}    
                        </textarea>        

                        {props.demand.modification && props.demand.modification.modification_reponse ?
                        <div>
                        <label id='textArea'>La réponse d'Arisan</label>
                         <textarea readOnly style={{border: '1px solid #c0a375',borderRadius: '5px', paddingLeft:'8px', fontSize : '16px'}}  autoFocus={true} >
                         {props.demand.modification.modification_reponse}    
                         </textarea>  
                         </div>
                        :
                        <div></div>
                        }                       
                       

                        <label id='phoneNumber'>Votre numéro de téléphone</label>
                        <input readOnly value={props.demand.contract.artisan_phone_number}  type="number" onChange={handlePhoneNumber} style={{border: '1px solid #c0a375',borderRadius: '5px', paddingLeft:'8px'}} 
                        />

                        <label id='materialCost'>Coût matériel €</label>
                        <input readOnly value={props.demand.contract.material_cost}  type="number" onChange={handleMaterialCost} style={{border: '1px solid #c0a375',borderRadius: '5px', paddingLeft:'8px'}} 
                        />
                        
                        <label id='materialCost'>Salaire demandé €</label>
                        <input readOnly value={props.demand.contract.wage}  type="number" onChange={handleEstimatedWage} style={{border: '1px solid #c0a375',borderRadius: '5px', paddingLeft:'8px'}} 
                        />

                        <label id='totalCost'>Coût total €</label>
                        <input readOnly value={props.demand.contract.total_cost}  type="number"  style={{border: '1px solid #c0a375',borderRadius: '5px', paddingLeft:'8px'}} 
                        />
                        
                        
                        <label readOnly id='estimatedVisitDate'>Le projet commence à</label>
                        <input value={props.demand.contract.start_date} placeholder="Begining of your project"  type="date" onChange={handleVisit} style={{border: '1px solid #c0a375',borderRadius: '5px', paddingLeft:'8px'}} 
                        />


                        {/* <ModificationButton type='submit' onClick={handleAccpetClick}> Everything is ok? </ModificationButton> */}
                        <ModificationButton  type='submit' onClick={handleRadioClick}> Une modification est nécessaire ? ou non? </ModificationButton>
                        <label  id='modificationText'>Que souhaitez-vous modifier sur le contrat ?</label>
                        <textarea disabled={disabled} value={modificationText} placeholder="To modify on the contract..."  type="text" onChange={handleModification} style={{border: '1px solid #c0a375',borderRadius: '5px', paddingLeft:'8px'}} 
                        />

                    </Editor>

                    <SharedCreation>
                        <RejectButton 
                           
                             onClick={(event)=> rejectContract(event)  }
                        >Trouvez-moi un autre artisan 
                        </RejectButton>
                        <ModificationButton 
                           disabled={disabled}
                             onClick={(event)=> askModification(event)  }
                        >Modifier
                        </ModificationButton>
                        <AcceptButton 
                            disabled={!disabled}
                            onClick={(event)=> acceptContract(event)  }
                        >J'accepte
                        </AcceptButton>
                    </SharedCreation>
                    </SharedContent>
                </Content>
            </Container>
        )}
            
        </>

    )
}



const Container = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:9999;
    color:black;
    background-color:rgba(0,0,0,0.8);
    animation: fadeIn 0.3s;
`;

const Content = styled.div`
    width:100%;
    max-width:552px;
    background-color:white;
    max-height:90%;
    overflow:initial;
    border-radius:5px;
    position: relative;
    display:flex;
    flex-direction:column;
    top:32px;
    margin: 0 auto;
`;

const Header = styled.div`
    display:block;
    padding: 16px 20px;
    border-bottom: 1px solid rgba(0,0,0, 0.15);
    font-size:16px;
    line-height: 1.5;
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items:center;
    button{
        height:40px;
        width:40px;
        min-width:auto;
        color: rgba(0,0,0,0.15);
        background-color:transparent;
        border: none;
        justify-content: center;
        cursor: pointer;
        &:hover{
            border-radius:50%;
            background-color: #aaa;
        }
        
    }
    img{
        width:40px;
        margin-bottom:3px;
    }
`;

const SharedContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow:1;
    overflow-y:auto;
    vertical-align: baseline;
    background: transparent;
    padding: 8px 12px;
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 12px;

    svg, img{
        width: 48px;
        height:48px;
        background-clip: content-box;
        border: 2px solid transparent;
        border-radius: 50%;
    }
    span{
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        margin-left: 5px;
    }
`;

const SharedCreation = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 12px 16px;
`;



const ModificationButton = styled.button`
    min-width:60px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    font-weight:bold;
    margin-right:14px;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#c0a375')};
    color: ${(props)=> (props.disabled ? "#aaaa" : '#111111')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'};
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;

const PostButton = styled.button`
    min-width:60px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    font-weight:bold;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#c0a375')};
    color: ${(props)=> (props.disabled ? "#aaaa" : '#111111')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'};
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;

const Editor = styled.div`
    padding: 12px 24px;

    textarea{
        width: 100%;
        min-height: 100px;
        resize: none;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }

    input{
        width: 100%;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }
`;


const AcceptButton = styled.button`
    min-wifth:60px;
    border-radius: 20px;
    padding-left: 16px;
    border:none;
    padding-right: 16px;
    margin-right:20px;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#007500')};
    color: ${(props)=> (props.disabled ? "#aaaa" : 'white')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'};
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;
const RejectButton = styled.button`
    min-wifth:60px;
    margin-left:20px;
    border-radius: 20px;
    border:none;
    padding-left: 8px;
    padding-right: 16px;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#D70A00')};
    color: ${(props)=> (props.disabled ? "#aaaa" : 'white')};
    cursor: pointer;
    &:hover{
        background: ${props => props.disabled ? '' : '#c0a375'};
        color: ${props => props.disabled ? '' : '#111111'}; 
        font-weight: ${props => props.disabled ? '' : 'bold'}; 
        cursor: ${props => props.disabled ? 'not-allowed' :'pointer'}
    }
`;


const mapStateToProps = (state) => ({firebase, firestore})=>{
    return{
        firebase,
        userId: firebase.auth.uid,
    }
};

const mapDispatchToProps = (dispatch)=>({
    
    acceptContract: (demand) => dispatch(acceptContractClient(demand)),
    cancelContract: (demand) => dispatch(rejectContractClient(demand)),
    modifyContract: (demand)=> dispatch(askModificationClient(demand)),

});

export default connect(mapStateToProps, mapDispatchToProps)(ContractModalClient);