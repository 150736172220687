
import 'firebase/storage';
import { getFirestore } from 'redux-firestore';
import { setLoading } from './demandActions';

// function to add the estimation of the job costs
export function addEstimation(payload){
    const db = getFirestore();

    const demandId = payload.demandId;

    return(dispatch)=>{
        dispatch(setLoading(true));
        if(payload !== ''){
            db.collection('demands').doc(payload.demandId).update(
                {
                    demand_status:'estimation_confirmed',
            }
            );
            db.collection('demands').doc(payload.demandId).update({
                estimation:{
                    artisan_id: payload.artisanId,
                    artisan_firstname : payload.artisanFirstName,
                    artisan_lastname:payload.artisanLastName,
                    artisan_email: payload.artisanEmail,
                    artisan_profile_image: payload.artisanProfileImage,
                    estimation_description: payload.descriptionOfEstimation,
                    visit_date: payload.visitDateEstimation,
                    artisan_phone_number: payload.contactNumber,
                    estimation_post_date: payload.timestamp,
                    estimation_material_cost: payload.materialCostEstim,
                    estimation_wage: payload.wageEstim,             
                   }
            });
            dispatch(setLoading(false));
        }
        alert('Trouvez votre projet assigné dans la page Mes Boulots');
        
    }
        
};


// accept the estimation

export function acceptEstimation(demand){
    const db = getFirestore();

    const demandId = demand.demand_id;

    return(dispatch)=>{
        dispatch(setLoading(true));
        if(demand !== ''){
            db.collection('demands').doc(demandId).update(
                {
                    demand_status:'estimation_confirmed',
            }
            );
            dispatch(setLoading(false));
        }
    }
};

// REJECT the estimation
export function rejectEstimation(demand){
    const db = getFirestore();

    const demandId = demand.demand_id;
    
    return(dispatch)=>{
        dispatch(setLoading(true));
        if(demand!==''){
            db.collection('demands').doc(demandId).update(
                {
                    demand_status:'unassigned',
                }
            )
        }
    }
}

// valid the visit
export function validateTheVisit(demand){
    const db = getFirestore();
    const demandID = demand.demand_id;

    return(dispatch)=>{
        dispatch(setLoading(true));
        if(demand!==''){
            db.collection('demands').doc(demandID).update({
                demand_status: 'estimation_visit_valid',
            })
        }
    }
}
// refuse the visit
export function refuseTheVisit(demand){
    const db = getFirestore();
    const demandID = demand.demand_id;
    return(dispatch)=>{
        dispatch(setLoading(true));
        if(demand!==''){
            db.collection('demands').doc(demandID).update({
                demand_status: 'unassigned',
            })
            
        }
        dispatch(setLoading(false));
    }
};

// ---------- function to send contract
export function sendContract(contract){
    const db = getFirestore();
    const demandId= contract.demandId;

    return(dispatch)=>{
        dispatch(setLoading(true));
        if(contract !== ''){
            db.collection('demands').doc(demandId).update({
                contract:{
                    artisan_id: contract.artisanId,
                    artisan_firstname : contract.artisanFirstName,
                    artisan_lastname: contract.artisanLastName,
                    artisan_email: contract.artisanEmail,
                    artisan_profile_image: contract.artisanProfileImage,
                    description: contract.descriptionOfContract,
                    start_date: contract.startDate,
                    artisan_phone_number: contract.contactNumber,
                    artisan_address: contract.artisanAddress,
                    post_date: contract.timestamp,
                    material_list: contract.materialList,
                    material_cost: contract.materialCost,
                    wage: contract.wage,
                    end_date: '',
                    total_cost: contract.totalCost,
                    
                }
            });
            db.collection('demands').doc(contract.demandId).update({
                demand_status: 'contract_offered'
            });
            dispatch(setLoading(false));
        }
    }
}

// function to accpet the contract
export function acceptContractClient(demand){
    const db = getFirestore();
    const demandId = demand.demand_id;
    
    return(dispatch)=>{
        
        dispatch(setLoading(true));
        if(demand!==''){
            db.collection('demands').doc(demandId).update(
                {
                    demand_status: 'assigned',
                }
            );
            dispatch(setLoading(false));
        }
    }
};

// function to REJECT the contract
export function rejectContractClient(demand){
    const db = getFirestore();
    const demandId = demand.demand_id;

    return(dispatch)=>{
        dispatch(setLoading(true));
        if(demand!==''){
            db.collection('demands').doc(demandId).update(
                {
                    demand_status:'unassigned',
                }
            )
        }
        dispatch(setLoading(false));
    }
}

// function to MODIFY the conract
export function askModificationClient(demand){
    const db = getFirestore();
    const demandId = demand.demand_id;

    return(dispatch)=>{
        dispatch(setLoading(true));
        if(demand!== ''){
            db.collection('demands').doc(demandId).update({
                demand_status: 'modification_asked',
            });
            db.collection('demands').doc(demandId).update({
                modification:{
                    modification : demand.modification
                }
            });
        }
        dispatch(setLoading(false));
    }
};

// ---------- function to send contract
export function sendModificaionArtisan(contract){
    const db = getFirestore();
    const demandId= contract.demandId;

    return(dispatch)=>{
        dispatch(setLoading(true));
        if(contract !== ''){
            db.collection('demands').doc(demandId).update({
                contract:{
                    artisan_id: contract.artisanId,
                    artisan_firstname : contract.artisanFirstName,
                    artisan_lastname: contract.artisanLastName,
                    artisan_email: contract.artisanEmail,
                    artisan_profile_image: contract.artisanProfileImage,
                    description: contract.descriptionOfContract,
                    start_date: contract.startDate,
                    artisan_phone_number: contract.contactNumber,
                    post_date: contract.timestamp,
                    material_cost: contract.materialCost,
                    material_list: contract.materialList,
                    wage: contract.wage,
                    end_date: '',
                    total_cost: contract.totalCost,

                },

                modification:{
                    modification: contract.modificationAsked,
                    modification_reponse: contract.modificationResponse,
                }

            });
            db.collection('demands').doc(contract.demandId).update({
                demand_status: 'contract_offered'
            });
            dispatch(setLoading(false));
        }
    }
}

// FINALIZE THE JOB
export function finalizeJobArtisan(payload){
    const db= getFirestore();
    const demandId = payload.demandId;
    return(dispatch)=>{
        dispatch(setLoading(true));
        if(payload !==''){
            db.collection('demands').doc(demandId).update({
                contract:{
                    artisan_id: payload.artisanId,
                    artisan_firstname : payload.artisanFirstName,
                    artisan_lastname: payload.artisanLastName,
                    artisan_email: payload.artisanEmail,
                    artisan_profile_image: payload.artisanProfileImage,
                    description: payload.descriptionOfContract,
                    start_date: payload.startDate,
                    end_date: payload.endDate,
                    post_date: payload.postDate,
                    artisan_phone_number: payload.contactNumber,
                    material_list: payload.materialList,
                    material_cost: payload.materialCost,
                    wage: payload.wage,
                    additional_info: payload.additionalInfo,
                    total_cost: payload.totalCost,
                    artisan_address: payload.artisanAddress,
                }
            });
            db.collection('demands').doc(demandId).update({
                demand_status: 'finished'
            });
            dispatch(setLoading(false));
        }
    }
}

// function to DELETE THE OFFER
export function deletOfferArtisanAction(demand){
    const db = getFirestore();
    const demandId = demand.demand_id;

    return(dispatch)=>{
        dispatch(setLoading(true));
        if(demand!== ''){
            db.collection('demands').doc(demandId).update({
                demand_status: 'unassigned',
            });
            db.collection('demands').doc(demandId).update({
                estimation:{

                },
                contract:{

                }, 
                modification:{
                    
                }
            }
            )
            dispatch(setLoading(false));
        }
    }
}
        
// function to Confirm The payment
export function paymentConfirmationAPI(demand){
    const db = getFirestore();
    const demandId = demand.demand_id;

    return(dispatch)=>{
        dispatch(setLoading(true));
        if(demand!== ''){
            db.collection('demands').doc(demandId).update({
                demand_status: 'paid',
            });
            dispatch(setLoading(false));
        }
    }
}

//function to add review
export function reviewArtisanAPI(review){
    const db= getFirestore();
    const demandId= review.demandId;
    const artisanId = review.artisanId;
    const ratingValue = review.rating;

    return(dispatch)=>{
    dispatch(setLoading(true));
     db.collection('users').doc(artisanId).get().then(snapshot =>{
         
        const totalNumJobs = snapshot.data().total_num_jobs;
        const artisanRating = snapshot.data().artisan_rating;

            if(review!== ''){
                db.collection('demands').doc(demandId).update({
                    demand_status: 'reviewed',

                    review:{
                        review_text: review.reviewText,
                        rating:review.rating,
                    }
                });
                // to re-calculate the rating of the artisan and add to the total new number of jobs
                db.collection('users').doc(artisanId).update({
                    total_num_jobs: totalNumJobs + 1,
                    artisan_rating: (artisanRating + ratingValue ) / (totalNumJobs +1),
                })
            }
            dispatch(setLoading(false));
     }
     )};

    
}