import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Yup from 'yup';
import { getDemandsForArtisanAPI , deleteDemand, getDemandsAPI } from "../../Store/Actions/demandActions";

import ClientPreviousProjectModal from "./ClientPreviousProjectModal";
import Loader from "../../Components/UI/Loader/Loader";


const MainProjects = (props)=>{

    useEffect(()=> {
        props.getDemands()
    }, []);

    const clientId= props.firebase.auth.uid;

    const [demandToSee, setDemandToSee] =useState('');
    const [showModal, setShowModal] =useState('close')

    

    const handleJobModal = (e)=>{
        e.preventDefault();

        switch(showModal){
            case "open":
                setShowModal('close');
                break;

            case "close":
                setShowModal('open');
                break;

                default:
                    setShowModal('close');
                    break;
        }
}

    return(
    <>
                    <Gallery>
                        {props.loading && 
                        <Loader/>
                        }
                        {console.log(props.loading)}

                        {props.userDemands.length > 0 && props.userDemands.map((eachDemand, key)=>(
                        eachDemand.contract && eachDemand.user_id === clientId & eachDemand.demand_status ==='reviewed'?

                        <GalleryItem key={key} >
                            
                            <GalleryImage 
                                src={eachDemand.demand_image[0]}>
                            </GalleryImage>
                            
                            {eachDemand.review&& eachDemand.review.rating?
                            <div onClick={handleJobModal}>
                             <GalleryItemInfo  onClick={()=>setDemandToSee(eachDemand)}>
                             <ul >
                                 <GalleryItemStat ><span>score:</span><i className="fas fa-star" aria-hidden="true"></i>{eachDemand.review.rating}/5</GalleryItemStat>
                             
                             </ul>
                         </GalleryItemInfo>
                         </div>
                            :
                                null
                            }       
                        </GalleryItem>
                        :
                        null
                        ))}
                    </Gallery>
                
                <ClientPreviousProjectModal showModal={showModal} handleClick = {handleJobModal} demand={demandToSee}/>
        
    </>
    )
}


const Gallery = styled.div`    
width:60%;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
justify-content: center;
margin-left:auto;
margin-right:auto;
grid-gap: 3px;
@media(max-width:768px){
    
border-top: 0.1rem solid #dadada;
    width:100%;
    grid-auto-rows: 120px;
    grid-gap: 1px;
    margin-left:5px;
    margin-right:5px;
}


    `;

const GalleryItemInfo = styled.div`
    display: none;
    li{
        display: inline-block;
        font-size: 1.7rem;
        font-weight: 600;
    }
`;

const GalleryItem =styled.div`
    
    margin-top: 2px;
    position: relative;
    width:100%;
    cursor: pointer;
    height:100%;
    max-height: 300px;
    max-width: 300px;

    &:hover {
        ${GalleryItemInfo} {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius:5px;
            color:#fff;

          }
    }

    &:focus {
        ${GalleryItemInfo} {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            color:#fff;
        }
        
    }
    @media(max-width:1000px){
        max-height: 100%;
        max-width: 100%;
    }
`;


const GalleryItemStat = styled.li`
    display: inline-block;
    font-size: 1.7rem;
    font-weight: 600;  

    span{
        margin-right: 2.2rem;
    }
`;


const GalleryImage = styled.img`     
    width: 100%;
    height: 300px;
    display: block;
    object-fit: cover; 
    border-radius: 5px;
    border: solid #aaa ;
    border-width:1px;
    @media(max-width:850px){
        height:120px;
        
    }
`;

const mapStateToProps = (state) =>({firebase, firestore, demands})=>{
    return{
        firebase,
        userId: firebase.auth.uid,
        loading: demands.loading,
        userDemands: demands.demands,     
        error: demands.deleteDemand.error,
        loadingDemandDelete: demands.deleteDemand.loading,
    }
};

const mapDispatchToProps = (dispatch)=>({
    // get all the demands that are reviewed and related to the artisan
    getDemands: ()=> dispatch(getDemandsAPI()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainProjects);