import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from "styled-components";
import { firestoreConnect } from 'react-redux-firebase';

import Heading from '../../Components/UI/Headings/Heading';
import { Container } from '../../Hoc/elements';
import LeftJobSection from './LeftJobSection/LeftJobSection';
import MainJobSection from './MainJobSection/MainJobSection';
import RightSection from './RightJobSection/RightSection';

function Jobs() {
    return (
        <Wrapper>
            <Container>
                <HomeLayout>
                    <LeftJobSection/>
                    <MiddleCol>
                        <InnerWrapper>
                        <Heading noMargin size="h1" color="black">
                        Vos projets en cours !
                        </Heading>
                        <Heading bold size="h4" color="black">
                        Gérez vos devis !
                        </Heading>
                        </InnerWrapper>
                        <MainJobSection/>
                    </MiddleCol>
                    <RightSection/>
                </HomeLayout>
            </Container>
        </Wrapper>
        
    )
}



const Wrapper = styled.div`
  width: 100%;
  align-self: flex-start;
  height: 100%;
  min-height: calc(100vh - 6rem);
  background-color: #fff;
  color: #111111;
  @media(min-width:768px){
    margin: 36px;
  }
`;

const HomeLayout = styled.div`
display: grid;
grid-template-areas: "leftsection main rightsection";
grid-template-column: minmax(0, 5fr) minmax(0, 12fr) minmax(300px, 5fr);
column-gap: 25px;
row-gap: 25px;
grid-template-row:auto;
margin: 25px 0;
max-width:100%;

@media(max-width:768px){
  display: flex;
  flex-direction:column;
  padding: 0 5px;
  max-width:100%;
  margin-bottom:96px;
}
`;

const MiddleCol = styled.div``;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 4rem;
  width:100%;
`;

export default Jobs
