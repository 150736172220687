import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import styled from 'styled-components';

import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useEffect } from "react";

const DevisModalPreview = (props)=>{
   // Going to devis page
    const history = useHistory();
    const routeChangeDevis = ()=>{
        let path = 'devis';
        history.push({pathname : path, state:{demandToPrint: props.demand}});
    }

    // check artisan page
    const routeToArtisanPage = () =>{
        const pathArtisan = 'artisanboutique';
        history.push({pathname : pathArtisan, state:{artisanId: props.demand.estimation.artisan_id}});
    }
    //check client page
    const routeToClientPage = () =>{
        const clientPath = 'clientprofile';
        history.push({pathname : clientPath, state:{clientId: props.demand.user_id}});
    }

    const reset = (e) =>{
        props.handleClick(e);
    }

    const [materialList, setMaterialList] =useState([]);
    const [wage, setWage] = useState();

    //getting the materials list
    // useEffect(()=>{
    //     setMaterialList( props.demand.contract.material_list.map((eachMaterial, index)=>
    //     ({
    //         id: index,
    //         materialName: eachMaterial.material_name,
    //         materialPrice: eachMaterial.material_price, 
    //         materialUnit: eachMaterial.material_quantity
    //     })),[])
    // })  ;
    // //getting wage
    // useEffect(()=> {
    //     setWage(props.demand.contract.wage);
    // },0)

// 
//     const totalMaterialCost = materialList ? materialList.reduce((a, c) => (a+c.materialUnit * c.materialPrice), 0):null;
// 
//     const totalCostToPay = parseFloat(totalMaterialCost) + parseFloat(wage);

    return(
        <>
        {props.showModal === 'open' && (
            <Container>
            <Content>
                
                <Article >
                    
                            <SharedActor>
                            <button onClick={(event) => reset(event) }>
                              <img style={{width:'40px' , marginTop:'2px'}} src="/images/close.png" alt="" />
                            </button>
                            
                            <Header onClick={routeToClientPage}>
                            <a >
                                {props.demand.client_profile_image ? 
                                <img src={props.demand.client_profile_image} />
                                :
                                <img src='/images/user.svg'/>
                                }
                                <div>
                                    {props.demand.user_firstname ? 
                                    <span>{props.demand.user_firstname}</span> :
                                    <span></span>
                                    }
                                    
                                    <span>{props.demand.date.toDate().toLocaleDateString()}</span>
                                </div>
                            </a>
                            
                            </Header>
                            <Header onClick={routeToArtisanPage}>
                                <a>
                                {props.demand.estimation.artisan_profile_image?
                                <img src={props.demand.estimation.artisan_profile_image}/>
                                :
                                <img src='/images/user.svg'/>}
                                <div>
                                    {props.demand.estimation.artisan_firstname &&  
                                    <span>{props.demand.estimation.artisan_firstname}</span> }
                                </div>
                            </a>
                            
                            </Header>
                            
                                 
                        </SharedActor>

                        <Editor>
                            <label id='contractDetails'>Description de la demande : </label>
                            {props.demand.description ?
                            <textarea style={{fontSize : '16px'}} value={props.demand.description} ></textarea> 
                            :null}   
                            
                            <label id='artisanDetails'>Description du problème par l'artisan</label>
                            {props.demand.contract && props.demand.contract.description ?
                            <textarea value={props.demand.contract.description} ></textarea> 
                            :
                            props.demand.estimation.description ?
                            <textarea value={props.demand.estimation.description} ></textarea> 
                            :<textarea  >L'artisan n'a pas encore fait de commentaire </textarea>}             

                            {/* <ContractDetails> 

                            <Table>
                                <table>
                                <thead>
                                    <th >Détails</th>
                                    <th>Quantité</th>
                                    <th >Prix ​​unitaire</th>
                                    <th >Totale €</th>
                                </thead>
                                <tbody>
                                {props.demand.contract.material_list?  props.demand.contract.material_list.map((eachMaterial, key)=> (                            
                                    <tr key={key}>
                                    <td >Matériaux utilisés: {eachMaterial.material_name}</td>
                                    <td>{eachMaterial.material_quantity}</td>
                                    <td>{eachMaterial.material_price}</td>
                                    <td>{eachMaterial.material_price* eachMaterial.material_quantity}</td>
                                    </tr>
                                ))
                                :null}
                                </tbody>
                                
                                <tbody>
                                    <tr>
                                    <td>Wage: </td>
                                    <td>---</td>
                                    <td>---</td>
                                    <td>{props.demand.contract.wage}</td>
                                    </tr>
                                </tbody>
                                </table>   
                            </Table>

                            </ContractDetails> */}
                                                
                        

                            {/* <label style={{fontWeight:'600'}} id='materialCost'>Total Cost: €</label>
                            
                            <input style={{fontWeight:'600', padding:'5px'}} value={totalCostToPay}  /> */}
                            
                            <h4>Projet mis en ligne le :   {props.demand.date.toDate().toLocaleDateString()}</h4>
                            { props.demand.contract && props.demand.contract.end_date ? 
                            <h4>Projet terminé à {props.demand.contract.end_date.toDate().toLocaleDateString()}  </h4>
                            :
                            <h4>Le projet n'est pas encore terminé</h4>
                             }
                            
                        </Editor>
                                                
                      
                  {props.demand.contract ?
                  <PostButton onClick={routeChangeDevis} > DEVIS </PostButton>
                  :
                  <h5>Le devis n'est pas encore prêt</h5>
                  }
                  
                       
                        
                    </Article>
                
            </Content>
        </Container>
        )}
        </>
    )
}

const Container = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height:100%;
    transform: translate(-50%, -50%);
    bottom:0;
    z-index:9999;
    color:black;
    background-color:rgba(0,0,0,0.8);
    animation: fadeIn 0.7s;
`;

const CommonCard= styled.div`
    text-align: center;
    overflow:hidden;
    margin-bottom: 8px;
    background-color: #fff;
    border-radius:5px;
    position:relative;
    box-shadow: 0 0 0 1px rgba(0 0 0 / 15%), 0 0 0 rgba(0 0 0 /20%);
    border: none;

`;

const Article = styled(CommonCard)`
    padding: 0;
    margin: 0 0 8px;
    overflow:visible;
`;

const SharedActor = styled.div`
    padding-right: 40px;
    flex-wrap:nowrap;
    padding: 12px 16px 0;
    margin-bottom: 8px;
    align-items:center;
    display:flex;
    
    a{
        margin-right:12px;
        flex-grow:1;
        overflow:hidden;
        display:flex;
        text-decoration:none;

        img{
            width:48px;
            height:48px;
            border-radius:50%;

        }
        &>div{
            display:flex;
            flex-direction:column;
            flex-grow:1;
            flex-basis:0;
            margin-left: 8px;
            overflow:hidden;
            span{
                text-align:left;
                &:first-child{
                    font-size: 14px;
                    font-weight:700;
                    color: rgba(0,0,0,1);
                }
                &:nth-child(n+1){
                    font-size:12px;
                    color:rgba(0,0,0,0.6);

                }
            }
        }
    }

    button{
        position: absolute;
        right:12px;
        top:10px;
        background:transparent;
        border:none;
        outline:none;
        cursor:pointer;
        img{
            width:18px;
        }
    }
`;

const Description =  styled.div`
    padding : 0 16px;
    overflow: hidden;
    color: rgba(0,0,0,0.9);
    font-size:14px;
    text-align:left;
`;
const Content = styled.div`
    width:100%;
    max-width:552px;
    background-color:white;
    max-height:90%;
    overflow:initial;
    border-radius:5px;
    position: relative;
    display:flex;
    flex-direction:column;
    top:32px;
    margin: 0 auto;
`;
const PostButton = styled.button`
    min-width:60px;
    border-radius: 20px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom:10px;
    margin-top:10px;
    font-weight:bold;
    background: ${(props) => ( props.disabled ? "rgba(0,0,0,0.8)" : '#c0a375')};
    color: ${(props)=> (props.disabled ? "#aaaa" : '#111111')};
    cursor: pointer;
    &:hover{
        background-color:transparent;
    }
`;

const Header = styled.div`
    display:block;
    padding: 16px 20px;
    font-size:16px;
    line-height: 1.5;
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items:center;
    @media(max-width: 568px){
        
    padding: 45px 20px;
    margin-left:-10px;
    }
    button{
        height:40px;
        width:40px;
        min-width:auto;
        color: rgba(0,0,0,0.15);
        background-color:transparent;
        border: none;
        justify-content: center;
        cursor: pointer;
        &:hover{
            border-radius:50%;
            background-color: #aaa;
        }
        
    }
    img{
        width:40px;
        margin-bottom:3px;
    }
`;

const Users = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    text-align: left; 
    justify-content: space-around;
    margin-bottom:10px;
    border-bottom: 1px solid rgba(0,0,0, 0.15);
    padding-bottom: 10px;
`;



const ArtisanInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 12px;

    svg, img{
        width: 48px;
        height:48px;
        background-clip: content-box;
        border: 2px solid transparent;
        border-radius: 50%;
    }
    span{
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        margin-left: 5px;
    }
`;



const Editor = styled.div`
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right:auto;
    width:80%;

    textarea{
        width: 100%;
        min-height: 100px;
        resize: none;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }

    input{
        width: 100%;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
    }
`;

const ContractDetails =styled.div`
    display:flex;
    justify-content:center;
    width:100%;
`;

const Table = styled.div`
    display:flex;
    table-layout: fixed;
    width: 100%;
    align-items: stretch;
    justify-content: center;
    align-items: center;
    border-collapse:collapse;
    border-spacing:0;
    padding:20px;
    
    td{
        border-color:black;
        border-style:solid;
        border-width:1px;
        border-radius:5px;
        overflow:hidden;
        padding:10px 5px;
        word-break:normal;
    }
    th{
        border-color:black;
        border-style:solid;
        border-width:1px;
        font-weight:normal;
        overflow:hidden;
        padding:10px 5px;
        word-break:normal;
        border-radius:5px;
        font-weight:bold;
        text-align:center;
        vertical-align:top
    }
    @media(max-width: 767px) {
        td {width: auto !important;
            width: auto !important;
            overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}
`;




const mapStateToProps = (state)=>({firebase, firestore})=>{
    return{
        firebase,
        userId: firebase.auth.uid,
    }
};

const mapDispatchToProps = (dispatch)=>({

});

export default connect(mapStateToProps, mapDispatchToProps)(DevisModalPreview);