import React, {useEffect} from 'react';
import { Route, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import { FormWrapper, StyledForm } from '../../../Hoc/elements';
import Input from '../../../Components/UI/Forms/Inputs/Input';
import Button from '../../../Components/UI/Forms/Button/Button';
import Heading from '../../../Components/UI/Headings/Heading';

import * as actions from '../../../Store/Actions';
import Message from '../../../Components/UI/Messages/Message';

const MessageWrapper = styled.div`
  position: absolute;
  bottom: 0;
`;


const SignUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Votre prénom est requis.')
    .min(3, 'Trop court.')
    .max(25, 'Trop long.'),
  lastName: Yup.string()
    .required('Votre nom de famille est requis.')
    .min(3, 'Trop court.')
    .max(25, 'Trop long.'),
  email: Yup.string()
    .email('Email invalide.')
    .required("L'e-mail est requis."),
  password: Yup.string().required('Le mot de passe est requis.').min(8, 'Le mot de passe est trop court.'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], `Le mot de passe ne correspond pas`)
    .required("Vous devez confirmer votre mot de passe."),
});

const SignUp = ({signUp, loading, error, cleanup}) => {


  const history = useHistory();
    const artisanRouteChange = ()=>{
        let path = '/registerartisan';
        history.push(path);
    }

  const loginRouteChange = ()=>{
    let path = '/login';
    history.push(path)
  }

  useEffect(() => {
    return () => {
      cleanup();
    }
  }, [cleanup])

  return ( 
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
      }}
      validationSchema={SignUpSchema}
      onSubmit={async (values, { setSubmitting }) => {
        console.log(values);
        await signUp(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid }) => (
        <FormWrapper>
          <Heading noMargin size="h1" color="white">
          Créer un compte <Heading noMargin size="h1" color="gold">client</Heading>
          </Heading>
         
          <Heading bold size="h4" color="white">
            <a style={{textDecoration:'underline', textDecorationColor: '#c0a375'}} onClick= {artisanRouteChange}>
            Vous êtes artisan ? Cliquez ici!
            </a>
            
          </Heading>
          <StyledForm>
            <Field
              type="text"
              name="firstName"
              placeholder="Votre prénom..."
              component={Input}
            />
            <Field
              type="text"
              name="lastName"
              placeholder="Votre nom..."
              component={Input}
            />
            <Field
              type="email"
              name="email"
              placeholder="Votre email..."
              component={Input}
            />
            <Field
              type="password"
              name="password"
              placeholder="Votre mot de passe..."
              component={Input}
            />
            <Field
              type="password"
              name="confirmPassword"
              placeholder="Retaper votre mot de passe..."
              component={Input}
            />
            <Button disabled={!isValid || isSubmitting} loading= {loading? "S'enregistrer...": null} type="submit">
            S'inscrire
            </Button>
            <Heading style={{cursor:'pointer'}}  bold size="h4" color="white">
              <a style={{textDecoration:'underline', textDecorationColor: '#c0a375'}} onClick={loginRouteChange}>
              Vous avez déjà un compte? La Connexion!
            </a>
           </Heading>  
            <MessageWrapper><Message error show={error}>{error}</Message></MessageWrapper>
          </StyledForm>
        </FormWrapper>
      )}
    </Formik>
  );
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  error: auth.error,
});

const mapDispatchToProps = {
  signUp: actions.signUp,
  cleanup: actions.cleanMessage
  
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
     )(SignUp);