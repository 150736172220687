import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from "styled-components";
import { firestoreConnect } from 'react-redux-firebase';

import Heading from '../../Components/UI/Headings/Heading';
import { Container } from '../../Hoc/elements';
import LeftJobSection from './LeftJobSection/LeftJobSection';
import NewJobSection from './NewJobSection/NewJobSection';
import RightSection from './RightJobSection/RightSection';
import MainJobSection from './MainJobSection/MainJobSection';

function NewJobs(){
    return(
        <Wrapper>
            <Container>
                <HomeLayout>
                    <LeftJobSection/>
                    <MiddleCol>
                    <InnerWrapper>
                    <Heading noMargin size="h1" color="black">
                    Trouver un nouveau projet
                    </Heading>
                    <Heading bold size="h4" color="black">
                    Envoyez vos estimations
                    </Heading>
                    </InnerWrapper>
                    <NewJobSection/>
                    </MiddleCol>
                    <RightSection/>
                </HomeLayout>
            </Container>
        </Wrapper>
    )
}


const Wrapper = styled.div`
  width: 100%;
  align-self: flex-start;
  height: 100%;
  min-height: calc(100vh - 6rem);
  background-color: #fff;
  color: #111111;
  @media(min-width:768px){
    margin: 36px;
  }
`;

const HomeLayout = styled.div`
display: grid;
grid-template-areas: "leftsection main rightsection";
grid-template-column: minmax(0, 5fr) minmax(0, 12fr) minmax(300px, 5fr);
column-gap: 25px;
row-gap: 25px;
grid-template-row:auto;
margin: 25px 0;
max-width:100%;

@media(max-width:768px){
  display: flex;
  flex-direction:column;
  margin: 0 0;
  padding: 0 5px;
  max-width:100%;
  margin-bottom:96px;
}
`;

const MiddleCol = styled.div``;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem 4rem;
  width:100%;
  margin-top:20px;
`;
export default NewJobs