import * as actions from './actionTypes';
import { SET_LOADING_STATUS, GET_DEMANDS } from './actionTypes';
import firebase from '../../Firebase/Firebase';
import 'firebase/storage';
import { getFirestore } from 'redux-firestore';


        // --------------------     Functions to add DEMANDS --------------------

// Add a DEmand function
export const addDemand = (data) => async (
    dispatch,
    getState,
    { getFirestore, getFirebase }
  ) => {
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;
    dispatch({ type: actions.ADD_DEMAND_START });
    try {
      const res = await firestore
        .collection('user_demands')
        .doc(userId)
        .get();
      const newDemand = {
        id: new Date().valueOf(),
        demand: data.demand,
        demand_type: data.demandType,
        demand_status: 'Unassigned',
        demand_location: data.demandLocation,
        timestamp_registered:  Date(2* 3600),
        demand_image: data.demandImage,
      };
      if (!res.data()) {
        firestore
          .collection('user_demands')
          .doc(userId)
          .set({
            demands: [newDemand],
          });
      } else {
        firestore
          .collection('user_demands')
          .doc(userId)
          .update({
            demands: [...res.data().demands, newDemand],
          });
      }
      
      dispatch({ type: actions.ADD_DEMAND_SUCCESS });
      return true;
    } catch (err) {
      dispatch({ type: actions.ADD_DEMAND_FAIL, payload: err.message });
    }
  };

  //DELETE DEMAND
  export const deleteDemand = demand_id => async(dispatch, getState, {getFirestore})=>{
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid; 
  
       dispatch({ type: actions.DELETE_DEMAND_START });
    try{
      
      await firestore.collection('demands').doc(demand_id).delete();
  
      dispatch({ type: actions.DELETE_DEMAND_SUCCESS });
    }catch(err){
      dispatch({ type: actions.DELETE_DEMAND_FAIL, payload: err.message });
    }
  };

  // ---------------- adding the demands to db
  export const setLoading = (status) => ({
    type: SET_LOADING_STATUS,
    status: status,
}) ;



  //--------multi image upload
  export function addDemandToDbAPI(payload){

    const storage= firebase.storage();
    const db = getFirestore();
    const userId = payload.clientId; 

    return(dispatch)=>{
      dispatch(setLoading(true));
      
      if(payload !== ''){

        const promises = payload.demandImages.map((demandImg)=>{
          const ref = storage.ref().child(`demandImages/${demandImg.name}`)
          return ref.put(demandImg)
          .then(()=>ref.getDownloadURL())
        });
        const demandId = db.collection('demands').doc().id
        Promise.all(promises)
        .then((fileDownloadURLs)=>{
          db.collection('demands').doc(demandId).set({
            demand_id:demandId,
            user_id: payload.clientId,
            user_email_address: payload.userEmail,
            user_firstname: payload.userFirstName,
            user_lastname: payload.userLastName,
            client_profile_image: payload.clientProfileImage,
            date: payload.timestamp,
            demand_status: 'unassigned',
            
            demand_image: fileDownloadURLs,
            description: payload.description,
            demand_type: payload.demandType,
            client_phone_number: payload.contactNumber,
            demand_address: payload.address,
            client_address: payload.clientAddress,
            demand_region: payload.demandRegion,
          });

          dispatch(setLoading(false)); 
         ;
        });
      }      
    }    
  }


  //--- getting demand posts
  export const getDemands = (payload) =>({
    type: GET_DEMANDS,
    payload: payload,
})

export function getDemandsAPI() {

  const storage= firebase.storage();
  const db = getFirestore();
  const userId= firebase.auth().currentUser.uid;

  return (dispatch) => {
    dispatch(setLoading(true));
    let payload;
  
// I needed to create an index in the database by using the link given in the error of the console
    const demandsRef= db.collectionGroup('demands').where("user_id","==", userId).orderBy("date", 'desc' );
    
    demandsRef.onSnapshot((snapshot)=>{
      
      payload= snapshot.docs.map((doc)=> doc.data());
     
      dispatch(getDemands(payload));
      
      dispatch(setLoading(false));
    });
  }
};

export const getDemandsForArtisanAPI = async(dispatch, getState,{getFirestore}) => {
  const db = getFirestore();
  const artisanSpeciality = getState().firebase.profile.speciality;
  const artisanRegistrationStatus = getState().firebase.profile.registration_status;
  const artisanRegion  = getState().firebase.profile.artisan_region;


  if(artisanRegistrationStatus === 'valid'){
  dispatch(setLoading(true));
    try{
      await 
      console.log(artisanSpeciality, artisanRegistrationStatus, artisanRegion);
      let payload;
      const demandsRef = db.collectionGroup('demands').where('demand_type', "==", artisanSpeciality).where('demand_region' , '==', artisanRegion ).orderBy('date', 'desc');

       demandsRef.onSnapshot((snapshot)=>{
              
              payload = snapshot.docs.map((doc)=> doc.data());
              dispatch(getDemands(payload));
              dispatch(setLoading(false));
            });
    }catch(err){
              dispatch(console.log(err.message))
    }
  }
  
  
}

