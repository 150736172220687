import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import firebase from '../Firebase/Firebase';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import {createFirestoreInstance  , reduxFirestore, getFirestore } from 'redux-firestore';


import rootReducer from './Reducers';


// react-redux-firebase config
const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
    attachAuthIsReady: true,
  };

// react-redux config from the source code
const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const store = createStore(
  rootReducer,
 composeEnhancers(
  reactReduxFirebase(firebase, rrfConfig),
   reduxFirestore(firebase),
   applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore})) 
   )
 );

export default store;