import React from "react";
import StripeCheckout from "react-stripe-checkout";
import styled from "styled-components";
import Tooltip from '@mui/material/Tooltip';
import { connect } from "react-redux";
import axios from "axios";

import ButtonStyleForStripe from '../../UI/Forms/Button/ButtonStyleForStripe';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { paymentConfirmationAPI } from "../../../Store/Actions/actionOffer";

// help code : 391-030  I say 475-061

const StripeCheckoutButton = ( { price, onTop, demand, paymentConfirm } ) => {
    const priceForStripe = price * 100;
    // const publishableKey = 'pk_test_51KWHTILK8PZJEFX3EIZPqnuTvSc5PFItwYMO5uGiFfiEtMH9RLXo4YZcYrkdF1NWP7p0KVEqCPkF3KVgpt0SppKQ00QCFjQnsQ';
    const publishableKey = 'pk_live_51KWHTILK8PZJEFX3gLmXPnNKdoQRTIf3j08e0Rjq2JO9YP2Q62dqD5miCMrE1ljLnA4xAXCNusCewCcvaDbAntw000UzZVKzZC';
    

    //onToken function
    const onToken = async token =>  {
       await axios({
          url: 'payment',
          method: 'post',
          data: {
            amount: priceForStripe,
            token: token
          }
        })
          .then(response => {
            alert('Paiement réussi');
            paymentConfirm(demand);
            console.log('a new payment made')
          })
          .catch(error => {
            console.log('Payment Error: ', error);
            alert(
              "Il y a eu un problème avec votre paiement ! Veuillez vous assurer d'utiliser la carte de crédit fournie."
            );
          });
      };

    return (
        <StripeCheckout
            label="Pay Now"
            name="lepetitboulot"
            billingAddress
            description={`Votre total à payer est de ${price} €`}
            amount={priceForStripe}
            currency='EUR'
            panelLabel="Payer"
            token={onToken}
            stripeKey= {publishableKey}
        >
            {onTop == 'false' ?
             <PayButton 
                 color='payment' 
                 title='Payment'>
             <h5>Payment </h5>
             <Tooltip title='Payment'>
                 <CreditScoreIcon  ></CreditScoreIcon>
            </Tooltip>
                 
             </PayButton>
             :
             <Tooltip title='Payment'>
             <CreditScoreIcon  ></CreditScoreIcon>
             </Tooltip>
            }
           
        </StripeCheckout>
    );
};

const PayButton = styled(ButtonStyleForStripe)`
    display: flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    padding: 0.5rem 3rem;
    h5{
        margin-right:5px;
        margin-top:7px
    }
    

`;

const mapStateToProps = ({demands}) => ({
    error: demands.deleteDemand.error,
    loading: demands.deleteDemand.loading
});

const mapDispatchToProps = (dispatch)=>({
    paymentConfirm : (demand) => dispatch(paymentConfirmationAPI(demand)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StripeCheckoutButton) ;