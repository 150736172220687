import React from 'react';
import { connect } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';

import honey from '../../img/honey.jpeg';
import sina from '../../img/sina.jpg';
import './Landing.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as actions from '../../Store/Actions';




function Landing({userType, logout}) {

    //---- to navigate to signup
    const history = useHistory();
    
    const routeChange = () =>{  
        if(userType==='Artisan'){
            logout();
            let path = `/signup`;
            history.push(path);
                
        }else{
    
        let path = `/signup`;
        history.push(path);
        }
    }
    //------------------

    const artisanRouteChange = ()=>{
        if(userType ==='Client'){
            let path = '/registerartisan';
            history.push(path);
            logout();
            
        }else{
            let path = '/registerartisan';
            history.push(path);
        }
    }

    return (
    <div style={{width:'100%'}}>
        <div className="hero">
            <div className="content">
                <h1>
                Vous avez besoin d'un artisan professionnel? 
                </h1> <br />
                
                <div>
                    <button 
                    className="btn btn-outline-dark btn-lg" 
                    onClick= {routeChange}
                    >Vous avez besoin d'un devis? Inscrivez-vous!</button>
                    <br />
            
                    <br />
                    <button 
                    className="btn btn-outline-dark btn-lg" 
                    onClick= {artisanRouteChange}
                    >Vous êtes un artisan? Trouvez un projet!</button>
                    <br />
                </div>
            
                <div className="meet stagger1">
                 {/* <div className="finger">
                     <span>👇</span>
                    <p >Découvrez nos projets</p>
                 </div> */}
                   
                </div>
                <svg className="scroll stagger1" width="40" height="77" viewBox="0 0 40 77">
                    <g id="scroll" transform="translate(-253 -787)">
                    <g id="Rectangle_12" data-name="Rectangle 12" transform="translate(253 787)" fill="none" stroke="#111111" stroke-width="4">
                        <rect width="40" height="77" rx="20" stroke="none"/>
                        <rect x="2" y="2" width="36" height="73" rx="18" fill="none"/>
                    </g>
                    <circle className="circle" id="Ellipse_1" data-name="Ellipse 1" cx="11" cy="11" r="11" transform="translate(262 798)" fill="#111111"/>
                    </g>
                </svg>
            </div>
            <div className="hero-design">
                <img   src='./images/landing-home-image.jpg' alt="" />
            </div>
        </div>

        

        {/*  sections */}
        <section className="skills">
                <div className="skills-container">
                    <ul className='skillBox' >
                        <li >
                            <div className="icon-container one">
                                <i className="far fa-paper-plane"></i>
                            </div>
                            <p class="skill-title">Envoyez votre demande</p>
                                <p class="featured-desc skill-desc">En utilisant notre application web vous pouvez nous envoyer votre petit boulot</p>
                        </li>
                    </ul>
                    <ul className='skillBox'> 
                        <li >
                            <div className="icon-container one">
                                <i class="far fa-hand-pointer"></i>
                            </div>
                            <p class="skill-title">Sélectionnez votre artisan</p>
                                <p class="featured-desc skill-desc">Recevez et sélectionnez les meilleures offres en ligne</p>
                        </li>
                    </ul>
                    <ul className='skillBox'>
                        <li >
                            <div className="icon-container one">
                                <i class="fas fa-tools"></i>
                            </div>
                            <p class="skill-title">Recevez votre service</p>
                                <p class="featured-desc skill-desc">Votre artisan sélectionné viendra réparer votre petit boulot</p>
                        </li>
                    </ul>
                </div>
            </section>

            
            {/* vission section */}

            <section className="p-5 text-dark">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md">
                            <img className="img-fluid" src="https://images.unsplash.com/photo-1474631245212-32dc3c8310c6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=924&q=80" alt="" />
                        </div>
                        <div className="col-md p-5">
                            <h2>Notre vision</h2>
                            <h3 className="lead">Vos réparations quotidiennes ne doivent pas vous stresser.</h3>
                            <p> Le Petit Boulot est créé pour connecter tout le monde avec les services dont ils ont besoin. Lorsque les gens sont confrontés à un problème chez eux, ce n'est pas le problème lui-même qui les frustre, c'est le défi de trouver quelqu'un qui peut le résoudre.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* Mission */}
            <section className="bg-white text-dark">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md p5">
                        <h2>Notre Mission</h2>
                        <p className="lead">
                        En utilisant nos applications vous pouvez simplement nous indiquer votre petit boulot et nous confierons votre problème aux artisans près de chez vous qui pourront les résoudre. Vous venez de vous asseoir et de vous occuper des choses importantes de votre vie. Au fait, l'utilisation de notre application est gratuite.
                        </p>
                        </div>
                        <div className="col-md">
                            <img src="https://images.unsplash.com/photo-1455849318743-b2233052fcff?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        {/* ourteam */}
        {/* <section id='team' className='pb team'>
            <div className='container'>
            <h2 class="text-center text-white">Notre équipe familiale</h2>
            <p class="lead text-center text-white mb-5">Dans tout ce que nous faisons, nous croyons qu'il est important d'éliminer le stress inutile et de simplifier nos vies. Pour ce faire, nous supprimons les complications pour résoudre nos problèmes quotidiens en optimisant les processus de travail. Nous ne sommes pas parfaits, mais nous visons l'excellence. Il semble que Le Petit Boulot suive cette vision. Rejoignez-nous!</p>

            <div className="row">
                <div className='col-md-6 col-lg-3'>
                    <div className="card bg-white">
                        <div className="card-body text-success text-center">
                        
                            <img src='https://pbs.twimg.com/media/EJLLOzcWkAAkwfv.jpg'
                            className="rounded-circle mb-3"></img>
                            <h4 className="card-title mb-3">Dr. Sina NAMAKI ARAGHI</h4>
                            <p className="card-text">Fondateur et directeur de lepetitboulot.</p>
                            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sinanamakiaragh"><i className="fab fa-twitter"></i></a>
                            <a target="_blank" rel="noopener noreferrer" href="https://fr.linkedin.com/in/sina-namaki-araghi"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                        
                    </div>
                </div> */}
{/* second team member */}
                {/* <div className="col-md-6 col-lg-3">
                     <div className="card bg-white">
                        <div className="card-body text-success text-center">
                            <img src={honey}
                            className="rounded-circle mb-3"></img>
                            <h4 className="card-title mb-3">Honey NAMAKI ARAGHI</h4>
                            <p className="card-text">En charge de la gestion des activités des artisans</p>
                            
                        </div>
                     </div>
                </div>
            </div>
            
            </div>
        </section> */}

        {/* contact */}

        <section className=''>
            <div className="container">
            <div className="row g-4">
                <div className="col-md">
                    <h2 className="text-center mb-4">Nous contacter</h2>
                    <ul className="pb-contact">
                        <li className="list-group-pb">
                            {/* <span className="fw-bold"> Rue DE RABANEAU
                        33440 AMBARES-ET-LAGRAVE</span> */}
                        </li>
                        <li className="list-group-pb">
                            <span className="fw-bold">e-mail:</span> support@lepetitboulot.fr
                        </li>
                    </ul>
                </div>
                <div className="col-md">
                    {/* <MapLanding/> */}
                </div>
            </div>
        </div>
        </section>
        {/* FOOTER */}
    <footer class="p-5 bg-white text-dark text-center position-relative">
        <div class="container">
            <p class="lead">Copyright &copy; lepetitboulot 2022 </p>

            <a href="#" class="position-absolute bottom-0 end-0 p-5">
                <i class="bi bi-arrow-up-circle h1"></i>
            </a>
        </div>
    </footer>

    </div>      
       

    )
}

const mapStateToProps = ({firebase})=>({
    userType: firebase.profile.user_type,
});

const mapDispatchToProps = {
    logout: actions.signOut,
  };

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
