import { useState } from "react";
import { connect } from "react-redux";
import styled from 'styled-components';

import ReactStars from "react-rating-stars-component";
import { getFirestore } from "redux-firestore";
import firebase from "firebase";
import SpecialityModal from '../../../Components/UI/Modal/SpecialityModal';
import RegionModal from "../../Boutique/RegionModal";

const LeftJobSection = (props) => {

// a function to capitalize the first letter of a string
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

    const [profileImage, setProfileImage]= useState("");
    const [showModal, setShowModal] = useState('close');
    const [showRegionModal, setRegionModal] = useState('close');

    const handleProfileImage = (e) => {

        const profileImage = e.target.files[0];

        if(profileImage === "" || profileImage === undefined){
            alert(`not an image, the file is a ${typeof profileImage}`);

            return;
        }
            setProfileImage(profileImage);
            const database = getFirestore();
            const storage = firebase.storage();
            const userId = firebase.auth().currentUser.uid;

            if(profileImage != ''){
                const upload = storage.ref(`profileImages/${profileImage.name}`).put(profileImage);
                upload.on('state_changed',
                snapshot =>{
                    const progress = 
                    (snapshot.bytesTransferred/ snapshot.totalBytes) * 100;
                    console.log(`progress: ${progress} %`);
                    if(snapshot.state === 'RUNNING'){
                        console.log(`Progress: ${progress} %`)
                    }

                }, error=> console.log(error.code),
                async() => {
                    const downloadURL = await upload.snapshot.ref.getDownloadURL();
                    //adding to the database
                    await database.collection('users').doc(userId).update({profile_image: downloadURL});
                });
            }

        };

        //changing speciality modal
        const handleClick = (e) => {
            e.preventDefault();
    
            switch(showModal){
                case "open":
                    setShowModal('close');
                    break;
    
                case "close":
                    setShowModal('open');
                    break;
    
                    default:
                        setShowModal('close');
                        break;
            }
        };

        //changing region modal
        const handleRegion = (e) => {
            e.preventDefault();
    
            switch(showRegionModal){
                case "open":
                    setRegionModal('close');
                    break;
    
                case "close":
                    setRegionModal('open');
                    break;
    
                    default:
                        setRegionModal('close');
                        break;
            }
        };


    return(
        <Container>
            <ArtCard>
                <UserInfo>
                    <CardBackground/>
                        <a>
                            <Photo>
                            {props.userId && props.firebase.profile.profile_image ? 
                            <img src={props.firebase.profile.profile_image}/> : <img/>}
                            </Photo>
                            <Link>Bienvenue, {props.userId ? props.firebase.profile.firstName : 'there'}!</Link>
                            
                        </a>
                        <a>
                            <input
                                type='file'
                                name='profileImage'
                                id='profileImageFile'
                                style={{display:'none'}}
                                onChange={handleProfileImage}
                            />
                            <AddPhotoText>
                                <label htmlFor ='profileImageFile' style={{cursor:'pointer'}}>Ajouter une photo</label>
                            </AddPhotoText>
                        </a>

                        <SpanDemandInfo style={{cursor:'default'}}>
                            <SpanDemandInfo style={{fontWeight:800}} >Overall Score: {props.firebase.profile.artisan_rating ? 
                            <ReactStars
                                count={5}
                                value={props.firebase.profile.artisan_rating}
                                size={15}
                                isHalf={true}
                                edit={false}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#c0a375"
                            />
                            : "Not enough projects" 
                            } </SpanDemandInfo>
                            <img src="" alt="" />
                    </SpanDemandInfo>
                    
                </UserInfo>
                <WidgetTasks>
                    <a >
                        <div>
                            <span style={{fontSize:'14px'}}>Votre Profil: {props.userId ? props.firebase.profile.user_type: "" } </span>
                            <img src="" alt="" />
                        </div>
                    </a>
                    <a onClick={handleClick}>
                        <div >
                            <span style={{fontSize:'14px'}}>Votre spécialité : {props.userId ? capitalizeFirstLetter(props.firebase.profile.speciality)
                            
                            : "" } </span>
                            <img src="" alt="" />
                        </div>
                    </a>

                    <a onClick={handleRegion}>
                        <div >
                            <span style={{fontSize:'14px'}}>Votre région : {props.userId && props.firebase.profile.artisan_region ? capitalizeFirstLetter(props.firebase.profile.artisan_region)
                            
                            : null } </span>
                            <img src="" alt="" />
                        </div>
                    </a>

                    <a >
                        <div>
                            <span style={{fontSize:'14px'}}>Statut de votre inscription : { props.firebase.profile.registration_status ==='pending' ? 
                            "Analyzing your application" 
                            :
                            props.firebase.profile.registration_status ==='valid' ?
                            capitalizeFirstLetter(props.firebase.profile.registration_status )
                            : "" } </span>
                            <img src="" alt="" />
                        </div>
                    </a>
                </WidgetTasks>
            </ArtCard>
            
            <SpecialityModal showModal={showModal} handleClick={handleClick}/>
            <RegionModal showModal={showRegionModal} handleClick={handleRegion}/>
        </Container>
        
    );
};

const Container = styled.div`
    grid-area: leftsection;
    @media(max-width:768px){
        display:none;
    }
   
`;

const ArtCard = styled.div`
    text-align : center;
    overflow: hidden;
    margin-bottom: 8px;
    margin-left:10px;
    backgroud-color: #fff;
    border-radius: 5px;
    transition: box-shadow 83ms;
    position: relative;
    border: none;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 15%), 0 0 0 rgb(0 0 0 / 20%);
    @media(max-width:768px){
        margin-left:0;
    }
`; 

const UserInfo = styled.div`
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 12px 12 px 16px;
    word-wrap: break-word;
    word-break: break-word;
`;

const CardBackground =styled.div`
    background: url('/images/tools.jpg');
    background-position: bottom;
    background-size: 462px;
    height: 104px;
    margin: -12px -12px 0;
`;

const Photo =styled.div`
    box-shadow: none;
    background-image: url('/images/photo.svg');
    width: 92px;
    height: 92px;
    box-sizing: border-box;
    background-clip: content-box;
    background-color: white;
    background-position: center;
    background-size: 60%;
    background-repeat: no-repeat;
    border: 2px solid white;
    margin: -38px auto 12px;
    border-radius: 50%;

    & > img{
        border-radius:50%;
        max-width: 92px;
        max-height:92px;
    }
`;

const Link =styled.div`
    font-size: 16px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;                                                                   
`;

const AddPhotoText  = styled.div`
    color: #0a66c2;
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.33;
    font-weight: 400;
    cursor: pointer;    
`;
const SpanDemandInfo = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding:5px;

`;



const WidgetTasks =styled.div`
    border-bottom: 1px solid rgba(0,0,0,0.15);
    padding-top: 12px;
    padding-bottom: 12px;
    
    & > a{
        text-decoration : none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 12px;

        &:hover{
            background-color: rgba(0, 0, 0, 0.08);
        }

        div{
            display:flex;
            flex-direction:column;
            text-align:left;
            
            span{
                font-size:18px;
                line-height:1.333;
                &:first-child{
                    color: rgba(0, 0, 0, 0.6);
                }
                &:nth-child(2){
                    color:rgba(0, 0, 0, 1);
                }
            }
        }
    }

    svg{
        color:rgba(0, 0, 0, 1);
    }
`;


const mapStateToProps = ({firebase, firestore})=> ({
    firebase,
    userId: firebase.auth.uid,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(LeftJobSection);